import { useEffect, useMemo } from 'react';

import { useGetAppointmentOnAppointmentFileMenuLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  appointmentId: string | null | undefined;
};

export const useFetchMedicineNoteStatus = ({ appointmentId }: Props) => {
  const [getAppointment, { data, loading, called }] =
    useGetAppointmentOnAppointmentFileMenuLazyQuery();

  const appointment = useMemo(() => {
    return getNode(data, 'Appointment');
  }, [data]);

  useEffect(() => {
    if (appointmentId) {
      getAppointment({
        variables: {
          id: appointmentId,
        },
      });
    }
  }, [appointmentId, getAppointment]);

  const status = useMemo(() => {
    if (!appointment?.patient?.active) {
      return {
        label: '閲覧期限切れ',
        active: false,
      };
    }

    if (!appointment?.hiccupAppointmentPermission) {
      return {
        label: 'データなし',
        active: false,
      };
    }

    if (!appointment.hiccupAppointmentPermission?.active) {
      return {
        label: '閲覧期限切れ',
        active: false,
      };
    }

    return {
      label: 'データあり',
      active: true,
    };
  }, [appointment]);

  return {
    status,
    loading: loading || !called,
  };
};
