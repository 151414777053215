import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Box, EntryList, Flex, Text } from '~/components/blocks';
import { AppointmentStatusLabel, MemoField } from '~/components/partials';
import { MedicationInstructionDateTime } from '~/components/partials/MedicationInstructionDateTime';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { ReceiveOption } from '~/components/partials/ReceiveOption';
import { useUpdateDescriptionMutation, WebVisitorAppointmentDetailFragment } from '~/graphql';
import { translateReason } from '~/utils/appointments';
import { Label } from '~/utils/label';

import { WebVisitorActionInfo } from './ActionInfo';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

const PatientBox = styled(Box)(({ theme }) =>
  css({
    borderTop: `1px solid ${theme.colors.border.default}`,
    paddingTop: theme.space.l,
  }),
);

export const WebVisitorProfile = React.memo((props: Props) => {
  const { appointment } = props;
  const { webVisitor, createdAt, start, end, description, status } = appointment;
  const theme = useTheme();

  const cancellationReason = appointment?.cancellationReason
    ? translateReason(appointment.cancellationReason, status, !!appointment.waitingForChargeAt)
    : null;

  const [latestMemo, setLatestMemo] = useState({ memo: '', mutationCalled: false });
  const [updateDescription, { loading: updateLoading }] = useUpdateDescriptionMutation({
    onCompleted: (_data) => {
      // Apollo or useMemo のキャッシュまわりの不整合が発生するので、メモ更新後は Mutation の返り値を使う
      // FYI https://github.com/medley-inc/pharms/issues/1852
      const latestDescription = _data?.updateAppointmentDescription?.appointment?.description || '';

      // updateDescriptionData はリロード走るまで値を保持し続けるので、別の受付ドロワーを開いたタイミングでリセットできるようにステート管理する
      setLatestMemo({ memo: latestDescription, mutationCalled: true });
    },
  });

  const handleChangeMemo = useCallback(
    (memo: string) => {
      if (appointment) {
        updateDescription({
          variables: {
            input: {
              appointmentId: appointment.id,
              description: memo,
            },
          },
        });
      }
    },
    [appointment, updateDescription],
  );

  useEffect(() => {
    // appointmentが切り替わった時にリセットする
    setLatestMemo({ memo: '', mutationCalled: false });
  }, [appointment]);

  return (
    <>
      <Box position="relative" height="100%" overflow="auto">
        <Flex flexDirection="column">
          <WebVisitorActionInfo status={status} />
          <Flex justifyItems="start" marginY={theme.space.m} alignItems="center">
            <MedicationInstructionMethodLabel method="faceToFace" />
            <Box marginLeft="auto">
              <AppointmentStatusLabel status={status} />
            </Box>
          </Flex>
          {start && end && <MedicationInstructionDateTime start={start} end={end} />}
        </Flex>
      </Box>
      <ReceiveOption deliveryMethod="hand" />
      {cancellationReason && (
        <Alert status="warning" marginY={theme.space.m} withIcon={false}>
          <Flex flexDirection="column">
            <Text size="s" fontWeight={theme.fontWeights.bold}>
              {cancellationReason.canceledBy}
              からキャンセル
            </Text>
            {cancellationReason.canceledBy === '薬局' && (
              <Text size="s">{cancellationReason.detail}</Text>
            )}
          </Flex>
        </Alert>
      )}
      <PatientBox>
        <Text fontWeight="bold" marginBottom={theme.space.m}>
          患者情報
        </Text>
        <EntryList>
          <EntryList.Head>氏名</EntryList.Head>
          <EntryList.Body>
            {`${webVisitor?.familyName} ${webVisitor?.givenName}`}（
            {`${webVisitor?.phoneticFamilyName} ${webVisitor?.phoneticGivenName}`}）
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>
            {webVisitor ? Label.warekiBirthDate(webVisitor.birthDate) : '-'}
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>性別</EntryList.Head>
          <EntryList.Body>{webVisitor ? Label.sex(webVisitor.sex) : '-'}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>電話番号（携帯）</EntryList.Head>
          <EntryList.Body>{webVisitor?.mobilePhone}</EntryList.Body>
        </EntryList>
        <Text fontWeight="bold" marginTop={theme.space.xl} marginBottom={theme.space.m}>
          その他
        </Text>
        <EntryList>
          <EntryList.Head>申し込み日時</EntryList.Head>
          <EntryList.Body>
            <Flex>
              <Box>
                <Text size="m">{Label.YYYYMMDDja(createdAt)}</Text>
                <Text size="m">{Label.HHMM(createdAt)}</Text>
              </Box>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>メモ</EntryList.Head>
          <EntryList.Body>
            <MemoField
              disabled={updateLoading}
              value={latestMemo.mutationCalled ? latestMemo.memo : description || ''}
              onChange={handleChangeMemo}
            />
          </EntryList.Body>
        </EntryList>
      </PatientBox>
    </>
  );
});

WebVisitorProfile.displayName = 'WebVisitorProfile';
