import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Box } from '~/components/blocks';
import { ReceptionDrawerWidth } from '~/components/layouts/ReceptionDrawer/constants';

export const ReceptionMainBox = styled(Box)(({ theme }) =>
  css({
    boxShadow: theme.shadows.default,
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: ReceptionDrawerWidth.main,
  }),
);
