import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Box, EntryList, Flex, Icon, Text } from '~/components/blocks';
import { MemoField } from '~/components/partials';
import { MedicationInstructionDateTime } from '~/components/partials/MedicationInstructionDateTime';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { OutpatientQuestionnaireEntryStatusLabel } from '~/components/partials/OutpatientQuestionnaireEntryStatusLabel';
import { ReceiveOption } from '~/components/partials/ReceiveOption';
import { OutpatientQuestionnaireEntryDetailFragment } from '~/graphql';
import { theme } from '~/styles/theme';
import { Label } from '~/utils/label';

import { OutpatientQuestionnaireEntryActionInfo } from './ActionInfo';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  outpatientQuestionnaireEntry: OutpatientQuestionnaireEntryDetailFragment;
};

const PatientBox = styled(Box)(({ theme }) =>
  css({
    borderTop: `1px solid ${theme.colors.border.default}`,
    paddingTop: theme.space.l,
  }),
);

export const ProfilePane = React.memo((props: Props) => {
  const { outpatientQuestionnaireEntry } = props;
  const directVisitor = outpatientQuestionnaireEntry.directVisitor;
  const address = outpatientQuestionnaireEntry.address;
  const enteredAt = outpatientQuestionnaireEntry.enteredAt;
  const enteredEndAt = new Date(+new Date(enteredAt) + 30 * 60 * 1000);

  const { called, updating, latestMemo, update } = useUpdateMemo(outpatientQuestionnaireEntry.id);

  const handleChangeMemo = useCallback((memo: string) => update(memo), [update]);

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      <Flex flexDirection="column">
        <OutpatientQuestionnaireEntryActionInfo
          status={outpatientQuestionnaireEntry.status}
          isEnabledNotification={outpatientQuestionnaireEntry.enableNotification}
        />
        <Flex justifyItems="start" marginY={theme.space.m} alignItems="center">
          <MedicationInstructionMethodLabel method="faceToFace" />
          <Box marginLeft="auto">
            <OutpatientQuestionnaireEntryStatusLabel
              enableNotification={outpatientQuestionnaireEntry.enableNotification}
              status={outpatientQuestionnaireEntry.status}
            />
          </Box>
        </Flex>
        <MedicationInstructionDateTime start={enteredAt} end={enteredEndAt} />
      </Flex>
      <ReceiveOption deliveryMethod="hand" />
      <Flex alignItems="center" marginY={theme.space.m}>
        <Icon icon="document" size="m" />
        <Text size="s" marginLeft={theme.space.xs}>
          Web問診
        </Text>
      </Flex>
      <PatientBox>
        <Text fontWeight="bold" marginBottom={theme.space.m}>
          患者情報
        </Text>
        <EntryList>
          <EntryList.Head>氏名</EntryList.Head>
          <EntryList.Body>
            {`${directVisitor.familyName} ${directVisitor.givenName}`}（
            {`${directVisitor.phoneticFamilyName} ${directVisitor.phoneticGivenName}`}）
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>{Label.warekiBirthDate(directVisitor.birthDate)}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>性別</EntryList.Head>
          <EntryList.Body>{Label.sex(directVisitor.sex)}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>電話番号（携帯）</EntryList.Head>
          <EntryList.Body>{directVisitor.mobilePhone}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>住所</EntryList.Head>
          <EntryList.Body>
            <Flex flexDirection="column">
              <Text>〒{address.postalCode}</Text>
              <Text>{`${address.prefecture}${address.city}${address.line}`}</Text>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <Text fontWeight="bold" marginTop={theme.space.xl} marginBottom={theme.space.m}>
          その他
        </Text>
        <EntryList>
          <EntryList.Head>申し込み日時</EntryList.Head>
          <EntryList.Body>
            <Flex>
              <Box>
                <Text size="m">{Label.YYYYMMDDja(enteredAt)}</Text>
                <Text size="m">{Label.HHMM(enteredAt)}</Text>
              </Box>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <EntryList marginTop={theme.space.l}>
          <EntryList.Head>メモ</EntryList.Head>
          <EntryList.Body>
            <MemoField
              disabled={updating}
              value={called ? latestMemo : outpatientQuestionnaireEntry.description || ''}
              onChange={handleChangeMemo}
            />
          </EntryList.Body>
        </EntryList>
      </PatientBox>
    </Box>
  );
});

ProfilePane.displayName = 'ProfilePane';
