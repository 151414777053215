import styled from '@emotion/styled';
import { css } from '@styled-system/css';

import { Box, Button } from '~/components/blocks';

const PreviewButton = styled(Button)(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radii.circle,
    opacity: 0,
    transform: 'translate(-50%,-50%)',
    transition: `opacity linier ${theme.transitions.fast}`,
  }),
);

const Root = styled(Box)(({ theme }) =>
  css({
    position: 'relative',
    background: theme.colors.background.bg,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.background.grey,
      opacity: 1,
      [`& > ${PreviewButton}`]: {
        opacity: 1,
      },
    },
  }),
);

export const PrescriptionContent = Object.assign(Root, { PreviewButton });
