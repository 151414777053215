import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DateLabel, Flex, Name, Table, Text } from '~/components/blocks';
import { DeliveryMethodField } from '~/components/partials/DeliveryMethodField';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { OutpatientQuestionnaireEntryStatusLabel } from '~/components/partials/OutpatientQuestionnaireEntryStatusLabel';
import { ReceptionMemos } from '~/components/partials/ReceptionMemos';
import { ReceptionOutpatientQuestionnaireEntryFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  newReceptionDrawerState,
  outpatientQuestionnaireEntryProfileDrawerState,
  receptionPageInfoState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { ReceptionIcon } from './ReceptionIcon';
import { TableRow } from './TableRow';

type Props = {
  activeMedixs: boolean;
  updated: boolean;
  outpatientQuestionnaireEntry: ReceptionOutpatientQuestionnaireEntryFragment;
  onAnimationEnd: (visitId: string) => void;
};

export const OutpatientQuestionnaireEntry = React.memo((props: Props) => {
  const { outpatientQuestionnaireEntry, onAnimationEnd } = props;
  const guestPatient = outpatientQuestionnaireEntry.directVisitor;
  const { date } = useRecoilValue(receptionPageInfoState);
  const { enable: enabledUber } = useUberOrganizationSetting();

  const enteredAt = outpatientQuestionnaireEntry.enteredAt;
  const enteredEndAt = new Date(+new Date(enteredAt) + 30 * 60 * 1000);

  const handleAnimationEnd = useCallback(
    () => onAnimationEnd(outpatientQuestionnaireEntry.id),
    [onAnimationEnd, outpatientQuestionnaireEntry.id],
  );

  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(outpatientQuestionnaireEntryProfileDrawerState, (_state) => ({
          ..._state,
          isOpen: true,
          outpatientQuestionnaireEntryId: outpatientQuestionnaireEntry.id,
        }));

        reset(draftAppointmentPatientProfileDrawerState);
        reset(profileDrawerState);
        reset(newReceptionDrawerState);
        reset(webVisitorProfileDrawerState);
        reset(checkinEntryProfileDrawerState);
      },
    [outpatientQuestionnaireEntry.id],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick} onAnimationEnd={handleAnimationEnd}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          <DateLabel>
            {!date && <DateLabel.Time>{Label.YYYYMMDDja(enteredAt)}</DateLabel.Time>}
            <DateLabel.Day>
              {Label.HHMM(enteredAt)}-{Label.HHMM(enteredEndAt)}
            </DateLabel.Day>
          </DateLabel>
        </Flex>
      </Table.Td>
      <Table.Td>
        <OutpatientQuestionnaireEntryStatusLabel
          status={outpatientQuestionnaireEntry.outpatientQuestionnaireEntryStatus}
          enableNotification={outpatientQuestionnaireEntry.enableNotification}
        />
      </Table.Td>
      {props.activeMedixs && <Table.Td nowrap />}
      <Table.Td nowrap>
        <Flex alignItems="center">
          <ReceptionIcon receptionType="outpatientQuestionnaireEntry" />
          <Name
            familyName={guestPatient.familyName}
            givenName={guestPatient.givenName}
            phoneticFamilyName={guestPatient.phoneticFamilyName}
            phoneticGivenName={guestPatient.phoneticGivenName}
          />
        </Flex>
      </Table.Td>
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {guestPatient.birthDate ? Label.age(guestPatient.birthDate) : ''}
        </Text>
        <Text size="s" whiteSpace="nowrap">
          （{guestPatient.sex ? Label.sex(guestPatient.sex) : ''}）
        </Text>
      </Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <MedicationInstructionMethodLabel method="faceToFace" use="short" size="s" />
        </Flex>
      </Table.Td>
      <Table.Td>
        {/* TODO: 受け渡し方法は後になくなるが、一旦チェックインと合わせたUIにしている。後に消す */}
        <DeliveryMethodField deliveryMethod="hand" size="m" />
      </Table.Td>
      {enabledUber && <Table.Td>-</Table.Td>}
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.YYYYMMDDja(enteredAt)}&nbsp;{Label.HHMM(enteredAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        <ReceptionMemos memo={outpatientQuestionnaireEntry.description} />
      </Table.Td>
    </TableRow>
  );
});

OutpatientQuestionnaireEntry.displayName = 'OutpatientQuestionnaireEntry';
