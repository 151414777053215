import { useEffect, useMemo } from 'react';

import { useGetQuestionnaireSheetLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  patientId: string | null | undefined;
};

export const useFetchQuestionnaireStatus = ({ patientId }: Props) => {
  const [getQuestionnaires, { data, loading, called }] = useGetQuestionnaireSheetLazyQuery();

  const sheet = getNode(data, 'Patient')?.questionnaireSheet || undefined;

  useEffect(() => {
    if (patientId) {
      getQuestionnaires({ variables: { patientId: patientId } });
    }
  }, [getQuestionnaires, patientId]);

  const status = useMemo(() => {
    if (sheet)
      return {
        label: 'データあり',
        active: true,
      };

    return {
      label: 'データなし',
      active: false,
    };
  }, [sheet]);

  return {
    status,
    loading: loading || !called,
  };
};
