import { FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Box, Button, Icon, Modal, Text } from '~/components/blocks';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { RequestUberDeliveryModalState } from '~/state/reception/types';

import { PiecesContent } from '../PiecesContent';
import { Fields } from '../type';
import { InputPiece } from './InputPiece';

type Props = {
  formik: FormikProps<Fields>;
};

export const PiecesContentForm = React.memo((props: Props) => {
  const { formik } = props;
  const setState = useSetRecoilState(requestUberDeliveryModalState);
  const initialPiecesValue = Array.from({ length: formik.values.quantity }).map<number>(() => 0);

  const parseNumber = useCallback((str: string): number | null => {
    if (str.length === 0) return NaN;
    return Number(str);
  }, []);

  const handleChange = useCallback(
    (value: string, idx: number) => {
      const newValues = formik.values.pieces;
      const newValue = parseNumber(value);

      if (newValue) {
        newValues[idx] = newValue;
      } else {
        newValues[idx] = '';
      }

      formik.setValues({
        ...formik.values,
        pieces: newValues,
      });
    },
    [formik, parseNumber],
  );

  return (
    <Box>
      <Modal.Body height="360px">
        {initialPiecesValue.map((_value, idx) => (
          <PiecesContent key={idx} index={idx}>
            <InputPiece
              index={idx}
              errors={Array.isArray(formik.errors.pieces) ? formik.errors.pieces[idx] : undefined}
              value={formik.values.pieces[idx]}
              onChange={(value) => handleChange(value, idx)}
            />
          </PiecesContent>
        ))}
      </Modal.Body>
      <Modal.Footer both>
        <Button
          size="xs"
          use="white"
          onClick={() => {
            setState((_state: RequestUberDeliveryModalState) => ({
              ..._state,
              step: 'selectQuantity',
            }));
          }}
        >
          <Icon icon="back-line" size="l" />
          <Text size="m">戻る</Text>
        </Button>
        <Button
          size="m"
          use="base"
          onClick={() => {
            setState((_state: RequestUberDeliveryModalState) => ({ ..._state, step: 'confirm' }));
          }}
          disabled={!!formik.errors.pieces || !!formik.errors.quantity}
        >
          確認
        </Button>
      </Modal.Footer>
    </Box>
  );
});

PiecesContentForm.displayName = 'PiecesContentForm';
