import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Flex } from '~/components/blocks';
import { CancelWaitingForChargePane } from '~/components/partials/CancelWaitingForChargePane';
import { ActionButton } from '~/components/partials/ReceptionDrawer/ActionButton';
import { InactiveButton } from '~/components/partials/ReceptionDrawer/InactiveButton';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';
import { chargeModalState } from '~/state/partials/charge_modal/atoms';

import { useCancelAppointment } from './use-cancel-appointment';

type Props = {
  appointmentId: string;
  draftAppointmentId: string | undefined;
  telemedicine: boolean;
  isSentCongestionNotification: boolean;
};

export const WaitingForChargeButtons = (props: Props) => {
  const { appointmentId, draftAppointmentId, telemedicine, isSentCongestionNotification } = props;
  const { canceling, handleCancel } = useCancelAppointment(appointmentId);
  const theme = useTheme();

  // 会計モーダルで確定後
  // - 未会計 -> 会計済(配送待ち) （telemedicine: true）
  // - 指導待ち -> 未会計 -> 完了 （telemedicine: false）
  const openChargeModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chargeModalState, {
          appointmentId,
          isOpen: true,
        });
      },
    [appointmentId],
  );

  // キャンセル
  const handleClickCancel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelPane, {
          isOpen: true,
        });
      },
    [],
  );

  if (telemedicine) {
    return (
      <ActionButton use="primary" wide="fill" size="l" onClick={openChargeModal}>
        会計
      </ActionButton>
    );
  } else {
    return (
      <>
        <Flex flexDirection="column">
          <ActionButton use="primary" wide="fill" size="l" onClick={openChargeModal}>
            会計
          </ActionButton>
          <Flex alignItems="center" marginTop={theme.space.m}>
            <InactiveButton
              status={isSentCongestionNotification ? '送信済' : undefined}
              wide="half"
              size="m"
            >
              混雑時通知を送信
            </InactiveButton>
            <InactiveButton status="送信済" marginLeft={theme.space.m} wide="half" size="m">
              準備完了通知を送信
            </InactiveButton>
          </Flex>
          <ActionButton use="bgGrey" onClick={handleClickCancel} marginTop={theme.space.m} size="s">
            キャンセル
          </ActionButton>
        </Flex>
        <CancelWaitingForChargePane
          align="center"
          canceling={canceling}
          onCancel={handleCancel}
          draftAppointmentId={draftAppointmentId}
        />
      </>
    );
  }
};
