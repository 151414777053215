import { useEffect, useMemo } from 'react';

import { useGetAppointmentOnAppointmentFileMenuLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

import { useFetchInsuranceCards } from './use-fetch-insurance_cards';

type Props = {
  appointmentId: string | null | undefined;
  patientId: string | null | undefined;
};

export const useFetchInsuranceCardStatus = ({ appointmentId, patientId }: Props) => {
  const [getAppointment, { data, loading: appointmentLoading, called }] =
    useGetAppointmentOnAppointmentFileMenuLazyQuery();
  const appointment = useMemo(() => {
    return getNode(data, 'Appointment');
  }, [data]);

  const qualificationConfirmationMethod = appointment?.qualificationConfirmationMethod;
  const willFetchInsuranceCards = qualificationConfirmationMethod === 'insurance_card';
  const {
    insuranceCards,
    loading: insuranceCardLoading,
    timeout,
  } = useFetchInsuranceCards(willFetchInsuranceCards, patientId || null);

  useEffect(() => {
    if (appointmentId) {
      getAppointment({
        variables: {
          id: appointmentId,
        },
      });
    }
  }, [appointmentId, getAppointment]);

  const status = useMemo(() => {
    if (qualificationConfirmationMethod === 'individual_number_card') {
      return {
        label: 'オン資希望',
        active: true,
      };
    }

    if (!appointment?.patient?.active) {
      return {
        label: '閲覧期限切れ',
        active: false,
      };
    }

    if (insuranceCards.length === 0) {
      return {
        label: 'データなし',
        active: false,
      };
    }

    return {
      label: 'データあり',
      active: true,
    };
  }, [appointment, qualificationConfirmationMethod, insuranceCards]);

  return {
    status,
    loading: appointmentLoading || insuranceCardLoading || !called,
    timeout,
  };
};
