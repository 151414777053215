import { useTheme } from '@emotion/react';
import React from 'react';

import { Flex, Name, Skeleton, Text } from '~/components/blocks';

export const ProfileSkeleton = () => {
  const theme = useTheme();

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="row" alignItems="flex-end">
        <Name
          familyName={<Skeleton inline width="60px" />}
          givenName={<Skeleton inline width="60px" />}
          phoneticFamilyName={<Skeleton inline width="60px" />}
          phoneticGivenName={<Skeleton inline width="60px" />}
          size="l"
        />
        <Text lineHeight={theme.lineHeights.s}>
          <Skeleton width="80px" />
        </Text>
      </Flex>
      <Flex marginTop={`calc(${theme.space.m} + ${theme.space.xs})`} marginBottom={theme.space.l}>
        <Skeleton width="80px" />
      </Flex>
    </Flex>
  );
};
