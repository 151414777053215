import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, variant } from 'styled-system';

import { Text } from '~/components/blocks';

type Props = {
  method: 'faceToFace' | 'telemedicine';
  size?: 's' | 'm';
  use?: 'short' | 'full';
};

const Root = styled('div', { shouldForwardProp })(
  ({ theme }) =>
    css({
      border: theme.borders.black,
      borderRadius: theme.radii.default,
      padding: `${theme.space.xs} ${theme.space.m}`,
      height: '100%',
      minWidth: '85px',
      textAlign: 'center',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            padding: `${theme.space.xs} ${theme.space.m}`,
          }),
          m: css({
            padding: `${theme.space.xs} ${theme.space.xl}`,
          }),
        },
      }),
    ),
);

const TextLabel = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.text.default,
    fontWeight: 'bold',
    fontSize: theme.fontSizes.s,
    whiteSpace: 'nowrap',
  }),
);

export const MedicationInstructionMethodLabel = (props: Props) => {
  if (props.method === 'faceToFace') {
    const label = props.use === 'short' ? '対面' : '対面服薬指導';

    return (
      <Root size={props.size}>
        <TextLabel>{label}</TextLabel>
      </Root>
    );
  } else {
    const label = props.use === 'short' ? 'オンライン' : 'オンライン服薬指導';

    return (
      <Root size={props.size}>
        <TextLabel>{label}</TextLabel>
      </Root>
    );
  }
};
