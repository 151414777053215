import React from 'react';

import { ActionButton } from '~/components/partials/ReceptionDrawer/ActionButton';

import { useFinishAppointment } from './use-finish-appointment';

type Props = {
  appointmentId: string;
};

export const ChargedButtons = (props: Props) => {
  const { finishing, handleFinish } = useFinishAppointment(props.appointmentId);

  return (
    <ActionButton use="primary" wide="fill" size="l" loading={finishing} onClick={handleFinish}>
      発送完了
    </ActionButton>
  );
};
