import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box, Flex, Icon, Text } from '~/components/blocks';
import { PrescriptionCollapseImgViewer } from '~/components/partials';
import { PrescriptionContent } from '~/components/partials/ReceptionDrawer/PrescriptionContent';
import { PrescriptionLoader } from '~/components/partials/ReceptionDrawer/PrescriptionLoader';
import { AppointmentStatus } from '~/graphql';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { webVisitorFileAreaState } from '~/state/reception_drawer/atoms';
import { openPreviewPage } from '~/utils/prescription';

import { useFetchAppointment } from './use-fetch-appointment';

export const Prescription = () => {
  const { appointmentId } = useRecoilValue(webVisitorFileAreaState);
  const theme = useTheme();

  const { loading, error, appointment } = useFetchAppointment(appointmentId);
  const { electronicPrescription } = useOrganizationElectronicPrescription();

  const isPrintable = appointment?.status !== AppointmentStatus.Pending;

  const handleClick = useCallback(() => {
    if (appointmentId) {
      openPreviewPage(appointmentId, isPrintable);
    }
  }, [isPrintable, appointmentId]);

  const prescriptions = appointment?.prescriptions || [];

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l}>
        <Icon size="xl" icon="drug" />
        <Text fontWeight="bold" marginLeft={theme.space.xs}>
          {electronicPrescription ? '処方箋または処方内容（控え）' : '処方箋'}
        </Text>
      </Flex>
      {error ? (
        <Box padding={theme.space.m}>
          <Alert status="error">取得できませんでした</Alert>
        </Box>
      ) : loading ? (
        <PrescriptionLoader />
      ) : (
        <PrescriptionContent onClick={handleClick}>
          {prescriptions.map((prescription) => (
            <PrescriptionCollapseImgViewer
              key={prescription.signedId}
              src={prescription.url}
              alt="処方箋"
            />
          ))}
          <PrescriptionContent.PreviewButton icon use="base">
            {isPrintable ? (
              <Icon color="white" icon="print" size="l" />
            ) : (
              <Icon color="white" icon="blank" size="l" />
            )}
          </PrescriptionContent.PreviewButton>
        </PrescriptionContent>
      )}
    </Flex>
  );
};
