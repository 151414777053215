import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Grid, Loader } from '~/components/blocks';
import { ReceptionDrawer } from '~/components/layouts/ReceptionDrawer';
import { ReceptionDrawerFileMenu } from '~/components/layouts/ReceptionDrawer/FileMenu';
import { useMarkNotification } from '~/components/pages/Reception/CheckinEntryProfileDrawer/use-mark-notification';
import { FileMenuButton } from '~/components/partials/ReceptionDrawer/FileMenuButton';
import { ReceptionMainBox } from '~/components/partials/ReceptionDrawer/ReceptionMainBox';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { checkinEntryProfileDrawerState } from '~/state/reception/atoms';

import { FinishConfirmModal } from '././FinishConfirmModal';
import { CheckinEntryKarte } from './CheckinEntryKarte';
import { CheckinPatientProfile } from './CheckinEntryKarte/CheckinPatientProfile';
import { Footer } from './Footer';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { useFetchCheckinEntry } from './use-fetch-checkin_entry';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const CheckinEntryProfileDrawer = () => {
  const theme = useTheme();
  const {
    state: { isOpen },
    handleTransitionEnd,
  } = useSwapDrawer(checkinEntryProfileDrawerState, 'checkinEntryId');

  const { checkinEntry, loading } = useFetchCheckinEntry();
  useMarkNotification();

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(checkinEntryProfileDrawerState);
      },
    [],
  );

  return (
    <>
      <ReceptionDrawer
        isOpen={isOpen}
        onClose={handleClose}
        onTransitionEnd={handleTransitionEnd}
        isExpended={false}
      >
        <ReceptionDrawerFileMenu>
          <FileMenuButton
            fileName="処方箋"
            active={false}
            statusLabel="データなし"
            marginTop={theme.space.xl}
          />
          <FileMenuButton fileName="保険証" active={false} statusLabel="データなし" />
          <FileMenuButton fileName="問診票" active={false} statusLabel="データなし" />
          <FileMenuButton fileName="お薬手帳" active={false} statusLabel="データなし" />
        </ReceptionDrawerFileMenu>
        <ReceptionMainBox>
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <CheckinPatientProfile loading={loading} checkinEntry={checkinEntry} />
            </ProfileBox>
            {checkinEntry ? (
              <Box overflow="auto">
                <CheckinEntryKarte checkinEntry={checkinEntry} />
              </Box>
            ) : (
              <Loader inside open />
            )}
            <Footer checkinEntry={checkinEntry} />
          </Grid>
        </ReceptionMainBox>
      </ReceptionDrawer>
      <FinishConfirmModal />
      <NotificationConfirmModal />
    </>
  );
};
