import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Chip, Text } from '~/components/blocks';

type Props = {
  isLinked: boolean;
};

const CustomChip = styled(Chip)<{ isLinked: boolean }>(({ theme, isLinked }) =>
  css({
    border: isLinked ? theme.borders.default : 'none',
    size: 'fixed',
    backgroundColor: isLinked ? theme.colors.colorPallete.white : theme.colors.colorPallete.grey04,
    padding: `0 ${theme.space.m}`,
  }),
);

export const MedixsTag = ({ isLinked }: Props) => {
  const theme = useTheme();

  return (
    <CustomChip isLinked={isLinked}>
      <Text
        size="xs"
        color={isLinked ? 'navy' : 'grey01'}
        lineHeight="1"
        padding={`${theme.space.s} 0`}
      >
        {isLinked ? 'MEDIXS連携済み' : 'MEDIXS未連携'}
      </Text>
    </CustomChip>
  );
};
