import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Flex, Loader, Text } from '~/components/blocks';
import { ReceptionUberDeliveryFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { useFetchUberCourier } from '../use-fetch_uber_courier';

type Props = {
  uberDelivery: ReceptionUberDeliveryFragment;
};

const Info = styled(Flex)(({ theme }) =>
  css({
    flexDirection: 'column',
    width: '100%',
    background: theme.colors.background.bg,
    marginTop: theme.space.s,
    padding: theme.space.m,
    position: 'relative',
  }),
);

export const UberDeliveryField = React.memo((props: Props) => {
  const { uberDelivery } = props;
  const uberDeliveryOrderNumber = uberDelivery.orderNumber;
  const deliveredAt = uberDelivery.deliveredAt;
  const { courier, loading } = useFetchUberCourier({
    uberDeliveryId: uberDelivery.id,
    status: uberDelivery.status,
  });

  const shouldShowOrderNumber =
    uberDelivery.status !== 'suspended' &&
    uberDelivery.status !== 'unfulfilled' &&
    uberDelivery.status !== 'before_dropoff_canceled';
  const shouldShowFee =
    uberDelivery.status === 'pending' ||
    uberDelivery.status === 'pickup' ||
    uberDelivery.status === 'dropoff';
  const shouldShowCourier = uberDelivery.status === 'pickup' || uberDelivery.status === 'dropoff';
  const shouldShowDeliveredAt = uberDelivery.status === 'delivered';

  if (!uberDeliveryOrderNumber) {
    return null;
  }

  if (loading) {
    return (
      <Info>
        <Loader open inside appearance="white" logoSize="half" />
      </Info>
    );
  }

  return (
    <Flex flexDirection="column">
      {shouldShowCourier && !courier && (
        <Alert status="error">
          配達員の取得に失敗しました。
          <br />
          しばらく待ってから、再度アクセスしてください
        </Alert>
      )}
      {(shouldShowOrderNumber || shouldShowFee || shouldShowCourier || shouldShowDeliveredAt) && (
        <Info>
          {shouldShowOrderNumber && (
            <Text size="s">
              注文番号：<b>{uberDeliveryOrderNumber}</b>
            </Text>
          )}
          {/* TODO: 無料キャンペーン期間中は表示を変更する（ref. https://github.com/medley-inc/pharms/pull/10595/files#r1879622107） */}
          {shouldShowFee && <Text size="s">当日配達利用料：{uberDelivery.fee}円</Text>}
          {shouldShowCourier && courier && (
            <Text size="s">
              配達員：{courier.name}さん（{courier.phoneNumber}）
            </Text>
          )}
          {shouldShowDeliveredAt && deliveredAt && (
            <Text size="s">
              配達完了：{`${Label.YYYYMMDDja(deliveredAt)} ${Label.HHMM(deliveredAt)}`}
            </Text>
          )}
        </Info>
      )}
    </Flex>
  );
});

UberDeliveryField.displayName = 'UberDeliveryField';
