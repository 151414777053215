import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Flex } from '~/components/blocks';
import { ActionButton } from '~/components/partials/ReceptionDrawer/ActionButton';
import { InactiveButton } from '~/components/partials/ReceptionDrawer/InactiveButton';
import { CheckinEntryDetailFragment, CheckinEntryStatus } from '~/graphql';
import {
  checkinFinishConfirmModalModalState,
  checkinNotifyConfirmModalState,
} from '~/state/reception/atoms';

import { useFinishCheckinEntry } from '../use-finish-checkin_entry';

type Props = {
  checkinEntry: CheckinEntryDetailFragment;
};

export const BookedButtons = (props: Props) => {
  const theme = useTheme();
  const { checkinEntry } = props;
  const enableNotification = checkinEntry.notificationsCount < 2;
  const setFinishState = useSetRecoilState(checkinFinishConfirmModalModalState);
  const setNotifyState = useSetRecoilState(checkinNotifyConfirmModalState);
  const { finish, finishing } = useFinishCheckinEntry(checkinEntry.id);

  const handleFinishClick = useCallback(async () => {
    if (checkinEntry.status === CheckinEntryStatus.Entered) {
      setFinishState((_state) => ({ ..._state, isOpen: true, checkinEntryId: checkinEntry.id }));
    } else {
      await finish();
    }
  }, [checkinEntry.id, checkinEntry.status, finish, setFinishState]);

  const handleNotificationClick = useCallback(async () => {
    setNotifyState((_state) => ({ ..._state, isOpen: true, checkinEntryId: checkinEntry.id }));
  }, [checkinEntry.id, setNotifyState]);

  return (
    <Flex flexDirection="column">
      {enableNotification ? (
        <ActionButton
          use="primary"
          size="l"
          onClick={handleNotificationClick}
          marginBottom={theme.space.m}
        >
          {checkinEntry.notificationsCount === 0
            ? '準備完了通知を送信'
            : 'もう一度 準備完了通知を送信'}
        </ActionButton>
      ) : (
        <InactiveButton
          status="送信済"
          wide="fill"
          size="l"
          marginBottom={theme.space.m}
          use="primary"
        >
          もう一度 準備完了通知を送信
        </InactiveButton>
      )}
      <ActionButton use="primaryInverse" size="l" onClick={handleFinishClick} loading={finishing}>
        服薬指導を完了
      </ActionButton>
    </Flex>
  );
};
