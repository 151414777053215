import React from 'react';

import { Box } from '~/components/blocks';

import { PatientProfileSkeleton } from './PatientProfileSkeleton';
import { Profile } from './Profile';
import { useFetchPatientProfile } from './use-fetch-patient_profile';

type Props = {
  patientId: string | null;
  appointmentId: string | null;
};

export const PatientProfile = React.memo((props: Props) => {
  const { loading, patient } = useFetchPatientProfile(props.patientId);

  return (
    <Box>{loading || !patient ? <PatientProfileSkeleton /> : <Profile patient={patient} />}</Box>
  );
});

PatientProfile.displayName = 'PatientProfile';
