import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, FontWeightProps, space, SpaceProps, variant } from 'styled-system';

import { Text } from '../Text';

type Props = FontWeightProps & {
  className?: string;
  label?: string;
  name?: string;
  value?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const MimicCheckbox = styled('div')<{ disabled?: boolean }>(({ theme }) =>
  css({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.fontSizes.xl,
    height: theme.fontSizes.xl,
    backgroundColor: theme.colors.background.default,
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    flexShrink: 0,
  }),
);

const Root = styled('label', {
  shouldForwardProp,
})<{ disabled?: boolean }>(({ theme, disabled }) =>
  css({
    display: 'inline-flex',
    alignItems: 'start',
    justifyContent: 'center',
    cursor: 'pointer',
    ...(!disabled && {
      [`&:hover > ${MimicCheckbox}`]: {
        border: theme.borders.primary,
      },
      [`&:focus > ${MimicCheckbox}`]: {
        border: theme.borders.default,
      },
    }),
    ...(disabled && {
      opacity: 0.6,
      cursor: 'not-allowed',
    }),
  }),
);

const Input = styled('input', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    opacity: 0,
    position: 'absolute',
    [`:checked + ${MimicCheckbox}`]: {
      background: theme.colors.background.primary,
      border: theme.borders.transparent,
      borderRadius: theme.radii.default,
      '&:after': {
        opacity: 1,
        top: '50%',
        left: '22%',
        width: '8px',
        height: '12px',
        position: 'absolute',
        display: 'table',
        borderRight: `3px solid ${theme.colors.border.white}`,
        borderBottom: `3px solid ${theme.colors.border.white}`,
        transform: 'rotate(45deg) scale(1) translate(-50%, -50%)',
        content: '""',
      },
    },
    [`:focus-visible + ${MimicCheckbox}`]: {
      border: theme.borders.primary,
    },
  }),
);

export const Checkbox: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { onChange } = props;

  return (
    <Root disabled={props.disabled} className={props.className}>
      <Input
        type="checkbox"
        disabled={props.disabled}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
      />
      <MimicCheckbox disabled={props.disabled} />
      {props.label && (
        <Text userSelect fontWeight={props.fontWeight} ml={theme.space.s}>
          {props.label}
        </Text>
      )}
    </Root>
  );
};

export const CheckboxGroup = styled('div', {
  shouldForwardProp,
})<SpaceProps & { orientation?: 'vertical' | 'holizonal' }>(
  () =>
    css({
      display: 'flex',
      alignItems: 'start',
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            flexDirection: 'row',
            [`& > ${Root} + ${Root}`]: {
              marginLeft: theme.space.m,
            },
          }),
          vertical: css({
            flexDirection: 'column',
            [`& > ${Root} + ${Root}`]: {
              marginTop: theme.space.m,
            },
          }),
        },
      }),
    ),
);

CheckboxGroup.defaultProps = {
  orientation: 'holizonal',
};
