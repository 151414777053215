import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Box, Tooltip } from '~/components/blocks';
import { ReceptionDrawer } from '~/components/layouts/ReceptionDrawer';
import { ReceptionDrawerFileMenu } from '~/components/layouts/ReceptionDrawer/FileMenu';
import { DEFAULT_WINDOW, TelemedicinePatientProfile } from '~/components/partials';
import { FileMenuButton } from '~/components/partials/ReceptionDrawer/FileMenuButton';
import { FileMenuSkeletonButton } from '~/components/partials/ReceptionDrawer/FileMenuSkeletonButton';
import { ReceptionFileType } from '~/constants/reception_file_type';
import { BasePatientProfileFragment, KarteAppointmentFragment } from '~/graphql';
import { telemedicinePanelState } from '~/state/partials/telemedicine/atoms';
import { telemedicinePatientFileAreaState } from '~/state/reception_drawer/atoms';
import { ReceptionDrawerFileType } from '~/state/reception_drawer/types';
import { openWithNoOpener } from '~/utils/window';

import { FileArea } from './FileArea';
import { useFetchInsuranceCardStatus } from './use-fetch-insurance_card_status';
import { useFetchMedicineNoteStatus } from './use-fetch-medicine_note_status';
import { useFetchPrescriptionStatus } from './use-fetch-prescription_status';

type Props = {
  patient?: BasePatientProfileFragment | null;
  appointment?: KarteAppointmentFragment | null;
};

export const PatientProfilePanel = ({ patient, appointment }: Props) => {
  const theme = useTheme();
  const { isOpenProfile } = useRecoilValue(telemedicinePanelState);
  const [isFileAreaOpened, setOpenFileArea] = useState(false);
  const setFileAreaState = useSetRecoilState(telemedicinePatientFileAreaState);

  const handleOpenFileArea = useCallback(
    (type: ReceptionDrawerFileType) => {
      if (appointment) {
        setOpenFileArea(true);
        setFileAreaState((state) => ({
          ...state,
          type,
          appointmentId: appointment.id,
          patientId: patient?.id || null,
        }));
      }
    },
    [appointment, patient, setFileAreaState],
  );
  const handleCloseFileArea = useCallback(() => {
    setOpenFileArea(false);
  }, []);
  const handleOpenMedicineNote = useCallback(() => {
    if (patient) {
      openWithNoOpener(`/medicinenote/patients/${patient.id}`, {
        target: patient.id,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });
    }
  }, [patient]);

  const { status: prescriptionStatus, loading: prescriptionLoading } = useFetchPrescriptionStatus({
    appointmentId: appointment?.id,
  });
  const {
    status: insuranceCardStatus,
    loading: insuranceCardLoading,
    timeout: insuranceCardTimeout,
  } = useFetchInsuranceCardStatus({
    appointmentId: appointment?.id,
    patientId: patient?.id,
  });
  const { status: medicineNoteStatus, loading: medicineNoteLoading } = useFetchMedicineNoteStatus({
    appointmentId: appointment?.id,
  });

  useEffect(() => {
    setOpenFileArea(false);
  }, [appointment?.id]);

  return (
    <Box position="relative" height="100%">
      <ReceptionDrawer isOpen={isOpenProfile} isExpended={isFileAreaOpened} use="inside">
        <ReceptionDrawerFileMenu>
          {prescriptionLoading ? (
            <FileMenuSkeletonButton marginTop={theme.space.xl} />
          ) : (
            <FileMenuButton
              fileName="処方箋"
              active={prescriptionStatus.active}
              statusLabel={prescriptionStatus.label}
              onClick={() => handleOpenFileArea(ReceptionFileType.prescription)}
              marginTop={theme.space.xl}
            />
          )}
          {insuranceCardLoading ? (
            <FileMenuSkeletonButton />
          ) : insuranceCardTimeout ? (
            <Tooltip
              content={
                <>
                  データ取得に時間がかかっています
                  <br />
                  しばらく経ってから再度お試しください
                </>
              }
              placement="right"
            >
              <div>
                <FileMenuButton
                  fileName="保険証"
                  active={false}
                  statusLabel="取得不可"
                  use="error"
                />
              </div>
            </Tooltip>
          ) : (
            <FileMenuButton
              fileName="保険証"
              active={insuranceCardStatus.active}
              statusLabel={insuranceCardStatus.label}
              onClick={() => handleOpenFileArea(ReceptionFileType.insuranceCard)}
            />
          )}
          <FileMenuButton
            fileName="問診票"
            active={true}
            statusLabel="データあり"
            onClick={() => handleOpenFileArea(ReceptionFileType.questionnaire)}
          />
          {medicineNoteLoading ? (
            <FileMenuSkeletonButton />
          ) : (
            <FileMenuButton
              fileName="お薬手帳"
              active={medicineNoteStatus.active}
              statusLabel={medicineNoteStatus.label}
              statusIcon={medicineNoteStatus.active ? 'blank' : undefined}
              onClick={handleOpenMedicineNote}
            />
          )}
        </ReceptionDrawerFileMenu>
        {isFileAreaOpened && <FileArea onClose={handleCloseFileArea} />}
        <TelemedicinePatientProfile patient={patient} appointment={appointment} />
      </ReceptionDrawer>
    </Box>
  );
};
