import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Box, EntryList, Flex, Loader, Text, Tooltip } from '~/components/blocks';
import { DraftAppointmentStatusLabel } from '~/components/partials/DraftAppointmentStatusLabel';
import { MedicationInstructionDateTime } from '~/components/partials/MedicationInstructionDateTime';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { ReceiveOption } from '~/components/partials/ReceiveOption';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { translateReason } from '~/utils/draft_appointments';

import { LinkButton } from '../MedixsLinkButtons/LinkButton';
import { DraftAppointmentActionInfo } from './ActionInfo';
import { MemoField } from './MemoField';
import { PatientProfileOnReceptionPane } from './PatientProfile';
import { useFetchDraftAppointment } from './use-fetch-draft-appointment';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  draftAppointmentId: string;
};

const PatientBox = styled(Box)(({ theme }) =>
  css({
    borderTop: `1px solid ${theme.colors.border.default}`,
    paddingTop: theme.space.l,
  }),
);

export const DraftAppointment = React.memo((props: Props) => {
  const theme = useTheme();
  const { loading, draftAppointment } = useFetchDraftAppointment(props.draftAppointmentId);

  const { updating, handleChangeMemo } = useUpdateMemo(props.draftAppointmentId);
  const { enabledMedixs } = useMedixsSetting();

  const cancelReason =
    draftAppointment &&
    (draftAppointment.status === 'cancelled' || draftAppointment.status === 'not_prescribed')
      ? translateReason(draftAppointment)
      : null;

  if (!draftAppointment) {
    return null;
  }

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      {loading && <Loader open inside transparent appearance="white" />}
      <>
        <Flex flexDirection="column">
          <DraftAppointmentActionInfo
            status={draftAppointment.status}
            isSameDayDelivery={draftAppointment.deliveryMethod === 'same_day_delivery'}
            medicalInstitutionName={
              draftAppointment.pfDispensingRequest?.medicalInstitution?.medicalInstitution.name
            }
            prescriptionSendMethod={draftAppointment.pfDispensingRequest?.sendMethod}
          />
          <Flex justifyItems="start" marginY={theme.space.m} alignItems="center">
            <MedicationInstructionMethodLabel
              method={draftAppointment.telemedicine ? 'telemedicine' : 'faceToFace'}
            />
            <Box marginLeft="auto">
              <DraftAppointmentStatusLabel status={draftAppointment.status} />
            </Box>
          </Flex>
          <MedicationInstructionDateTime />
        </Flex>
        <ReceiveOption deliveryMethod={draftAppointment.deliveryMethod} />
        {cancelReason && (
          <Alert status="warning" marginY={theme.space.m} withIcon={false}>
            <Flex flexDirection="column">
              <Text size="s" fontWeight={theme.fontWeights.bold}>
                {cancelReason.canceledBy}
                からキャンセル
              </Text>
              {cancelReason.canceledBy !== '患者' && <Text size="s">{cancelReason.detail}</Text>}
            </Flex>
          </Alert>
        )}
        <PatientBox>
          {enabledMedixs && (
            <Box>
              <Flex alignItems="center" marginBottom={theme.space.m}>
                <Text fontWeight="bold">MEDIXS連携情報</Text>
                <Flex marginLeft={theme.space.m}>
                  <Tooltip content="希望日時確定後に連携できます">
                    <div>
                      <LinkButton disabled />
                    </div>
                  </Tooltip>
                </Flex>
              </Flex>
              <Text>未連携</Text>
            </Box>
          )}
          <Text
            fontWeight="bold"
            marginTop={enabledMedixs ? theme.space.xl : '0px'}
            marginBottom={theme.space.m}
          >
            患者情報
          </Text>
          <PatientProfileOnReceptionPane patient={draftAppointment.patient} />
          <Text fontWeight="bold" marginTop={theme.space.xl} marginBottom={theme.space.m}>
            その他
          </Text>
          <EntryList>
            <EntryList.Head>申し込み日時</EntryList.Head>
            <EntryList.Body>
              <Flex>
                <Box>
                  <Text size="m">-</Text>
                </Box>
              </Flex>
            </EntryList.Body>
          </EntryList>
          <EntryList mt={theme.space.l}>
            <EntryList.Head>メモ</EntryList.Head>
            <EntryList.Body>
              <MemoField
                disabled={updating}
                value={draftAppointment.description}
                onChange={handleChangeMemo}
              />
            </EntryList.Body>
          </EntryList>
        </PatientBox>
      </>
    </Box>
  );
});

DraftAppointment.displayName = 'DraftAppointmentOnReceptionPane';
