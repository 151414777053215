import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Grid } from '~/components/blocks';
import { CheckinEntryProfileDrawer } from '~/components/pages/Reception/CheckinEntryProfileDrawer';
import { WebVisitorProfileDrawer } from '~/components/pages/Reception/WebVisitorProfileDrawer';
import {
  DeleteFileModal,
  FollowupMessageSettingModal,
  MedixsReceptionIdentificationModal,
  PatientProfileDrawer,
} from '~/components/partials';
import { ChangeDeliveryMethodModal } from '~/components/partials/ChangeDeliveryMethodModal';

import { B2CloudDownloadModal } from './B2CloudDownloadModal';
import { BookmarkPatientListModal } from './BookmarkPatientListModal';
import { DraftAppointmentPatientProfileDrawer } from './DraftAppointmentPatientProfileDrawer';
import { Header } from './Header';
import { InvitationModal } from './InvitationModal';
import { NewReceptionDrawer } from './NewReceptionDrawer';
import { OutpatientQuestionnaireEntryProfileDrawer } from './OutpatientQuestionnaireEntryProfileDrawer';
import { ReceptionList } from './ReceptionList';
import { RequestUberDeliveryModal } from './RequestUberDeliveryModal';

const Main = styled(Grid)(({ theme }) =>
  css({
    height: '100%',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '1fr',
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: 0,
    },
  }),
);

export const Reception = () => {
  return (
    <>
      <Main>
        <Header />
        <ReceptionList />
      </Main>
      <PatientProfileDrawer />
      <B2CloudDownloadModal />
      <BookmarkPatientListModal />
      <DeleteFileModal />
      <WebVisitorProfileDrawer />
      <CheckinEntryProfileDrawer />
      <OutpatientQuestionnaireEntryProfileDrawer />
      <InvitationModal />
      <MedixsReceptionIdentificationModal />
      <NewReceptionDrawer />
      <FollowupMessageSettingModal />
      <DraftAppointmentPatientProfileDrawer />
      <ChangeDeliveryMethodModal />
      <RequestUberDeliveryModal />
    </>
  );
};
