import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Button, Grid, Icon } from '~/components/blocks';
import { MessageList, ScheduledMessageList } from '~/components/partials';
import {
  DirectVisitorFollowupMessageFragment,
  DirectVisitorMessagesFragment,
  GetMessagesOnOutpatientQuestionnaireEntryKarteQuery,
  ScheduledDirectVisitorMessageFragment,
  ScheduledDirectVisitorMessagesFragment,
  useGetMessagesOnOutpatientQuestionnaireEntryKarteLazyQuery,
} from '~/graphql';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { Panel, PanelType } from '~/state/partials/message_modal/types';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';

type Props = {
  directVisitorId: string;
};

const ButtonWrapper = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    padding: theme.space.l,
    margin: theme.space.xs,
  }),
);

const isGuestPatientMessages = (
  alias: 'all' | 'scheduled',
  data?: GetMessagesOnOutpatientQuestionnaireEntryKarteQuery,
) => {
  if (!data || data?.all?.__typename !== 'DirectVisitor') {
    return undefined;
  }

  if (alias === 'scheduled') {
    return data.scheduled as ScheduledDirectVisitorMessagesFragment;
  } else {
    return data.all as DirectVisitorMessagesFragment;
  }
};

export const MessagePane = React.memo((props: Props) => {
  const { directVisitorId } = props;
  const theme = useTheme();

  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  const [getMessage, { loading, data }] =
    useGetMessagesOnOutpatientQuestionnaireEntryKarteLazyQuery();
  const messages = useMemo(() => {
    return (
      isGuestPatientMessages('all', data)?.medicationFollowupMessages.nodes.map(
        (node) => node as DirectVisitorFollowupMessageFragment,
      ) || []
    );
  }, [data]);
  const scheduledMessages = useMemo(() => {
    return (
      isGuestPatientMessages('scheduled', data)?.medicationFollowupMessages.nodes.map(
        (node) => node as ScheduledDirectVisitorMessageFragment,
      ) || []
    );
  }, [data]);

  const handleClick = useRecoilCallback(
    ({ set }) =>
      (panel: PanelType, messageId?: string) => {
        set(patientDetailDialogMessageState, (_state) => ({
          ..._state,
          selectedMessageId: messageId || null,
        }));
        set(patientDetailDialogState, (_state) => ({
          ..._state,
          isOpen: true,
          type: 'message' as TabType,
          patientId: directVisitorId,
        }));
      },
    [directVisitorId],
  );

  useEffect(() => {
    getMessage({
      variables: {
        id: directVisitorId,
      },
    });
  }, [getMessage, directVisitorId]);

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      <Grid height="100%" gridTemplateRows="min-content min-content 1fr" gridTemplateColumns="1fr">
        {isVerified ? (
          <ButtonWrapper>
            <Button wide="fill" use="base" onClick={() => handleClick(Panel.initial)}>
              <Icon icon="comment" color="white" size="l" />
              患者にメッセージ送信
            </Button>
          </ButtonWrapper>
        ) : (
          <Box />
        )}
        <Box marginTop={theme.space.l}>
          {scheduledMessages.length > 0 && (
            <ScheduledMessageList scheduledMessages={scheduledMessages} onClick={handleClick} />
          )}
        </Box>
        <Box marginTop={theme.space.l} height="100%" overflow="auto">
          <MessageList loading={loading} messages={messages} onClick={handleClick} />
        </Box>
      </Grid>
    </Box>
  );
});

MessagePane.displayName = 'MessagePane';
