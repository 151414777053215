import { PureQueryOptions } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  GetPatientMessagesDocument,
  GetWebVisitorMessagesDocument,
  useDeactivateFollowupMessageMutation,
} from '~/graphql';
import { deleteMedicationFollowupMessageModalState } from '~/state/partials/delete_medication_followup_message_modal2/atoms';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import { webVisitorProfileDrawerState } from '~/state/reception/atoms';

export const useDeactivateFollowupMessage = () => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const patientDrawerState = useRecoilValue(profileDrawerState);
  const webVisitorDrawerState = useRecoilValue(webVisitorProfileDrawerState);
  const reset = useResetRecoilState(deleteMedicationFollowupMessageModalState);

  const [deactivateMessage] = useDeactivateFollowupMessageMutation();

  const deactivate = useCallback(
    async (messageId: string) => {
      setIsDeactivating(true);
      setError(null);

      const refetchQueries: PureQueryOptions[] = [];

      if (webVisitorDrawerState.isOpen && webVisitorDrawerState.webVisitorId) {
        refetchQueries.push({
          query: GetWebVisitorMessagesDocument,
          variables: { id: webVisitorDrawerState.webVisitorId },
        });
      }

      if (patientDrawerState.isOpen && patientDrawerState.patientId) {
        refetchQueries.push({
          query: GetPatientMessagesDocument,
          variables: { id: patientDrawerState.patientId },
        });
      }

      try {
        await deactivateMessage({
          variables: {
            input: {
              medicationFollowupMessageId: messageId,
            },
          },
          refetchQueries,
        });
        reset();
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
      }
      setIsDeactivating(false);
    },
    [
      deactivateMessage,
      patientDrawerState.isOpen,
      patientDrawerState.patientId,
      reset,
      webVisitorDrawerState.isOpen,
      webVisitorDrawerState.webVisitorId,
    ],
  );

  return {
    isDeactivating,
    error,
    deactivate,
  };
};
