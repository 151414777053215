import React from 'react';

import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import {
  AppointmentPfDispensingRequestUploadType,
  AppointmentStatus,
  DraftAppointmentOnReceptionPaneAppointmentFragment,
  ReceptionPaneEncounterFragment,
  ReceptionUberDeliveryFragment,
} from '~/graphql';

import { UberDeliveryInfo } from './UberDeliveryInfo';

type Props = {
  status: AppointmentStatus;
  telemedicine: boolean;
  isSameDayDelivery: boolean;
  uberDelivery: ReceptionUberDeliveryFragment | null | undefined;
  pfDispensingRequestUploadType: AppointmentPfDispensingRequestUploadType | null | undefined;
  draftAppointment: DraftAppointmentOnReceptionPaneAppointmentFragment | null | undefined;
  encounter: ReceptionPaneEncounterFragment | null | undefined;
};

export const AppointmentActionInfo = React.memo((props: Props) => {
  const {
    status,
    telemedicine,
    isSameDayDelivery,
    uberDelivery,
    pfDispensingRequestUploadType,
    draftAppointment,
    encounter,
  } = props;

  const uploadFromMedicalInstitution =
    pfDispensingRequestUploadType === AppointmentPfDispensingRequestUploadType.MedicalInstitution ||
    !!draftAppointment;

  if (isSameDayDelivery) {
    return (
      <UberDeliveryInfo
        uberDelivery={uberDelivery}
        status={status}
        pfDispensingRequestUploadType={pfDispensingRequestUploadType}
        draftAppointment={draftAppointment}
        encounter={encounter}
      />
    );
  }

  if (status === 'pending') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">処方箋を確認し、受付してください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'booked') {
    if (telemedicine) {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            服薬指導を行なってください
            {!encounter?.notifiedAt && (
              <ActionInfo.Description>
                薬局が混み合っており時間に間に合わない場合は、混雑時通知を送ってください
              </ActionInfo.Description>
            )}
          </ActionInfo.Title>
        </ActionInfo>
      );
    }

    if (uploadFromMedicalInstitution) {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            お薬の準備ができたら準備完了通知を送って患者にお伝えください
            <ActionInfo.Description>
              薬局が混み合っており時間に間に合わない場合は、混雑時通知を送ってください
            </ActionInfo.Description>
          </ActionInfo.Title>
          <ActionInfo.Title status="success">
            服薬指導後、会計操作を行なってください
            <ActionInfo.Description>
              会計しないと、医療機関から処方箋原本が送られません
            </ActionInfo.Description>
          </ActionInfo.Title>
        </ActionInfo>
      );
    }

    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          お薬の準備ができたら準備完了通知を送って患者にお伝えください
          <ActionInfo.Description>
            薬局が混み合っており時間に間に合わない場合は、混雑時通知を送ってください
          </ActionInfo.Description>
        </ActionInfo.Title>
        <ActionInfo.Title status="success">服薬指導後、会計操作を行なってください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge') {
    if (telemedicine && uploadFromMedicalInstitution) {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            会計操作を行なってください
            <ActionInfo.Description>
              会計しないと、医療機関から処方箋原本が送られません
            </ActionInfo.Description>
          </ActionInfo.Title>
        </ActionInfo>
      );
    }

    if (telemedicine && !uploadFromMedicalInstitution) {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">会計操作を行なってください</ActionInfo.Title>
        </ActionInfo>
      );
    }

    if (uploadFromMedicalInstitution) {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            服薬指導後、会計操作を行なってください
            <ActionInfo.Description>
              会計しないと、医療機関から処方箋原本が送られません
            </ActionInfo.Description>
          </ActionInfo.Title>
        </ActionInfo>
      );
    }
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">服薬指導後、会計操作を行なってください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'charged') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">お薬を患者住所へ発送してください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  return null;
});

AppointmentActionInfo.displayName = 'AppointmentActionInfo';
