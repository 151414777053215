import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Chip, Flex, Icon, Text, Tooltip } from '~/components/blocks';

type Props = {
  isBookmarked: boolean;
};

const CustomChip = styled(Chip)<{ isBookmarked: boolean }>(({ theme, isBookmarked }) =>
  css({
    border: isBookmarked ? theme.borders.default : 'none',
    size: 'fixed',
    backgroundColor: isBookmarked
      ? theme.colors.colorPallete.white
      : theme.colors.colorPallete.grey04,
    padding: `0 ${theme.space.m}`,
  }),
);

export const BookmarkTag = ({ isBookmarked }: Props) => {
  const theme = useTheme();
  const label = isBookmarked ? 'お気に入り登録済' : 'お気に入り未登録';
  const hintText = isBookmarked ? (
    <>
      CLINICSアプリで当薬局を
      <br />
      お気に入り登録に登録しています
    </>
  ) : (
    <>
      お気に入り薬局登録済みの患者は <br />
      リピート率が高い傾向があります。 <br />
      当薬局のお気に入り薬局登録をご案内 <br />
      してみませんか？
    </>
  );

  return (
    <>
      <Tooltip placement="left" content={hintText}>
        <CustomChip isBookmarked={isBookmarked}>
          <Flex alignItems="center">
            {isBookmarked && (
              <Icon icon="favorite-fill" color="pink" size="m" marginRight={theme.space.xs} />
            )}
            <Text
              color={isBookmarked ? 'navy' : 'grey01'}
              lineHeight="1"
              padding={`${theme.space.s} 0`}
              size="xs"
            >
              {label}
            </Text>
          </Flex>
        </CustomChip>
      </Tooltip>
    </>
  );
};
