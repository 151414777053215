import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';

import { Box, Flex, Icon, Radio, RadioGroup, Text, Tooltip } from '~/components/blocks';
import { isProduction } from '~/constants/utils';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { RequestUberDeliveryModalState } from '~/state/reception/types';

const Content = styled(Box)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
  }),
);

export const AutoRadioInput = React.memo(() => {
  const theme = useTheme();

  const [state, setState] = useRecoilState(requestUberDeliveryModalState);

  const handleAutoModeChange = useRecoilCallback(
    ({ set }) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        set(requestUberDeliveryModalState, (_state: RequestUberDeliveryModalState) => ({
          ..._state,
          autoMode: event.target.value as RequestUberDeliveryModalState['autoMode'],
        }));
      },
    [],
  );

  useEffect(() => {
    if (isProduction) {
      setState((prev) => ({
        ...prev,
        autoMode: null,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        autoMode: 'delivered',
      }));
    }
  }, [setState]);

  if (isProduction) {
    return null;
  }

  return (
    <Box>
      <Flex alignItems="center">
        <Text>QA用設定</Text>
        <Tooltip
          content={
            <Content>
              オートモードでは、配達員とマッチングした状態となり、約30秒でステータスが自動遷移します
            </Content>
          }
        >
          <Flex marginLeft={theme.space.xs}>
            <Icon icon="hint" size="m" color="navy" />
          </Flex>
        </Tooltip>
      </Flex>
      <RadioGroup orientation="vertical" marginY={theme.space.m}>
        <Radio
          name="value"
          label="【オートモード】正常に配達が完了した"
          value="delivered"
          checked={state.autoMode === 'delivered'}
          onChange={handleAutoModeChange}
        />
        <Radio
          name="value"
          label="【オートモード】患者に届けることができなかった"
          value="canceled"
          checked={state.autoMode === 'canceled'}
          onChange={handleAutoModeChange}
        />
        <Radio
          name="value"
          label="マッチングしない（20分後にアラート・70分後自動キャンセル）"
          value="unfulfilled"
          checked={state.autoMode === 'unfulfilled'}
          onChange={handleAutoModeChange}
        />
      </RadioGroup>
    </Box>
  );
});

AutoRadioInput.displayName = 'AutoRadioInput';
