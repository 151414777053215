import { atom } from 'recoil';

import {
  AppointmentPatientFileArea,
  DraftAppointmentPatientFileArea,
  OutpatientQuestionnaireEntryFileArea,
  TelemedicinePatientFileArea,
  WebVisitorFileArea,
} from './types';

export const webVisitorFileAreaState = atom<WebVisitorFileArea>({
  key: 'receptionDrawer.webVisitorFileArea',
  default: {
    type: null,
    appointmentId: null,
  },
});

export const appointmentPatientFileAreaState = atom<AppointmentPatientFileArea>({
  key: 'receptionDrawer.patientFileArea',
  default: {
    type: null,
    appointmentId: null,
    patientId: null,
  },
});

export const draftAppointmentPatientFileAreaState = atom<DraftAppointmentPatientFileArea>({
  key: 'receptionDrawer.draftAppointmentPatientFileArea',
  default: {
    type: null,
    draftAppointmentId: null,
    patientId: null,
  },
});

export const telemedicinePatientFileAreaState = atom<TelemedicinePatientFileArea>({
  key: 'receptionDrawer.telemedicinePatientFileArea',
  default: {
    type: null,
    appointmentId: null,
    patientId: null,
  },
});

export const outpatientQuestionnaireEntryFileAreaState = atom<OutpatientQuestionnaireEntryFileArea>(
  {
    key: 'receptionDrawer.outpatientQuestionnaireEntryFileArea',
    default: {
      type: null,
      outpatientQuestionnaireEntryId: null,
    },
  },
);
