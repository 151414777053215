import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Icon, Tag, Text } from '~/components/blocks';

const DeliTag = styled(Tag)(({ theme }) =>
  css({
    alignItems: 'center',
    border: theme.borders.transparent,
    background: theme.colors.background.bg,
    display: 'inline-flex',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
  }),
);

type Props = {
  text: string;
  fontSize?: 's' | 'm';
};

export const DeliveryTag = React.memo((props: Props) => {
  return (
    <DeliTag>
      <Icon icon="delivery" color="navy" size="l" />
      <Text size={props.fontSize} color="navy">
        {props.text}
      </Text>
    </DeliTag>
  );
});

DeliveryTag.displayName = 'DeliveryTag';
