import { useCallback, useState } from 'react';

import {
  GetPatientProfileMedixsPatientDocument,
  GetReceptionPaneAppointmentDocument,
  ReceptionPanePatientFragment,
  useUnidentifyMedixsPatientMutation,
} from '~/graphql';

type Props = {
  patient: ReceptionPanePatientFragment | undefined | null;
  appointmentId: string;
};

export const useUnlinkMedixsPatient = ({ patient, appointmentId }: Props) => {
  const [error, setError] = useState<Error | null>(null);
  const [unidentifyMedixsPatient, { loading }] = useUnidentifyMedixsPatientMutation({
    refetchQueries: [
      { query: GetReceptionPaneAppointmentDocument, variables: { appointmentId } },
      { query: GetPatientProfileMedixsPatientDocument, variables: { patientId: patient?.id } },
    ],
    onError: (e) => {
      setError(e);
    },
  });
  const handleUnlink = useCallback(() => {
    if (patient?.medixsPatient && appointmentId) {
      unidentifyMedixsPatient({
        variables: {
          input: {
            medixsPatientId: patient.medixsPatient?.id,
          },
        },
      });
    }
  }, [unidentifyMedixsPatient, patient?.medixsPatient, appointmentId]);

  return { handleUnlink, loading, error };
};
