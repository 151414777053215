import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Collapse, EntryList, Flex, Text } from '~/components/blocks';
import { AppointmentStatusLabel, DispensingRequestCollapse } from '~/components/partials';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { AppointmentDeliveryMethod, KarteHistoryFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  appointment: KarteHistoryFragment;
};

export const History = (props: Props) => {
  const { appointment } = props;
  const theme = useTheme();

  const applicationDateTime = `${Label.YYYYMMDDja(appointment.createdAt)} ${Label.HHMM(appointment.createdAt)}`;
  const charge = appointment.charge;

  return (
    <Collapse label={applicationDateTime}>
      <Box padding={`0 ${theme.space.m} ${theme.space.l}`}>
        <Flex alignItems="center" justifyContent="space-between" paddingY={theme.space.l}>
          <MedicationInstructionMethodLabel
            method={appointment.telemedicine ? 'telemedicine' : 'faceToFace'}
          />
          <AppointmentStatusLabel
            status={appointment.status}
            uberDelivery={appointment.uberDelivery}
            isSameDayDelivery={
              appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
            }
          />
        </Flex>
        <DispensingRequestCollapse appointmentId={appointment.id} />
        <Flex alignItems="flex-start" justifyContent="space-between" mt={theme.space.l}>
          <EntryList>
            <EntryList.Head>担当薬剤師</EntryList.Head>
            <EntryList.Body>
              <strong>
                {charge
                  ? `${charge.practitioner.familyName}${charge.practitioner.givenName}`
                  : 'ー'}
              </strong>
            </EntryList.Body>
          </EntryList>
        </Flex>
        <Box padding={theme.space.l} mt={theme.space.m} bg={theme.colors.background.bg}>
          <EntryList>
            <EntryList.Head>合計金額</EntryList.Head>
            <EntryList.Body>
              <Text size="l" fontWeight="bold">
                {typeof charge?.amount === 'string' ? Label.amount(+charge.amount) : 'ー'}
              </Text>
            </EntryList.Body>
          </EntryList>
        </Box>
      </Box>
    </Collapse>
  );
};
