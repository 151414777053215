export const GuestPatientTabMap = {
  profile: 'profile',
  history: 'history',
  questionnaire: 'questionnaire',
  message: 'message',
  file: 'file',
} as const;

export const NewReceptionTabMap = {
  new: 'new',
  search: 'search',
} as const;

export const TabMap = {
  reception: 'reception',
  history: 'history',
  questionnaire: 'questionnaire',
  message: 'message',
  file: 'file',
} as const;

export const PatientFileTabMap = {
  patient: 'patient',
  medicalInstitution: 'medicalInstitution',
} as const;

export const PatientProfileTabMap = {
  app: 'app',
  medixs: 'medixs',
} as const;

export const WebVisitorTabMap = {
  reception: 'reception',
  history: 'history',
  message: 'message',
  file: 'file',
} as const;

export const PatientDetailDiaLogTabMap = {
  tracingreport: 'tracingreport',
  message: 'message',
} as const;
