import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { Box, Grid, Loader } from '~/components/blocks';
import { ReceptionDrawer } from '~/components/layouts/ReceptionDrawer';
import { ReceptionDrawerFileMenu } from '~/components/layouts/ReceptionDrawer/FileMenu';
import { DeleteMedicationFollowupMessageModal } from '~/components/partials';
import { useMarkNotification } from '~/components/partials/PatientProfileDrawer/use-mark-notification';
import { FileMenuButton } from '~/components/partials/ReceptionDrawer/FileMenuButton';
import { ReceptionMainBox } from '~/components/partials/ReceptionDrawer/ReceptionMainBox';
import { VisitorProfile } from '~/components/partials/VisitorProfile';
import { WebVisitorChargeModal } from '~/components/partials/WebVisitorChargeModal';
import { WebVisitorKarte } from '~/components/partials/WebVisitorKarte';
import { ReceptionFileType } from '~/constants/reception_file_type';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { webVisitorProfileDrawerState, webVisitorTabState } from '~/state/reception/atoms';
import { webVisitorFileAreaState } from '~/state/reception_drawer/atoms';
import { ReceptionDrawerFileType } from '~/state/reception_drawer/types';

import { FileArea } from './FileArea';
import { Footer } from './Footer';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { NotificationCongestionModal } from './NotificationCongestionModal';
import { useFetchAppointment } from './use-fetch-appointment';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const WebVisitorProfileDrawer = () => {
  const theme = useTheme();
  const {
    state: { isOpen, appointmentId },
    handleTransitionEnd,
  } = useSwapDrawer(webVisitorProfileDrawerState, 'appointmentId');

  const [isFileAreaOpened, setOpenFileArea] = useState(false);
  const setFileAreaState = useSetRecoilState(webVisitorFileAreaState);

  const handleOpenFileArea = useCallback(
    (type: ReceptionDrawerFileType) => {
      setOpenFileArea(true);
      setFileAreaState((state) => ({ ...state, type, appointmentId }));
    },
    [appointmentId, setFileAreaState],
  );
  const handleCloseFileArea = useCallback(() => {
    setOpenFileArea(false);
  }, []);

  const { loading, appointment } = useFetchAppointment(isOpen, appointmentId);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(webVisitorProfileDrawerState);
        reset(webVisitorTabState);
      },
    [],
  );

  useEffect(() => {
    setOpenFileArea(false);
  }, [appointmentId, isOpen]);

  useMarkNotification(appointmentId);

  return (
    <>
      <ReceptionDrawer
        isOpen={isOpen}
        onClose={handleClose}
        onTransitionEnd={handleTransitionEnd}
        isExpended={isFileAreaOpened}
      >
        <ReceptionDrawerFileMenu>
          <FileMenuButton
            fileName="処方箋"
            active={true}
            statusLabel="データあり"
            onClick={() => handleOpenFileArea(ReceptionFileType.prescription)}
            marginTop={theme.space.xl}
          />
          <FileMenuButton fileName="保険証" active={false} statusLabel="データなし" />
          <FileMenuButton
            fileName="問診票"
            active={true}
            statusLabel="データあり"
            onClick={() => handleOpenFileArea(ReceptionFileType.questionnaire)}
          />
          <FileMenuButton fileName="お薬手帳" active={false} statusLabel="データなし" />
        </ReceptionDrawerFileMenu>
        {isFileAreaOpened && <FileArea onClose={handleCloseFileArea} />}
        <ReceptionMainBox>
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <VisitorProfile loading={loading} visitor={appointment?.webVisitor} />
            </ProfileBox>
            {appointment ? (
              <>
                <Box overflow="auto">
                  <WebVisitorKarte appointment={appointment} />
                </Box>
                {appointment.webVisitor && <Footer appointment={appointment} />}
              </>
            ) : (
              <Loader inside open />
            )}
          </Grid>
        </ReceptionMainBox>
      </ReceptionDrawer>
      <WebVisitorChargeModal />
      <NotificationCongestionModal />
      <DeleteMedicationFollowupMessageModal />
      <NotificationConfirmModal />
    </>
  );
};
