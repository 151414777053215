import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { Box, Grid, Loader } from '~/components/blocks';
import { ReceptionDrawer } from '~/components/layouts/ReceptionDrawer';
import { ReceptionDrawerFileMenu } from '~/components/layouts/ReceptionDrawer/FileMenu';
import { FileMenuButton } from '~/components/partials/ReceptionDrawer/FileMenuButton';
import { ReceptionMainBox } from '~/components/partials/ReceptionDrawer/ReceptionMainBox';
import { VisitorProfile } from '~/components/partials/VisitorProfile';
import { ReceptionFileType } from '~/constants/reception_file_type';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { outpatientQuestionnaireEntryProfileDrawerState } from '~/state/reception/atoms';
import { outpatientQuestionnaireEntryFileAreaState } from '~/state/reception_drawer/atoms';
import { ReceptionDrawerFileType } from '~/state/reception_drawer/types';

import { FileArea } from './FileArea';
import { FinishConfirmModal } from './FinishConfirmModal';
import { Footer } from './Footer';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { OutpatientQuestionnaireEntryKarte } from './OutpatientQuestionnaireEntryKarte';
import { useFetchOutpatientQuestionnaireEntry } from './use-fetch_outpatient_questionnaire_entry';
import { useMarkNotification } from './use-mark-notification';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const OutpatientQuestionnaireEntryProfileDrawer = () => {
  const theme = useTheme();
  const {
    state: { isOpen },
    handleTransitionEnd,
  } = useSwapDrawer(
    outpatientQuestionnaireEntryProfileDrawerState,
    'outpatientQuestionnaireEntryId',
  );
  const [isFileAreaOpened, setOpenFileArea] = useState(false);
  const setFileAreaState = useSetRecoilState(outpatientQuestionnaireEntryFileAreaState);

  const { outpatientQuestionnaireEntry, loading } = useFetchOutpatientQuestionnaireEntry();
  useMarkNotification();

  const handleOpenFileArea = useCallback(
    (type: ReceptionDrawerFileType) => {
      setOpenFileArea(true);
      setFileAreaState((state) => ({
        ...state,
        type,
        outpatientQuestionnaireEntryId: outpatientQuestionnaireEntry?.id || null,
      }));
    },
    [outpatientQuestionnaireEntry, setFileAreaState],
  );
  const handleCloseFileArea = useCallback(() => {
    setOpenFileArea(false);
  }, []);
  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(outpatientQuestionnaireEntryProfileDrawerState);
      },
    [],
  );

  useEffect(() => {
    setOpenFileArea(false);
  }, [outpatientQuestionnaireEntry?.id, isOpen]);

  return (
    <>
      <ReceptionDrawer
        isOpen={isOpen}
        onClose={handleClose}
        onTransitionEnd={handleTransitionEnd}
        isExpended={isFileAreaOpened}
      >
        <ReceptionDrawerFileMenu>
          <FileMenuButton
            fileName="処方箋"
            active={false}
            statusLabel="データなし"
            marginTop={theme.space.xl}
          />
          <FileMenuButton fileName="保険証" active={false} statusLabel="データなし" />
          <FileMenuButton
            fileName="問診票"
            active={true}
            statusLabel="データあり"
            onClick={() => handleOpenFileArea(ReceptionFileType.questionnaire)}
          />
          <FileMenuButton fileName="お薬手帳" active={false} statusLabel="データなし" />
        </ReceptionDrawerFileMenu>
        {isFileAreaOpened && <FileArea onClose={handleCloseFileArea} />}
        <ReceptionMainBox>
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <VisitorProfile
                loading={loading}
                visitor={outpatientQuestionnaireEntry?.directVisitor}
              />
            </ProfileBox>
            {outpatientQuestionnaireEntry ? (
              <Box overflow="auto">
                <OutpatientQuestionnaireEntryKarte
                  outpatientQuestionnaireEntry={outpatientQuestionnaireEntry}
                />
              </Box>
            ) : (
              <Loader inside open />
            )}
            <Footer outpatientQuestionnaireEntry={outpatientQuestionnaireEntry} />
          </Grid>
        </ReceptionMainBox>
      </ReceptionDrawer>
      <NotificationConfirmModal />
      <FinishConfirmModal />
    </>
  );
};
