import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Flex, Icon, Text } from '~/components/blocks';
import { NotificationListItem } from '~/components/partials/NotificationListItem';
import { AppointmentStatus, OrganizationNotificationFragment } from '~/graphql';
import { notificationPanelState } from '~/state/layouts/SharedAppShell/atoms';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  newReceptionDrawerState,
  outpatientQuestionnaireEntryProfileDrawerState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

type Props = {
  notification: OrganizationNotificationFragment;
};

export const OrganizationNotification = (props: Props) => {
  const { notification } = props;
  const router = useRouter();
  const theme = useTheme();

  const handleOpenReception = useRecoilCallback(
    ({ set, reset }) =>
      async (e: React.MouseEvent<HTMLLIElement>) => {
        // 受付ドロアーで既読処理をするため、親要素で既読処理が実行されないようにする
        e.stopPropagation();

        if (router.pathname !== '/reception') {
          await router.push('/reception');
        }

        // 受付通知パネルを閉じる
        set(notificationPanelState, (_state) => ({ ..._state, isOpen: false }));

        if (notification.appointment?.patient) {
          set(profileDrawerState, {
            isOpen: true,
            appointmentId: notification.appointment.id,
            patientId: notification.appointment.patient.id,
            error: null,
          });
          reset(webVisitorProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(checkinEntryProfileDrawerState);
          reset(outpatientQuestionnaireEntryProfileDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }

        if (notification.appointment?.webVisitor) {
          set(webVisitorProfileDrawerState, {
            isOpen: true,
            appointmentId: notification.appointment.id,
            webVisitorId: notification.appointment.webVisitor.id,
          });
          reset(profileDrawerState);
          reset(newReceptionDrawerState);
          reset(checkinEntryProfileDrawerState);
          reset(outpatientQuestionnaireEntryProfileDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }

        if (notification.draftAppointment) {
          const patientId = notification.draftAppointment.patient.id;
          const draftAppointment = notification.draftAppointment;
          const appointmentId = draftAppointment.appointment?.id;

          // 本予約済みの場合は、本予約のドロアーを表示する
          if (appointmentId) {
            set(profileDrawerState, {
              isOpen: true,
              appointmentId,
              patientId,
              error: null,
            });
            reset(draftAppointmentPatientProfileDrawerState);
          } else {
            set(draftAppointmentPatientProfileDrawerState, {
              isOpen: true,
              draftAppointmentId: draftAppointment.id,
              patientId,
              error: null,
            });
            reset(profileDrawerState);
          }

          reset(webVisitorProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(checkinEntryProfileDrawerState);
          reset(outpatientQuestionnaireEntryProfileDrawerState);
        }
      },
    [
      notification.appointment?.id,
      notification.appointment?.patient,
      notification.appointment?.webVisitor,
      notification.draftAppointment,
      router,
    ],
  );
  const isTaskUncompleted =
    !notification.isRead || notification.appointment?.status === AppointmentStatus.Pending;

  return (
    <NotificationListItem onClick={handleOpenReception} isMarked={isTaskUncompleted}>
      <Flex flexDirection="column" marginRight={theme.space.m}>
        <Text fontWeight="bold" size="s">
          {notification.subject}
        </Text>
        <Text size="xs" color="grey01" marginTop={theme.space.xs}>
          {`${Label.YYYYMMDDja(notification.updatedAt)} ${Label.HHMM(notification.updatedAt)}`}
        </Text>
      </Flex>
      <Icon icon="forward-line" size="m" marginLeft="auto" />
    </NotificationListItem>
  );
};
