import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { CSSProperties, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import {
  background,
  BackgroundProps,
  compose,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  variant,
} from 'styled-system';

import { CloseBtn } from '../CloseBtn';

type Variant = 'permanent' | 'persistent' | 'temporary' | 'inside';

type Anchor = 'left' | 'right';

type Props = PaddingProps &
  MarginProps &
  BackgroundProps & {
    open: boolean;
    onClose?: () => void;
    variant?: Variant;
    anchor?: Anchor;
    width?: string;
    fullHeight?: boolean;
    onTransitionEnd?: () => void;
    children?: React.ReactNode;
  };

const DEFAULT_WIDTH = '380px';

export const DEFAULT_VARIANT = 'temporary' as const;

const DEFAULT_ANCHOR = 'right' as const;

const Close = styled(CloseBtn, {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'absolute',
    top: theme.space.l,
    right: theme.space.l,
  }),
);

const Root = styled('div', {
  shouldForwardProp,
})<
  { width: string; variant?: Variant; anchor?: Anchor; fullHeight?: boolean } & PaddingProps &
    MarginProps &
    BackgroundProps
>(
  ({ theme, width, fullHeight }) =>
    css({
      display: 'block',
      height: fullHeight ? '100%' : `calc(100% - ${theme.layouts.appHeader.height})`,
      width,
      backgroundColor: theme.colors.background.default,
      zIndex: theme.zIndices.drawer,
      boxShadow: `0 10px 10px ${theme.colors.border.default}`,
      overflow: 'auto',
      willChange: 'position',
      [`@media ${theme.mediaQueries.portrait}`]: {
        height: fullHeight
          ? '100%'
          : `calc(100% - ${theme.layouts.appHeader.height} - ${theme.layouts.responsiveMenu.height})`,
        boxShadow: `-10px 0 10px -10px ${theme.colors.border.default}`,
      },
    }),
  ({ theme, width, anchor = DEFAULT_ANCHOR }) =>
    compose(
      variant({
        prop: 'variant',
        variants: {
          temporary: css({
            position: 'fixed',
            top: theme.layouts.appHeader.height,
            [anchor]: `-${width}`,
            transition: `right ${theme.transitions.default} ease-out`,
          }),
          persistent: css({
            height: '100%',
            position: 'relative',
            transition: `width ${theme.transitions.default} ease-out`,
            boxShadow: 'none',
          }),
          permanent: css({
            position: 'relative',
            height: '100%',
            borderLeft: `1px solid ${theme.colors.border.default}`,
            boxShadow: `none`,
          }),
          inside: css({
            height: '100%',
            position: 'relative',
            transition: `right ${theme.transitions.default} ease-out`,
            boxShadow: 'none',
          }),
        },
      }),
    ),
  () => compose(padding, margin, background),
);

Root.defaultProps = {
  variant: DEFAULT_VARIANT,
  anchor: 'right',
};

const Inner = styled('div', {
  shouldForwardProp,
})<{ width: string; anchor?: Anchor }>(
  ({ width }) =>
    css({
      position: 'absolute',
      top: 0,
      display: 'block',
      height: '100%',
      width,
      overflow: 'auto',
    }),
  () =>
    compose(
      variant({
        prop: 'anchor',
        variants: {
          right: css({
            left: 0,
          }),
          left: css({
            right: 0,
          }),
        },
      }),
    ),
);

export const Drawer: React.FC<Props> = (props) => {
  const {
    width = DEFAULT_WIDTH,
    variant = DEFAULT_VARIANT,
    anchor,
    fullHeight,
    padding,
    margin,
    background,
    onTransitionEnd,
    onClose,
  } = props;
  const transitionStyles = useMemo<Partial<Record<TransitionStatus, CSSProperties>>>(() => {
    switch (variant) {
      case 'persistent':
        return {
          entering: { width: 0 },
          entered: { width },
          exiting: { width },
          exited: { width: 0 },
        };
      case 'inside':
        return {
          entering: { width: 0 },
          entered: { width },
          exiting: { width },
          exited: { width: 0 },
        };
      case 'temporary':
        return {
          entering: { right: 0 },
          entered: { right: 0 },
          exiting: { right: `calc(-${width} - 40px)` },
          exited: { right: `calc(-${width} - 40px)` },
        };
      case 'permanent':
      default:
        return {
          entering: {},
          entered: {},
          exiting: {},
          exited: {},
        };
    }
  }, [variant, width]);

  return (
    <Transition in={props.open} timeout={0}>
      {(state) => (
        <Root
          width={width}
          anchor={anchor}
          variant={variant}
          fullHeight={fullHeight}
          padding={padding}
          margin={margin}
          background={background}
          onTransitionEnd={onTransitionEnd}
          style={{
            ...transitionStyles[state],
          }}
        >
          <Inner width={width} anchor={anchor}>
            {props.children}
          </Inner>
          {onClose && <Close onClick={onClose} />}
        </Root>
      )}
    </Transition>
  );
};
