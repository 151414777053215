import { useRecoilCallback } from 'recoil';

import { ReceptionPanePatientFragment } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { medixsReceptionIdentificationModalState } from '~/state/partials/medixs_reception_identification_modal/atoms';

type Props = {
  patient: ReceptionPanePatientFragment | undefined | null;
  appointmentId: string;
};

export const useLinkMedixsPatient = ({ patient, appointmentId }: Props) => {
  const { medixsSetting } = useMedixsSetting();

  const openMedixsReceptionIdentificationModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(medixsReceptionIdentificationModalState, {
          isOpen: true,
          error: null,
          patientId: patient?.id || null,
          appointmentId: appointmentId,
          medixsSettingId: medixsSetting?.id || null,
          selectedMedixsReception: null,
          selectedDate: null,
        });
      },
    [patient, appointmentId, medixsSetting?.id],
  );

  return { openMedixsReceptionIdentificationModal };
};
