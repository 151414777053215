import React, { useMemo } from 'react';

import { ClipButton } from '~/components/partials';
import { QuestionnaireSheetFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  disabled: boolean;
  sheet?: QuestionnaireSheetFragment;
};

const buildText = (sheet: QuestionnaireSheetFragment) => {
  const { updatedAt, answers } = sheet;

  const updatedDate = updatedAt ? `最終更新日: ${Label.YYYYMMDDja(updatedAt)}` : null;
  const answersText = answers.map(({ title, value }) => {
    return `Q：${title}
${value.join('\n')}`;
  });
  return `${updatedDate}
${answersText.join('\n\n')}`;
};

export const QuestionnaireSheetClipButton = React.memo((props: Props) => {
  const { disabled, sheet } = props;

  const clipboardText = useMemo(() => {
    if (sheet) {
      return buildText(sheet);
    } else {
      return '';
    }
  }, [sheet]);

  return (
    <ClipButton size="m" disabled={disabled} iconSize="m" text={clipboardText} btnText="コピー" />
  );
});

QuestionnaireSheetClipButton.displayName = 'QuestionnaireSheetClipButton';
