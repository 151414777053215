import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Flex, Icon, Text } from '~/components/blocks';
import { DeliveryMethodLabel } from '~/constants/delivery_method';
import { AppointmentDeliveryMethod } from '~/graphql';

type Props = {
  deliveryMethod: AppointmentDeliveryMethod;
};

const Root = styled(Box)(({ theme }) =>
  css({
    width: '100%',
    background: theme.colors.background.bg,
    marginTop: theme.space.s,
  }),
);

export const B2CloudField = React.memo((props: Props) => {
  const theme = useTheme();
  const { deliveryMethod } = props;

  return (
    <Root>
      <Flex alignItems="center" paddingLeft={theme.space.m} paddingY={theme.space.m}>
        <Icon size="l" icon="delivery" marginRight={theme.space.s} />
        <Text size="s" color="black">
          {DeliveryMethodLabel[deliveryMethod]}
        </Text>
      </Flex>
    </Root>
  );
});

B2CloudField.displayName = 'B2CloudField';
