import { useTheme } from '@emotion/react';
import * as Sentry from '@sentry/nextjs';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box, EntryList, Flex, Icon, Text } from '~/components/blocks';
import { PrescriptionContent } from '~/components/partials/ReceptionDrawer/PrescriptionContent';
import { PrescriptionLoader } from '~/components/partials/ReceptionDrawer/PrescriptionLoader';
import { AppointmentStatus } from '~/graphql';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { telemedicinePatientFileAreaState } from '~/state/reception_drawer/atoms';
import { isPdf } from '~/utils/file';
import { openPreviewPage } from '~/utils/pf_dispensing_request';

import { PdfViewer } from './PdfViewer';
import { PrescriptionImgViewer } from './PrescriptionImgViewer';
import { useFetchDispensingRequests } from './use-fetch-dispensing_requests';

export const Prescription = () => {
  const { appointmentId } = useRecoilValue(telemedicinePatientFileAreaState);
  const theme = useTheme();

  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [hasPdfError, setHasPdfError] = useState(false);

  const {
    loading,
    timeout,
    error,
    appointmentStatus,
    dispensingRequests,
    medicalInstitution,
    medicalInstitutionCount,
  } = useFetchDispensingRequests(appointmentId);
  const { electronicPrescription } = useOrganizationElectronicPrescription();

  const hasPdf = dispensingRequests.some((dreq) =>
    dreq?.prescriptionImages.some((image) => image && isPdf(image.attachment.contentType)),
  );
  const prescriptionImages = dispensingRequests.flatMap((dreq) => dreq?.prescriptionImages || []);

  const isPrintable = appointmentStatus !== AppointmentStatus.Pending;

  const handleClick = useCallback(() => {
    if (appointmentId) {
      openPreviewPage(appointmentId, true);
    }
  }, [appointmentId]);
  const handlePdfError = useCallback((error: Error) => {
    setHasPdfError(true);
    Sentry.captureMessage(`react-pdf Error: message=${error.message}`);
  }, []);
  const handlePdfLoad = useCallback(() => setIsPdfLoading(false), []);

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l}>
        <Icon size="xl" icon="drug" />
        <Text fontWeight="bold" marginLeft={theme.space.xs}>
          {electronicPrescription ? '処方箋または処方内容（控え）' : '処方箋'}
        </Text>
      </Flex>
      {timeout ? (
        <Box padding={theme.space.m}>
          <Alert status="error">
            処方箋の取得に時間がかかっています。
            <br />
            しばらく待ってから、再度アクセスしてください
          </Alert>
        </Box>
      ) : error ? (
        <Box padding={theme.space.m}>
          <Alert status="error">取得できませんでした</Alert>
        </Box>
      ) : loading ? (
        <PrescriptionLoader />
      ) : (
        <Box>
          {prescriptionImages.length > 0 ? (
            <>
              {medicalInstitution && (
                <Alert status="info">
                  <Text size="s">
                    オンライン診療を受診した患者です。処方箋原本は医療機関より共有されます
                  </Text>
                </Alert>
              )}
              <PrescriptionContent
                onClick={hasPdf && (hasPdfError || isPdfLoading) ? undefined : handleClick}
              >
                {prescriptionImages.map((image) => {
                  if (image) {
                    return isPdf(image.attachment.contentType) ? (
                      <PdfViewer
                        key={image.attachment.id}
                        src={image.attachment.url}
                        onError={handlePdfError}
                        onLoad={handlePdfLoad}
                      />
                    ) : (
                      <PrescriptionImgViewer
                        key={image.attachment.id}
                        src={image.attachment.url}
                        alt={image.attachment.title}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                <PrescriptionContent.PreviewButton icon use="base">
                  {isPrintable ? (
                    <Icon color="white" icon="print" size="l" />
                  ) : (
                    <Icon color="white" icon="blank" size="l" />
                  )}
                </PrescriptionContent.PreviewButton>
              </PrescriptionContent>
            </>
          ) : dispensingRequests.length > 0 && dispensingRequests.every((dr) => !dr) ? (
            <Box padding={theme.space.m}>
              <Alert status="warning">医療機関によって処方箋が取り消されました</Alert>
            </Box>
          ) : (
            <Box padding={theme.space.m}>
              <Alert status="info">
                {electronicPrescription
                  ? '医療機関より送られてきた処方箋または処方内容（控え）をご確認ください'
                  : '医療機関より送られてきた処方箋をご確認ください'}
              </Alert>
            </Box>
          )}
          {medicalInstitution && (
            <Box padding={theme.space.m} paddingTop={0}>
              <Text
                fontWeight="bold"
                size="s"
                marginTop={theme.space.l}
                marginBottom={theme.space.m}
              >
                医療機関情報
              </Text>
              <EntryList size="s">
                <EntryList.Head>医療機関名</EntryList.Head>
                <EntryList.Body>{medicalInstitution.name}</EntryList.Body>
              </EntryList>
              <EntryList size="s">
                <EntryList.Head>住所</EntryList.Head>
                <EntryList.Body>{medicalInstitution.address || '未登録'}</EntryList.Body>
              </EntryList>
              <EntryList size="s">
                <EntryList.Head>連絡先</EntryList.Head>
                <EntryList.Body>{medicalInstitution.tel || '未登録'}</EntryList.Body>
              </EntryList>
              <EntryList size="s">
                <EntryList.Head>担当</EntryList.Head>
                <EntryList.Body>{medicalInstitution.staffName}</EntryList.Body>
              </EntryList>
              {medicalInstitutionCount > 1 && (
                <Text size="s" marginTop={theme.space.l}>
                  ※複数の医療機関情報があります。詳細については処方箋をご確認ください
                </Text>
              )}
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};
