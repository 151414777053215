import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Button, Checkbox, Modal, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';

const CustomAlert = styled(Alert)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    padding: theme.space.m,
    '& ol': {
      paddingLeft: '2.6em',
    },
    '& li': {
      listStyleType: 'disc',
      listStylePosition: 'inside',
    },
  }),
);

export const CheckList = () => {
  const theme = useTheme();
  const { checked } = useRecoilValue(requestUberDeliveryModalState);
  const handleChange = useRecoilCallback(
    ({ set }) =>
      (_checked: boolean) => {
        set(requestUberDeliveryModalState, (_state) => ({
          ..._state,
          checked: _checked,
        }));
      },
    [],
  );

  const handleNext = useRecoilCallback(
    ({ set }) =>
      () => {
        set(requestUberDeliveryModalState, (_state) => ({
          ..._state,
          step: 'selectQuantity' as const,
        }));
      },
    [],
  );

  return (
    <>
      <Modal.Body>
        <Text size="s" marginBottom={theme.space.m}>
          集荷依頼の前に、以下の対応が全て完了しているか確認してください
        </Text>
        <CustomAlert status="info" withIcon={false}>
          <Checkbox
            checked={checked}
            onChange={() => handleChange(!checked)}
            label="全て対応しました"
            fontWeight="bold"
          />
          <ol>
            <li>
              今回配達するお薬に、
              <ExternalLink url="https://intercom.help/pharms/ja/articles/8965479-%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94-uber-eats-%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E5%88%A9%E7%94%A8%E6%99%82%E3%81%AE%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A0%85">
                Uber Direct 禁止処方箋薬
              </ExternalLink>
              が含まれていないことを確認しました
            </li>
            <li>
              <ExternalLink url="https://intercom.help/pharms/ja/articles/8965484-%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94-uber-eats-%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E5%88%A9%E7%94%A8%E6%99%82%E3%81%AE%E8%96%AC%E5%B1%80%E5%90%91%E3%81%91%E8%B3%87%E6%9D%90">
                患者向け・配達員向け資材
              </ExternalLink>
              の対応方法について確認し、資材を印刷しました
            </li>
            <li>
              <ExternalLink url="https://clinics.my.salesforce.com/sfc/p/#28000001G064/a/2j000008uu5Q/vs6n9PaUwqpWv06MjExGpxIEQDZV_J0hhbgt_9Rxvdc">
                お薬の梱包ルール
              </ExternalLink>
              に沿って適切に梱包し、資材を貼付しました
            </li>
          </ol>
        </CustomAlert>
      </Modal.Body>
      <Modal.Footer>
        <Button size="m" use="base" disabled={!checked} onClick={handleNext}>
          次へ
        </Button>
      </Modal.Footer>
    </>
  );
};
