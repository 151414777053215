import React from 'react';

import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import { CheckinEntryStatus } from '~/graphql';

type Props = {
  status: CheckinEntryStatus;
};

export const CheckinEntryActionInfo = React.memo(({ status }: Props) => {
  if (status === 'entered') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          お薬の準備が完了したら、通知を送って患者を呼び出してください
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'notified') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">患者の来局をお待ちください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  return null;
});

CheckinEntryActionInfo.displayName = 'CheckinEntryActionInfo';
