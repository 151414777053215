import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { compose, MarginProps, variant } from 'styled-system';

import { Button, Flex, Icon, IconName, Text } from '~/components/blocks';

type UseType = 'default' | 'error';

type Props = {
  onClick?: () => void;
  fileName: string;
  active: boolean;
  statusLabel: string;
  statusIcon?: IconName;
  use?: UseType;
} & MarginProps;

const CustomButton = styled(Button)<{ customUse: UseType }>(({ theme }) =>
  compose(
    variant({
      prop: 'customUse',
      variants: {
        default: css({
          '&:disabled': {
            background: theme.colors.colorPallete.grey03,
          },
        }),
        error: css({
          background: theme.colors.colorPallete.lightPink,
          cursor: 'not-allowed',
          pointerEvents: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }),
      },
    }),
  ),
);

const StatusText = styled(Text)<{ customUse: UseType }>(
  () =>
    css({
      letterSpacing: '0.6px',
      textAlign: 'center',
      [`svg:first-of-type`]: {
        marginRight: '0px',
      },
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'customUse',
        variants: {
          default: css({
            color: theme.colors.colorPallete.navy,
          }),
          error: css({
            color: theme.colors.colorPallete.pink,
          }),
        },
      }),
    ),
);

export const FileMenuButton = (props: Props) => {
  const {
    onClick,
    fileName,
    active,
    statusLabel,
    statusIcon,
    use = 'default',
    ...marginProps
  } = props;
  const theme = useTheme();
  const disabled = use === 'default' && !active;

  return (
    <CustomButton
      marginBottom={theme.space.m}
      marginX={theme.space.m}
      onClick={onClick}
      disabled={disabled}
      customUse={use}
      {...marginProps}
    >
      <Flex alignItems="center" flexDirection="column">
        <Text>{fileName}</Text>
        <StatusText size="xxs" customUse={use}>
          {statusLabel}
          {statusIcon && <Icon icon={statusIcon} color="navy" size="xxs" />}
        </StatusText>
      </Flex>
    </CustomButton>
  );
};
