import { atom } from 'recoil';

import {
  B2CloudDownloadModalState,
  BookmarkPatientListModalState,
  CancelDraftAppointmentPaneState,
  ChargeModalState,
  CheckinEntryProfileDrawerState,
  CheckinFinishConfirmModalModalState,
  CheckinNotifyConfirmModalState,
  DraftAppointmentPatientProfileDrawerState,
  FinishDraftAppointmentModalState,
  GuestPatientFileState,
  GuestPatientHistoryPageInfo,
  GuestPatientSearchPageInfo,
  GuestPatientTab,
  InvitationHistoryPageInfoState,
  InvitationModal,
  NewReceptionDrawerState,
  NewReceptionPanelMap,
  NewReceptionTab,
  NotificationConfirmModalState,
  NotificationCongestionModalState,
  OutpatientQuestionnaireEntryFinishConfirmModalModalState,
  OutpatientQuestionnaireEntryProfileDrawerState,
  OutpatientQuestionnaireNotifyConfirmModalState,
  PrescriptionQrCodeReaderDialog,
  ReceptionPageInfo,
  RequestAppointmentModalState,
  RequestUberDeliveryModalState,
  WebVisitorFileState,
  WebVisitorProfileDrawerState,
  WebVisitorTab,
} from './types';

export const b2CloudDownloadModalState = atom<B2CloudDownloadModalState>({
  key: 'reception.b2CloudDownloadModal',
  default: {
    isOpen: false,
    disabled: false,
    error: null,
    appointmentIds: [],
  },
});

export const bookmarkPatientListModalState = atom<BookmarkPatientListModalState>({
  key: 'reception.bookmarkPatientListModal',
  default: {
    isOpen: false,
    disabled: false,
    error: null,
    page: 1,
    totalPage: 1,
    perPage: 25,
    yearMonth: null,
    patientCount: 0,
  },
});

export const checkinFinishConfirmModalModalState = atom<CheckinFinishConfirmModalModalState>({
  key: 'reception.checkinListModal',
  default: {
    isOpen: false,
    checkinEntryId: null,
  },
});

export const checkinNotifyConfirmModalState = atom<CheckinNotifyConfirmModalState>({
  key: 'reception.checkinNotifyConfirmModal',
  default: {
    isOpen: false,
    checkinEntryId: null,
  },
});

export const receptionPageInfoState = atom<ReceptionPageInfo>({
  key: 'reception.receptionPageInfo',
  default: {
    page: 1,
    totalPage: 1,
    perPage: 25,
    orderBy: { field: 'start', direction: 'asc' },
    date: new Date(),
    statuses: [],
    patientName: null,
  },
});

export const invitationModalState = atom<InvitationModal>({
  key: 'reception.invitationModal',
  default: {
    isOpen: false,
    invitationCode: '',
    invitationCodeId: '',
    phoneNumber: '',
    memo: '',
  },
});

export const invitationHistoryPageInfoState = atom<InvitationHistoryPageInfoState>({
  key: 'reception.invitationHistoryPageInfo',
  default: {
    isSendFailed: true,
    isNotApplied: true,
    page: 1,
    totalPage: 1,
    perPage: 25,
    error: null,
  },
});

export const guestPatientHistoryPageInfoState = atom<GuestPatientHistoryPageInfo>({
  key: 'reception.guestPatientHistoryPageInfo',
  default: {
    guestPatientId: null,
    page: 1,
    perPage: 15,
    totalPage: 1,
  },
});

export const guestPatientTabState = atom<GuestPatientTab>({
  key: 'reception.guestPatientTab',
  default: {
    type: 'profile',
    editableProfile: false,
  },
});

export const newReceptionDrawerState = atom<NewReceptionDrawerState>({
  key: 'reception.newReceptionDrawer',
  default: {
    inputValue: null,
    qrCodePatient: null,
    currentPanel: NewReceptionPanelMap.input,
    isOpen: false,
    page: 1,
    perPage: 10,
    totalPage: 1,
    searchWord: '',
    selectedCandidate: null,
    existingPhoneNumber: false,
  },
});

export const newReceptionTabState = atom<NewReceptionTab>({
  key: 'reception.newReception.tab',
  default: {
    type: 'new',
  },
});

export const guestPatientSearchPageInfoState = atom<GuestPatientSearchPageInfo>({
  key: 'reception.guestPatientSearchPageInfo',
  default: {
    page: 1,
    totalPage: 1,
    perPage: 10,
  },
});

export const guestPatientFileState = atom<GuestPatientFileState>({
  key: 'reception.guestPatientFileState',
  default: {
    guestPatientId: null,
  },
});

export const prescriptionQrCodeReaderDialogState = atom<PrescriptionQrCodeReaderDialog>({
  key: 'reception.prescriptionQrCodeReader.dialog',
  default: {
    isOpen: false,
  },
});

export const webVisitorProfileDrawerState = atom<WebVisitorProfileDrawerState>({
  key: 'reception.webVisitorProfileDrawer',
  default: {
    isOpen: false,
    webVisitorId: null,
    appointmentId: null,
  },
});

export const webVisitorTabState = atom<WebVisitorTab>({
  key: 'reception.WebVisitorTab',
  default: {
    type: 'reception',
  },
});

export const webVisitorFileState = atom<WebVisitorFileState>({
  key: 'reception.webVisitorFileState',
  default: {
    webVisitorId: null,
  },
});

export const webVisitorChargeModalState = atom<ChargeModalState>({
  key: 'reception.webVisitorChargeModal',
  default: {
    isOpen: false,
    appointmentId: null,
  },
});

export const webVisitorNotificationConfirmModalState = atom<NotificationConfirmModalState>({
  key: 'reception.webVisitorNotificationConfirmModal',
  default: {
    isOpen: false,
    appointmentId: null,
    webVisitorId: null,
  },
});

export const webVisitorNotificationCongestionModalState = atom<NotificationCongestionModalState>({
  key: 'reception.webVisitorNotificationCongestionModal',
  default: {
    isOpen: false,
    appointmentId: null,
    webVisitorId: null,
  },
});

export const checkinEntryProfileDrawerState = atom<CheckinEntryProfileDrawerState>({
  key: 'reception.checkinEntryProfileDrawer',
  default: {
    isOpen: false,
    checkinEntryId: null,
  },
});

export const outpatientQuestionnaireEntryProfileDrawerState =
  atom<OutpatientQuestionnaireEntryProfileDrawerState>({
    key: 'reception.outpatientQuestionnaireEntryProfileDrawer',
    default: {
      isOpen: false,
      outpatientQuestionnaireEntryId: null,
    },
  });

export const outpatientQuestionnaireNotifyConfirmModalState =
  atom<OutpatientQuestionnaireNotifyConfirmModalState>({
    key: 'reception.outpatientQuestionnaireEntryNotifyConfirmModal',
    default: {
      isOpen: false,
      outpatientQuestionnaireEntryId: null,
    },
  });

export const outpatientQuestionnaireFinishConfirmModalModalState =
  atom<OutpatientQuestionnaireEntryFinishConfirmModalModalState>({
    key: 'reception.outpatientQuestionnaireEntryFinishModal',
    default: {
      isOpen: false,
      outpatientQuestionnaireEntryId: null,
    },
  });

export const draftAppointmentPatientProfileDrawerState =
  atom<DraftAppointmentPatientProfileDrawerState>({
    key: 'reception.draftAppointmentPatientProfileDrawer',
    default: {
      isOpen: false,
      draftAppointmentId: null,
      patientId: null,
      error: null,
    },
  });

export const cancelDraftAppointmentPane = atom<CancelDraftAppointmentPaneState>({
  key: 'reception.cancelDraftAppointmentPane',
  default: {
    isOpen: false,
  },
});

export const requestAppointmentModalState = atom<RequestAppointmentModalState>({
  key: 'reception.requestAppointmentModalState',
  default: {
    isOpen: false,
  },
});

export const finishDraftAppointmentModalState = atom<FinishDraftAppointmentModalState>({
  key: 'reception.finishDraftAppointmentModalState',
  default: {
    isOpen: false,
  },
});

export const requestUberDeliveryModalState = atom<RequestUberDeliveryModalState>({
  key: 'reception.requestUberDelivery.modal',
  default: {
    isOpen: false,
    step: 'checkList',
    appointmentId: null,
    quoteId: null,
    error: null,
    graphqlError: null,
    autoMode: null,
    checked: false,
  },
});
