import { useTheme } from '@emotion/react';
import React from 'react';
import { SpaceProps } from 'styled-system';

import { Box, Text } from '~/components/blocks';
import { Interview } from '~/components/partials';
import { OutpatientQuestionnaireSheetAnswer } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  enteredAt: string;
  answers: Array<OutpatientQuestionnaireSheetAnswer>;
} & SpaceProps;

export const OutpatientQuestionnaireSheet = React.memo((props: Props) => {
  const theme = useTheme();
  const { enteredAt, answers } = props;
  const updatedAt = `最終更新日: ${Label.YYYYMMDDja(enteredAt)}`;

  return (
    <Box overflow="auto">
      <Text color="grey01" size="s" mb={theme.space.m}>
        {updatedAt}
      </Text>
      {answers.map((q, i) => (
        <Interview key={i}>
          <Interview.Title>
            <Interview.Icon>Q</Interview.Icon>
            {q.title}
          </Interview.Title>
          <Interview.Contents>
            {q.answers.map((v, l) => (
              <Text block whiteSpace="pre-wrap" key={l}>
                {v}
              </Text>
            ))}
          </Interview.Contents>
        </Interview>
      ))}
    </Box>
  );
});

OutpatientQuestionnaireSheet.displayName = 'OutpatientQuestionnaireSheet';
