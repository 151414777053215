import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Box, Loader, Text } from '~/components/blocks';
import { QuestionnaireSheetFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { Interview } from '../Interview';

type Props = {
  loading: boolean;
  sheet?: QuestionnaireSheetFragment;
};

export const QuestionnaireSheet = React.memo((props: Props) => {
  const { sheet } = props;
  const theme = useTheme();

  const updatedAt = sheet ? `最終更新日: ${Label.YYYYMMDDja(sheet.updatedAt)}` : null;

  return (
    <Box>
      {updatedAt && (
        <Text color="grey01" size="s" mb={theme.space.m}>
          {updatedAt}
        </Text>
      )}
      {props.loading ? (
        <Loader open inside appearance="white" />
      ) : sheet ? (
        <>
          {sheet.answers.map((q, i) => (
            <Interview key={i}>
              <Interview.Title>
                <Interview.Icon>Q</Interview.Icon>
                {q.title}
              </Interview.Title>
              <Interview.Contents>
                {q.value.map((v, l) => (
                  <Text block whiteSpace="pre-wrap" key={l}>
                    {v}
                  </Text>
                ))}
              </Interview.Contents>
            </Interview>
          ))}
        </>
      ) : (
        <Alert status="info" mt={theme.space.m}>
          問診票はありません
        </Alert>
      )}
    </Box>
  );
});

QuestionnaireSheet.displayName = 'QuestionnaireSheet';
