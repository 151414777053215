import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Button, Flex, Icon, Text } from '~/components/blocks';

import { ReceptionDrawerWidth } from './constants';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

export const ReceptionDrawerFileArea: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box
      width={ReceptionDrawerWidth.fileArea}
      backgroundColor={theme.colors.background.default}
      overflow="auto"
    >
      <Button
        onClick={props.onClose}
        size="s"
        marginTop={theme.space.xl}
        marginBottom={theme.space.m}
        marginLeft={theme.space.m}
        paddingX={theme.space.m}
      >
        <Icon icon="back-line" size="m" color="grey" />
        <Text size="xs" fontWeight="normal">
          閉じる
        </Text>
      </Button>
      <Flex flexDirection="column" padding={theme.space.m}>
        {props.children}
      </Flex>
    </Box>
  );
};
