import React from 'react';

import { EntryList, Flex, Text } from '~/components/blocks';
import { ReceptionPanePatientFragment } from '~/graphql';
import { toYYYYMMDDhyphen } from '~/utils/date';
import { Label } from '~/utils/label';
import { getPfEmail, getPfTel } from '~/utils/pf_contact_point';

type Props = {
  patient: ReceptionPanePatientFragment;
};

const getFamily = (pfPatient: ReceptionPanePatientFragment['pfPatient']) => {
  if (!pfPatient) {
    return [];
  }

  const parent = pfPatient.parent;
  const family = pfPatient.family;

  if (!parent) {
    return family;
  }

  return [parent, ...family].filter(({ id }) => id !== pfPatient.id);
};

export const PatientProfileOnReceptionPane = React.memo(({ patient }: Props) => {
  const bookmark = patient.bookmark;
  const pfPatient = patient?.pfPatient;
  const telecoms = pfPatient?.telecoms || [];
  const address = pfPatient?.addresses[0];

  const email = getPfEmail(telecoms);
  const tel = getPfTel(telecoms);
  const family = pfPatient ? getFamily(pfPatient) : [];

  return (
    <>
      <EntryList>
        <EntryList.Head>氏名</EntryList.Head>
        <EntryList.Body>
          {`${patient.familyName} ${patient.givenName}`}（
          {`${patient.phoneticFamilyName} ${patient.phoneticGivenName}`}）
        </EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>生年月日</EntryList.Head>
        <EntryList.Body>{Label.warekiBirthDate(patient.birthDate)}</EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>性別</EntryList.Head>
        <EntryList.Body>{Label.sex(patient?.sex)}</EntryList.Body>
      </EntryList>
      {patient.active && (
        <>
          <EntryList>
            <EntryList.Head>電話番号（携帯）</EntryList.Head>
            <EntryList.Body>{tel ? tel : '-'}</EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>メールアドレス</EntryList.Head>
            <EntryList.Body>{email ? email : '-'}</EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>住所</EntryList.Head>
            <EntryList.Body>
              {address ? (
                <Flex flexDirection="column">
                  <Text>〒{address.postalCode}</Text>
                  <Text>{`${address.prefecture}${address.city}${address.line}`}</Text>
                </Flex>
              ) : (
                '-'
              )}
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>お気に入り薬局登録日</EntryList.Head>
            <EntryList.Body>
              {bookmark ? toYYYYMMDDhyphen(bookmark?.updatedAt) : '-'}
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>家族アカウント</EntryList.Head>
            <EntryList.Body>
              {family.length > 0 ? (
                <>
                  {family.map((f, i) => (
                    <Text key={i}>{`${i === 0 ? '' : '・'}${f.familyName}${f.givenName}`}</Text>
                  ))}
                </>
              ) : (
                '-'
              )}
            </EntryList.Body>
          </EntryList>
        </>
      )}
    </>
  );
});

PatientProfileOnReceptionPane.displayName = 'PatientProfileOnReceptionPane';
