import React from 'react';
import { useRecoilCallback } from 'recoil';
import { MarginProps } from 'styled-system';

import { ActionButton } from '~/components/partials/ReceptionDrawer/ActionButton';
import { cancelDraftAppointmentPane } from '~/state/reception/atoms';

export const CancelButton = (props: MarginProps) => {
  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelDraftAppointmentPane, {
          isOpen: true,
        });
      },
    [],
  );

  return (
    <ActionButton use="bgGrey" size="s" onClick={handleClick} {...props}>
      キャンセル
    </ActionButton>
  );
};
