import React from 'react';

import { ExternalLink } from '~/components/blocks/ExternalLink';
import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import {
  AppointmentPfDispensingRequestUploadType,
  AppointmentStatus,
  DraftAppointmentOnReceptionPaneAppointmentFragment,
  ReceptionPaneEncounterFragment,
  ReceptionUberDeliveryFragment,
} from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';

import { UberCourierInfo } from './UberCourierInfo';

type Props = {
  status: AppointmentStatus;
  uberDelivery: ReceptionUberDeliveryFragment | null | undefined;
  pfDispensingRequestUploadType: AppointmentPfDispensingRequestUploadType | null | undefined;
  draftAppointment: DraftAppointmentOnReceptionPaneAppointmentFragment | null | undefined;
  encounter: ReceptionPaneEncounterFragment | null | undefined;
};

export const UberDeliveryInfo = React.memo((props: Props) => {
  const { status, uberDelivery, pfDispensingRequestUploadType, draftAppointment, encounter } =
    props;

  const { enable: enabledUber, loading } = useUberOrganizationSetting();

  if (loading) {
    return null;
  }

  const uploadFromMedicalInstitution =
    pfDispensingRequestUploadType === AppointmentPfDispensingRequestUploadType.MedicalInstitution ||
    !!draftAppointment;

  const uberHelpUrl =
    'https://intercom.help/pharms/ja/articles/9049950-%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E6%93%8D%E4%BD%9C%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6#:~:text=%E6%93%8D%E4%BD%9C%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-,%E2%97%BC%EF%B8%8F%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94%E5%AF%BE%E5%BF%9C%E6%99%82%E3%81%AE%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A0%85,-%E5%BD%93%E6%A9%9F%E8%83%BD%E3%81%AE';

  // イレギュラーパターン
  if (!enabledUber && !uberDelivery) {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          当日配達（Uber Eats）を利用できません
          <ActionInfo.Description>
            設定が無効になっているため、 当日配達（Uber
            Eats）を利用できません。患者にご相談の上、受け渡し方法を変更してください
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }
  // イレギュラーパターン
  if (uberDelivery && uberDelivery.status === 'uber_support_canceled') {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          配達がキャンセルされました
          <ActionInfo.Description>
            Uber
            Eatsにより配達がキャンセルされました。配達状況を確認の上、Pharmsサポートからご連絡します
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'pending') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">処方箋を確認し、受付してください</ActionInfo.Title>
        <ActionInfo.Title status="success">
          集荷依頼までに、当日配達（Uber Eats）資材を確認し、準備をお願いします
          <ActionInfo.Description>
            <ExternalLink url={uberHelpUrl}>資材のダウンロードおよび対応方法</ExternalLink>
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'booked') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          服薬指導を行なってください
          {!encounter?.notifiedAt && (
            <ActionInfo.Description>
              薬局が混み合っており時間に間に合わない場合は、混雑時通知を送ってください
            </ActionInfo.Description>
          )}
        </ActionInfo.Title>
        <ActionInfo.Title status="success">
          集荷依頼までに、当日配達（Uber Eats）資材を確認し、準備をお願いします
          <ActionInfo.Description>
            <ExternalLink url={uberHelpUrl}>資材のダウンロードおよび対応方法</ExternalLink>
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (
    status === 'waiting_for_charge' &&
    uberDelivery?.isTimeElapsedNotification &&
    uberDelivery.status === 'unfulfilled'
  ) {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          集荷依頼がキャンセルされました
          <ActionInfo.Description>
            配達員とマッチングできなかったため、集荷依頼がキャンセルされました。患者と相談の上、再度集荷依頼するか、受け渡し方法を変更してください
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (
    status === 'waiting_for_charge' &&
    uberDelivery?.isTimeElapsedNotification &&
    uberDelivery.status === 'pending'
  ) {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          配達員のマッチングに時間がかかっているため、患者に受け渡し方法の変更についてご相談ください
          <ActionInfo.Description>
            患者が当日配達（Uber
            Eats）からの変更を希望した場合は、必ず受け渡し方法の設定を変更してください
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge' && !uberDelivery) {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          お薬を梱包し、集荷依頼をしてください
          <ActionInfo.Description>
            下記のリンクからお薬の梱包方法を確認し、対応してください
          </ActionInfo.Description>
        </ActionInfo.Title>
        <ActionInfo.Title status="success">
          集荷依頼までに、当日配達（Uber Eats）資材を確認し、準備をお願いします
          <ActionInfo.Description>
            <ExternalLink url={uberHelpUrl}>資材のダウンロードおよび対応方法</ExternalLink>
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge' && uberDelivery?.status === 'before_dropoff_canceled') {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          集荷依頼がキャンセルされました
          <ActionInfo.Description>
            Uber&nbsp;Eatsから集荷依頼がキャンセルされました。患者と相談の上、再度集荷依頼するか、受け渡し方法を変更してください
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge' && uberDelivery?.status === 'pending') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          配達員とマッチングするまでお待ちください
          <ActionInfo.Description>
            Uber Eatsに集荷依頼しました。配達員とマッチング次第、通知にてお知らせします
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (
    (status === 'waiting_for_charge' || status === 'charged') &&
    uberDelivery?.status === 'pickup'
  ) {
    return <UberCourierInfo uberDelivery={uberDelivery} />;
  }

  if (status === 'waiting_for_charge' && uberDelivery?.status === 'dropoff') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          配達員がお薬の配達中です
          <ActionInfo.Description>配達が完了次第、通知にてお知らせします</ActionInfo.Description>
        </ActionInfo.Title>
        <ActionInfo.Title status="success">
          会計操作を行ってください
          {uploadFromMedicalInstitution && (
            <ActionInfo.Description>
              会計しないと、医療機関から処方箋原本が送られません
            </ActionInfo.Description>
          )}
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge' && uberDelivery?.status === 'delivered') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          会計操作を行ってください
          {uploadFromMedicalInstitution && (
            <ActionInfo.Description>
              会計しないと、医療機関から処方箋原本が送られません
            </ActionInfo.Description>
          )}
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge' && uberDelivery?.status === 'canceled') {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          配達がキャンセルされました
          <ActionInfo.Description>
            配達員からお薬を受け取ってください。患者とお薬のお渡し方法について相談したうえで、会計を完了してください
          </ActionInfo.Description>
        </ActionInfo.Title>
        <ActionInfo.Title status="error">
          会計操作を行ってください
          {uploadFromMedicalInstitution && (
            <ActionInfo.Description>
              会計しないと、医療機関から処方箋原本が送られません
            </ActionInfo.Description>
          )}
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'charged' && uberDelivery?.status === 'dropoff') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          配達員がお薬の配達中です
          <ActionInfo.Description>配達が完了次第、通知にてお知らせします</ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'charged' && uberDelivery?.status === 'canceled') {
    return (
      <ActionInfo status="error">
        <ActionInfo.Title status="error">
          配達がキャンセルされました
          <ActionInfo.Description>
            配達員からお薬を受け取ってください。患者とお薬のお渡し方法について相談のうえ、対応をお願いします
          </ActionInfo.Description>
        </ActionInfo.Title>
      </ActionInfo>
    );
  }

  return null;
});

UberDeliveryInfo.displayName = 'UberDeliveryInfo';
