import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Flex, Icon, Modal, Tag, Text } from '~/components/blocks';
import { SelectMonthWithMoveButton } from '~/components/pages/Reception/BookmarkPatientListModal/SelectMonthWithMoveButton';
import { bookmarkPatientListModalState } from '~/state/reception/atoms';

import { BookmarkPatientList } from './BookmarkPatientList';

const CustomTag = styled(Tag)(() =>
  css({
    alignItems: 'center',
    display: 'inline-flex',
    margin: '0px',
    textAlign: 'start',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
  }),
);

export const BookmarkPatientListModal = () => {
  const theme = useTheme();

  const { isOpen, error, yearMonth, patientCount } = useRecoilValue(bookmarkPatientListModalState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(bookmarkPatientListModalState),
    [],
  );

  const handleChange = useRecoilCallback(
    ({ set }) =>
      (date: Date | null) => {
        set(bookmarkPatientListModalState, (_state) => ({ ..._state, yearMonth: date }));
      },
    [],
  );

  return (
    <Modal size="l" open={isOpen} onClose={handleClose}>
      <Modal.Header>お気に入り薬局登録患者</Modal.Header>
      <Flex marginTop={theme.space.m}>
        <CustomTag>
          <Icon icon="favorite-fill" color="pink" size="l" />
          お気に入り薬局登録患者数&nbsp;
          <Text fontWeight="bold">{patientCount}名</Text>
        </CustomTag>
        <Flex marginLeft={theme.space.m}>
          <SelectMonthWithMoveButton
            value={yearMonth}
            onChange={handleChange}
            maxDate={new Date()}
          />
        </Flex>
      </Flex>
      <Text size="m" fontWeight="normal" marginTop={theme.space.m}>
        お気に入り登録した患者の申し込み数を確認できます。
        <br />
        申し込みをしていない患者は表示されません
      </Text>
      <Modal.Body>
        {error && (
          <Alert status="error" whiteSpace="pre-wrap" marginBottom={theme.space.l}>
            {error}
          </Alert>
        )}
        <BookmarkPatientList />
      </Modal.Body>
    </Modal>
  );
};
