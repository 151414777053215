import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Box, EntryList, Flex, Loader, Text } from '~/components/blocks';
import { AppointmentStatusLabel, MemoField } from '~/components/partials';
import { MedicationInstructionDateTime } from '~/components/partials/MedicationInstructionDateTime';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { AppointmentDeliveryMethod } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { translateReason } from '~/utils/appointments';
import { Label } from '~/utils/label';

import { LinkButton } from '../MedixsLinkButtons/LinkButton';
import { UnlinkButton } from '../MedixsLinkButtons/UnlinkButton';
import { MedixsPatientProfileOnReceptionPane } from '../MedixsPatientProfile';
import { PatientProfileOnReceptionPane } from '../PatientProfile';
import { AppointmentActionInfo } from './ActionInfo';
import { ReceiveOption } from './ReceiveOption';
import { useFetchAppointment } from './use-fetch-appointment';
import { useLinkMedixsPatient } from './use-link_medixs_patient';
import { useUnlinkMedixsPatient } from './use-unlink_medixs_patient';
import { useUpdateDescription } from './use-update-description';

type Props = {
  appointmentId: string;
};

const PatientBox = styled(Box)(({ theme }) =>
  css({
    borderTop: `1px solid ${theme.colors.border.default}`,
    paddingTop: theme.space.l,
  }),
);

export const Appointment = React.memo((props: Props) => {
  const theme = useTheme();

  const { loading, appointment } = useFetchAppointment(props.appointmentId);
  const { updating, latestMemo, handleChangeMemo } = useUpdateDescription(props.appointmentId);
  const { enabledMedixs } = useMedixsSetting();

  const patient = appointment?.patient;
  const medixsPatient = patient?.medixsPatient;

  const cancellationReason = appointment?.cancellationReason
    ? translateReason(
        appointment.cancellationReason,
        appointment.status,
        appointment.telemedicine ? undefined : !!appointment.waitingForChargeAt,
      )
    : null;

  const {
    handleUnlink,
    error: unlinkError,
    loading: unlinkLoading,
  } = useUnlinkMedixsPatient({
    patient,
    appointmentId: props.appointmentId,
  });

  const { openMedixsReceptionIdentificationModal } = useLinkMedixsPatient({
    patient,
    appointmentId: props.appointmentId,
  });

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      {loading && <Loader open inside transparent appearance="white" />}
      {appointment && (
        <>
          <Flex flexDirection="column">
            <AppointmentActionInfo
              status={appointment.status}
              telemedicine={appointment.telemedicine}
              uberDelivery={appointment.uberDelivery}
              isSameDayDelivery={
                appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
              }
              pfDispensingRequestUploadType={appointment.pfDispensingRequestUploadType}
              draftAppointment={appointment.draftAppointment}
              encounter={appointment.encounter}
            />
            <Flex justifyItems="start" marginY={theme.space.m} alignItems="center">
              <MedicationInstructionMethodLabel
                method={appointment.telemedicine ? 'telemedicine' : 'faceToFace'}
              />
              <Box marginLeft="auto">
                <AppointmentStatusLabel
                  status={appointment.status}
                  uberDelivery={appointment.uberDelivery}
                  isSameDayDelivery={
                    appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
                  }
                />
              </Box>
            </Flex>
            {appointment.start && appointment.end && (
              <MedicationInstructionDateTime start={appointment.start} end={appointment.end} />
            )}
          </Flex>
          <ReceiveOption appointment={appointment} />
          {cancellationReason && (
            <Alert status="warning" marginY={theme.space.m} withIcon={false}>
              <Flex flexDirection="column">
                <Text size="s" fontWeight={theme.fontWeights.bold}>
                  {cancellationReason.canceledBy}
                  からキャンセル
                </Text>
                {cancellationReason.canceledBy === '薬局' && (
                  <Text size="s">{cancellationReason.detail}</Text>
                )}
              </Flex>
            </Alert>
          )}
          <PatientBox>
            {enabledMedixs && (
              <Box>
                {unlinkError && (
                  <Alert status="error" marginBottom={theme.space.m}>
                    連携解除に失敗しました
                  </Alert>
                )}
                <Flex alignItems="center" marginBottom={theme.space.m}>
                  <Text fontWeight="bold">MEDIXS連携情報</Text>
                  <Flex marginLeft={theme.space.m}>
                    {enabledMedixs && !patient?.medixsPatient ? (
                      <LinkButton onClick={openMedixsReceptionIdentificationModal} />
                    ) : enabledMedixs && patient?.medixsPatient ? (
                      <UnlinkButton onClick={handleUnlink} disabled={unlinkLoading} />
                    ) : null}
                  </Flex>
                </Flex>
                {medixsPatient ? (
                  <MedixsPatientProfileOnReceptionPane
                    medixsPatient={medixsPatient}
                    medixsReception={appointment.medixsReception}
                  />
                ) : (
                  <Text>未連携</Text>
                )}
              </Box>
            )}
            {patient && (
              <>
                <Text
                  fontWeight="bold"
                  marginTop={enabledMedixs ? theme.space.xl : '0px'}
                  marginBottom={theme.space.m}
                >
                  患者情報
                </Text>
                <PatientProfileOnReceptionPane patient={patient} />
              </>
            )}
            <Text fontWeight="bold" marginTop={theme.space.xl} marginBottom={theme.space.m}>
              その他
            </Text>
            <EntryList>
              <EntryList.Head>申し込み日時</EntryList.Head>
              <EntryList.Body>
                <Box>
                  <Text size="m">
                    {`${Label.YYYYMMDDja(appointment.createdAt)}
        ${Label.HHMM(appointment.createdAt)}`}
                  </Text>
                </Box>
              </EntryList.Body>
            </EntryList>
            <EntryList mt={theme.space.l}>
              <EntryList.Head>メモ</EntryList.Head>
              <EntryList.Body>
                <MemoField
                  disabled={updating}
                  value={
                    latestMemo.mutationCalled ? latestMemo.memo : appointment?.description || ''
                  }
                  onChange={handleChangeMemo}
                />
              </EntryList.Body>
            </EntryList>
          </PatientBox>
        </>
      )}
    </Box>
  );
});

Appointment.displayName = 'AppointmentOnReceptionPane';
