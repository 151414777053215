import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Box, Grid, Tabs } from '~/components/blocks';
import { TabMap } from '~/constants/tab';
import { KarteAppointmentFragment } from '~/graphql';
import { tabState } from '~/state/partials/telemedicine_patient_profile/atoms';

import { FilePane } from './FilePane';
import { HistoryPane } from './HistoryPane';
import { ReceptionPane } from './ReceptionPane';

type Props = {
  appointment?: KarteAppointmentFragment | null;
};

export type TabType = (typeof TabMap)[keyof typeof TabMap];

export const Karte = React.memo((props: Props) => {
  const [tab, setTab] = useRecoilState(tabState);

  const patientId = props.appointment?.patient?.id || undefined;

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as TabType;
      setTab({ type: newTab });
    },
    [setTab],
  );

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab.type === TabMap.reception}
          data-tab={TabMap.reception}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === TabMap.history}
          data-tab={TabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === TabMap.file}
          data-tab={TabMap.file}
          onClick={handleChange}
          size="xs"
        >
          ファイル
        </Tabs.Tab>
      </Tabs>
      <Box overflow="auto">
        {tab.type === TabMap.reception && <ReceptionPane appointment={props.appointment} />}
        {tab.type === TabMap.history && <HistoryPane patientId={patientId} />}
        {tab.type === TabMap.file && <FilePane patientId={patientId} />}
      </Box>
    </Grid>
  );
});

Karte.displayName = 'Karte';
