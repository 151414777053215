import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert, Checkbox, Flex, Pagination, ScrollBox, Text } from '~/components/blocks';

import { InvitationHistoryList } from './InvitationHistoryList';
import { useInvitationHistory } from './use-invitation-history';

const InvitationHistoryPane = styled('div')(({ theme }) =>
  css({
    display: 'grid',
    gridTemplateRows: '1fr min-content',
    gridTemplateColumns: '1fr',
    gap: theme.space.m,
    width: '100%',
    height: '390px',
    overflow: 'auto',
    backgroundColor: theme.colors.background.bg,
    padding: theme.space.m,
    marginTop: theme.space.l,
  }),
);

const HeaderCheckbox = styled(Checkbox)(({ theme }) =>
  css({
    marginRight: theme.space.l,
    fontWeight: theme.fontWeights.default,
  }),
);

export const InvitationHistoryPanel = () => {
  const theme = useTheme();
  const {
    loading,
    state,
    invitationCodes,
    handleChangePage,
    handleNotAppliedFilter,
    handleSendFailedFilter,
  } = useInvitationHistory();

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text size="m">患者に送信した申込コードの履歴を確認できます</Text>
        <Flex alignItems="center">
          <HeaderCheckbox
            label="申し込みなし"
            checked={state.isNotApplied}
            onChange={handleNotAppliedFilter}
          />
          <HeaderCheckbox
            label="送信エラー"
            checked={state.isSendFailed}
            onChange={handleSendFailedFilter}
          />
        </Flex>
      </Flex>
      {state.error && (
        <Alert status="error" mb={theme.space.xl}>
          {state.error}
        </Alert>
      )}

      <InvitationHistoryPane>
        <ScrollBox loading={loading}>
          <InvitationHistoryList invitationHistories={invitationCodes} />
          {!loading && invitationCodes.length === 0 && (
            <Alert status="info" mt={theme.space.xl}>
              条件に一致する送信履歴はありませんでした
            </Alert>
          )}
        </ScrollBox>
        {state.totalPage > 1 && (
          <Pagination
            currentPage={state.page}
            totalPage={state.totalPage}
            onChange={handleChangePage}
          />
        )}
      </InvitationHistoryPane>
    </>
  );
};
