import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

type Props = {
  src: string;
  alt: string;
};

const Wrapper = styled('div')(({ theme }) =>
  css({
    width: '100%',
    marginY: theme.space.m,
    padding: theme.space.m,
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

const Image = styled('img')(() =>
  css({
    display: 'block',
    width: '75%',
    height: 'auto',
    margin: '0 auto',
  }),
);

export const PrescriptionImgViewer = React.memo((props: Props) => {
  return (
    <Wrapper>
      <Image alt={props.alt} src={props.src} />
    </Wrapper>
  );
});

PrescriptionImgViewer.displayName = 'PrescriptionImgViewer';
