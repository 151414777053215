import { useEffect, useMemo } from 'react';

import { useGetAppointmentOnTelemedicineInsuranceCardLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  appointmentId: string | null;
};

export const useFetchAppointment = ({ appointmentId }: Props) => {
  const [getAppointment, { data, loading }] =
    useGetAppointmentOnTelemedicineInsuranceCardLazyQuery();

  const appointment = useMemo(() => {
    return getNode(data, 'Appointment');
  }, [data]);

  useEffect(() => {
    if (appointmentId) {
      getAppointment({
        variables: {
          id: appointmentId,
        },
      });
    }
  }, [appointmentId, getAppointment]);

  return {
    qualificationConfirmationMethod: appointment?.qualificationConfirmationMethod,
    isActivePatient: !!appointment?.patient?.active,
    loading,
  };
};
