import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { MarginProps } from 'styled-system';

import { Flex, Skeleton } from '~/components/blocks';

type Props = MarginProps;

const SkeletonButton = styled(Skeleton)(({ theme }) =>
  css({
    width: '72px',
    height: '56px',
    marginBottom: theme.space.m,
    marginX: theme.space.m,
  }),
);

export const FileMenuSkeletonButton = (props: Props) => {
  return (
    <Flex alignItems="center" flexDirection="column" {...props}>
      <SkeletonButton />
    </Flex>
  );
};
