// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"8911ed282996e27c02a5bf97e1d685c08083801f"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/pharmacy/assets/production-20250121093555-8911ed282996e27c02a5bf97e1d685c08083801f";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.sentryDsn,
  enabled: process.env.NODE_ENV !== 'test',
  release: process.env.sentryRelease,
  ignoreErrors: [],
});
