import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex, Name } from '~/components/blocks';
import { ClinicsAppTag } from '~/components/partials/ClinicsAppTag';
import { CheckinEntryDetailFragment } from '~/graphql';

import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  loading: boolean;
  checkinEntry?: CheckinEntryDetailFragment | null;
};

export const CheckinPatientProfile = React.memo((props: Props) => {
  const { loading, checkinEntry } = props;
  const theme = useTheme();

  return (
    <Box>
      {loading || !checkinEntry ? (
        <ProfileSkeleton />
      ) : (
        <Flex flexDirection="column">
          <Name
            size="l"
            familyName={checkinEntry.familyName}
            givenName={checkinEntry.givenName}
            phoneticFamilyName={checkinEntry.phoneticFamilyName}
            phoneticGivenName={checkinEntry.phoneticGivenName}
          />
          <Flex marginY={theme.space.l}>
            <ClinicsAppTag isAppPatient={false} />
          </Flex>
        </Flex>
      )}
    </Box>
  );
});

CheckinPatientProfile.displayName = 'CheckinPatientProfile';
