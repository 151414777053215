import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DateLabel, Flex, Name, Table, Text } from '~/components/blocks';
import { CheckinEntryStatusLabel } from '~/components/partials';
import { DeliveryMethodField } from '~/components/partials/DeliveryMethodField';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { ReceptionMemos } from '~/components/partials/ReceptionMemos';
import { ReceptionCheckinEntryFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  newReceptionDrawerState,
  outpatientQuestionnaireEntryProfileDrawerState,
  receptionPageInfoState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { ReceptionIcon } from './ReceptionIcon';
import { TableRow } from './TableRow';

type Props = {
  activeMedixs: boolean;
  updated: boolean;
  checkinEntry: ReceptionCheckinEntryFragment;
  onAnimationEnd: (visitId: string) => void;
};

export const CheckinEntry = React.memo((props: Props) => {
  const { checkinEntry, onAnimationEnd } = props;
  const { date } = useRecoilValue(receptionPageInfoState);
  const { enable: enabledUber } = useUberOrganizationSetting();

  const enteredAt = checkinEntry.enteredAt;
  const enteredEndAt = new Date(+new Date(enteredAt) + 30 * 60 * 1000);

  const handleAnimationEnd = useCallback(
    () => onAnimationEnd(checkinEntry.id),
    [onAnimationEnd, checkinEntry.id],
  );
  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(checkinEntryProfileDrawerState, (_state) => ({
          ..._state,
          isOpen: true,
          checkinEntryId: checkinEntry.id,
        }));

        reset(draftAppointmentPatientProfileDrawerState);
        reset(profileDrawerState);
        reset(newReceptionDrawerState);
        reset(webVisitorProfileDrawerState);
        reset(outpatientQuestionnaireEntryProfileDrawerState);
      },
    [checkinEntry.id],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick} onAnimationEnd={handleAnimationEnd}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          <DateLabel>
            {!date && <DateLabel.Time>{Label.YYYYMMDDja(enteredAt)}</DateLabel.Time>}
            <DateLabel.Day>
              {Label.HHMM(enteredAt)}-{Label.HHMM(enteredEndAt)}
            </DateLabel.Day>
          </DateLabel>
        </Flex>
      </Table.Td>
      <Table.Td>
        <CheckinEntryStatusLabel status={checkinEntry.checkinStatus} />
      </Table.Td>
      {props.activeMedixs && <Table.Td nowrap />}
      <Table.Td nowrap>
        <Flex alignItems="center">
          <ReceptionIcon receptionType="checkinEntry" />
          <Name
            familyName={checkinEntry.familyName}
            givenName={checkinEntry.givenName}
            phoneticFamilyName={checkinEntry.phoneticFamilyName}
            phoneticGivenName={checkinEntry.phoneticGivenName}
          />
        </Flex>
      </Table.Td>
      <Table.Td>-</Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <MedicationInstructionMethodLabel method="faceToFace" use="short" size="s" />
        </Flex>
      </Table.Td>
      <Table.Td>
        <DeliveryMethodField deliveryMethod="hand" size="m" />
      </Table.Td>
      {enabledUber && <Table.Td>-</Table.Td>}
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.YYYYMMDDja(enteredAt)}&nbsp;{Label.HHMM(enteredAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        <ReceptionMemos memo={checkinEntry.description} />
      </Table.Td>
    </TableRow>
  );
});

CheckinEntry.displayName = 'CheckinEntry';
