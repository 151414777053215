import React from 'react';

import { EntryList } from '~/components/blocks';
import { ReceptionPaneMedixsReceptionFragment, ReceptionPanePatientFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  medixsPatient: Exclude<ReceptionPanePatientFragment['medixsPatient'], null | undefined>;
  medixsReception: ReceptionPaneMedixsReceptionFragment | undefined | null;
};

export const MedixsPatientProfileOnReceptionPane = React.memo(
  ({ medixsPatient, medixsReception }: Props) => {
    return (
      <>
        <EntryList>
          <EntryList.Head>受付ID</EntryList.Head>
          <EntryList.Body>
            {medixsReception ? medixsReception.receptionId : '未連携'}
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>レセコン患者コード</EntryList.Head>
          <EntryList.Body>{medixsPatient.patientCode}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>患者名</EntryList.Head>
          <EntryList.Body>{`${medixsPatient.patientName}（${medixsPatient.patientNameKana}）`}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>{Label.YYYYMMDDja(medixsPatient.birthDate)}</EntryList.Body>
        </EntryList>
      </>
    );
  },
);

MedixsPatientProfileOnReceptionPane.displayName = 'MedixsPatientProfileOnReceptionPane';
