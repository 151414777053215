import { useTheme } from '@emotion/react';
import React from 'react';

import { Button, Icon, Text } from '~/components/blocks';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
};

export const LinkButton = (props: Props) => {
  const theme = useTheme();

  return (
    <Button
      size="ms"
      padding={`1px ${theme.space.m}`}
      marginRight={theme.space.s}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Icon size="s" icon="sync" color="blue" />
      <Text size="xs">連携</Text>
    </Button>
  );
};
