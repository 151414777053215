import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Box, Grid, Tooltip } from '~/components/blocks';
import { ReceptionDrawer } from '~/components/layouts/ReceptionDrawer';
import { ReceptionDrawerFileMenu } from '~/components/layouts/ReceptionDrawer/FileMenu';
import { PatientKarte, PatientProfile } from '~/components/partials';
import { FileMenuButton } from '~/components/partials/ReceptionDrawer/FileMenuButton';
import { FileMenuSkeletonButton } from '~/components/partials/ReceptionDrawer/FileMenuSkeletonButton';
import { ReceptionMainBox } from '~/components/partials/ReceptionDrawer/ReceptionMainBox';
import { ReceptionFileType } from '~/constants/reception_file_type';
import { draftAppointmentPatientFileAreaState } from '~/state/reception_drawer/atoms';
import { ReceptionDrawerFileType } from '~/state/reception_drawer/types';

import { Footer } from '../Footer';
import { FileArea } from './FileArea';
import { useFetchInsuranceCardStatus } from './use-fetch-insurance_card_status';
import { useFetchPrescriptionStatus } from './use-fetch-prescription_status';
import { useFetchQuestionnaireStatus } from './use-fetch-questionnaire_status';
import { useMarkNotification } from './use-mark-notification';

type Props = {
  open: boolean;
  draftAppointmentId: string | null;
  patientId: string | null;
  onClose: () => void;
  onTransitionEnd: () => void;
};

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const ProfileDrawer = (props: Props) => {
  const { draftAppointmentId, patientId, onClose, onTransitionEnd } = props;
  const theme = useTheme();
  const [isFileAreaOpened, setOpenFileArea] = useState(false);
  const setFileAreaState = useSetRecoilState(draftAppointmentPatientFileAreaState);

  const handleOpenFileArea = useCallback(
    (type: ReceptionDrawerFileType) => {
      if (draftAppointmentId) {
        setOpenFileArea(true);
        setFileAreaState((state) => ({
          ...state,
          type,
          draftAppointmentId,
          patientId: patientId,
        }));
      }
    },
    [draftAppointmentId, patientId, setFileAreaState],
  );
  const handleCloseFileArea = useCallback(() => {
    setOpenFileArea(false);
  }, []);

  const { status: prescriptionStatus, loading: prescriptionLoading } = useFetchPrescriptionStatus({
    draftAppointmentId,
  });
  const { status: questionnaireStatus, loading: questionnaireLoading } =
    useFetchQuestionnaireStatus({ patientId });
  const {
    status: insuranceCardStatus,
    loading: insuranceCardLoading,
    timeout: insuranceCardTimeout,
  } = useFetchInsuranceCardStatus({
    draftAppointmentId,
    patientId,
  });

  useMarkNotification(draftAppointmentId);
  useEffect(() => {
    setOpenFileArea(false);
  }, [draftAppointmentId, props.open]);

  return (
    <ReceptionDrawer
      isOpen={props.open}
      onClose={onClose}
      onTransitionEnd={onTransitionEnd}
      isExpended={isFileAreaOpened}
    >
      <ReceptionDrawerFileMenu>
        {prescriptionLoading ? (
          <FileMenuSkeletonButton marginTop={theme.space.xl} />
        ) : (
          <FileMenuButton
            fileName="処方箋"
            active={prescriptionStatus.active}
            statusLabel={prescriptionStatus.label}
            onClick={() => handleOpenFileArea(ReceptionFileType.prescription)}
            marginTop={theme.space.xl}
          />
        )}
        {insuranceCardLoading ? (
          <FileMenuSkeletonButton />
        ) : insuranceCardTimeout ? (
          <Tooltip
            content={
              <>
                データ取得に時間がかかっています
                <br />
                しばらく経ってから再度お試しください
              </>
            }
            placement="right"
          >
            <div>
              <FileMenuButton fileName="保険証" active={false} statusLabel="取得不可" use="error" />
            </div>
          </Tooltip>
        ) : (
          <FileMenuButton
            fileName="保険証"
            active={insuranceCardStatus.active}
            statusLabel={insuranceCardStatus.label}
            onClick={() => handleOpenFileArea(ReceptionFileType.insuranceCard)}
          />
        )}
        {questionnaireLoading ? (
          <FileMenuSkeletonButton />
        ) : (
          <FileMenuButton
            fileName="問診票"
            active={questionnaireStatus.active}
            statusLabel={questionnaireStatus.label}
            onClick={() => handleOpenFileArea(ReceptionFileType.questionnaire)}
          />
        )}
        <FileMenuButton fileName="お薬手帳" active={false} statusLabel="データなし" />
      </ReceptionDrawerFileMenu>
      {isFileAreaOpened && <FileArea onClose={handleCloseFileArea} />}
      {draftAppointmentId && patientId && (
        <ReceptionMainBox>
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <PatientProfile appointmentId={null} patientId={patientId} />
            </ProfileBox>
            <Box overflow="auto">
              <PatientKarte draftAppointmentId={draftAppointmentId} patientId={patientId} />
            </Box>
            <Footer draftAppointmentId={draftAppointmentId} />
          </Grid>
        </ReceptionMainBox>
      )}
    </ReceptionDrawer>
  );
};
