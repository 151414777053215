import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Grid } from '~/components/blocks';
import { ReceptionDrawerWidth } from '~/components/layouts/ReceptionDrawer/constants';
import { BasePatientProfileFragment, KarteAppointmentFragment } from '~/graphql';

import { Karte } from './Karte';
import { Profile } from './Profile';

type Props = {
  patient?: BasePatientProfileFragment | null;
  appointment?: KarteAppointmentFragment | null;
};

const Root = styled(Grid)(({ theme }) =>
  css({
    overflow: 'auto',
    gridTemplateRows: 'min-content 1fr',
    background: theme.colors.background.default,
    height: '100%',
    width: ReceptionDrawerWidth.main,
    boxShadow: `0 10px 10px ${theme.colors.border.default}`,
    [`@media ${theme.mediaQueries.portrait}`]: {
      boxShadow: `-10px 0 10px -10px ${theme.colors.border.default}`,
    },
  }),
);

export const TelemedicinePatientProfile = React.memo((props: Props) => {
  return (
    <Root>
      <Profile patient={props.patient} />
      <Karte appointment={props.appointment} />
    </Root>
  );
});

TelemedicinePatientProfile.displayName = 'PatientProfile';
