import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex, Name, Text } from '~/components/blocks';
import { ClinicsAppTag } from '~/components/partials/ClinicsAppTag';
import {
  DirectVisitorOfOutpatientQuestionnaireEntryDetailFragment,
  WebVisitorDetailFragment,
} from '~/graphql';
import { Label } from '~/utils/label';

import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  loading: boolean;
  visitor?:
    | WebVisitorDetailFragment
    | DirectVisitorOfOutpatientQuestionnaireEntryDetailFragment
    | null;
};

export const VisitorProfile = React.memo((props: Props) => {
  const { loading, visitor } = props;
  const theme = useTheme();

  return (
    <Box>
      {loading || !visitor ? (
        <ProfileSkeleton />
      ) : (
        <Flex flexDirection="column">
          <Flex flexDirection="row" alignItems="flex-end" paddingRight={theme.space.m}>
            <Text wordBreak="break-all">
              <Name
                size="l"
                familyName={visitor.familyName}
                givenName={visitor.givenName}
                phoneticFamilyName={visitor.phoneticFamilyName}
                phoneticGivenName={visitor.phoneticGivenName}
              />
            </Text>
            <Text lineHeight={theme.lineHeights.s} marginLeft={theme.space.m}>
              {Label.age(visitor.birthDate)}（{Label.sex(visitor.sex)}）
            </Text>
          </Flex>
          <Flex marginY={theme.space.l}>
            <ClinicsAppTag isAppPatient={false} />
          </Flex>
        </Flex>
      )}
    </Box>
  );
});

VisitorProfile.displayName = 'VisitorProfile';
