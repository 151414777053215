import {
  DELIVERY_REGEX,
  MEDICATION_FOLLOWUP_TEMPLATE_MEMO_REGEX,
  SUMMARIZED_MEMO,
  TEMPLATE_MEMOS,
} from '~/constants/memo';

export const isDeliveryTag = (text: string) => {
  return DELIVERY_REGEX.test(text);
};

export const isMedicationFollowupTag = (text: string) => {
  return MEDICATION_FOLLOWUP_TEMPLATE_MEMO_REGEX.test(text);
};

const isFreeText = (text: string) => {
  return !TEMPLATE_MEMOS.includes(text) && !isDeliveryTag(text) && !isMedicationFollowupTag(text);
};

export const getReceptionMemos = (memo: string) => {
  const arrayMemo = memo.split(',').filter(Boolean);
  const freeDescriptionFirstIndex = arrayMemo.findIndex((m) => isFreeText(m));

  const displayedMemo = arrayMemo.filter((memo) => TEMPLATE_MEMOS.includes(memo));

  if (freeDescriptionFirstIndex !== -1) {
    displayedMemo.splice(freeDescriptionFirstIndex, 0, SUMMARIZED_MEMO);
  }

  return displayedMemo;
};

export const getDrawerMemos = (memo: string) => {
  const arrayMemo = memo.split(',').filter(Boolean);
  const displayedMemo = arrayMemo.filter(
    (memo) => TEMPLATE_MEMOS.includes(memo) || isFreeText(memo),
  );

  return displayedMemo;
};
