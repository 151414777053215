import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect } from 'react';
import { useRecoilCallback, useResetRecoilState, useSetRecoilState } from 'recoil';

import { Flex } from '~/components/blocks';
import { CancelPane } from '~/components/partials';
import { ActionButton } from '~/components/partials/ReceptionDrawer/ActionButton';
import { InactiveButton } from '~/components/partials/ReceptionDrawer/InactiveButton';
import { WebVisitorAppointmentDetailFragment } from '~/graphql';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';
import {
  webVisitorChargeModalState,
  webVisitorNotificationConfirmModalState,
  webVisitorNotificationCongestionModalState,
} from '~/state/reception/atoms';

import { useCancelAppointment } from './use-cancel-appointment';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

export const BookedButtons = (props: Props) => {
  const theme = useTheme();
  const isSentCongestionNotification = !!props.appointment.congestionNotificationSentAt;

  const { canceling, handleCancel } = useCancelAppointment(props.appointment.id);

  const setChargeModalState = useSetRecoilState(webVisitorChargeModalState);
  const setNotificationConfirmState = useSetRecoilState(webVisitorNotificationConfirmModalState);
  const resetCancelPane = useResetRecoilState(cancelPane);

  // 準備できたよ通知
  // モーダルで準備完了通知送信を確定したあと、指導待ち -> 未会計 （telemedicine: false）
  const handleSendNotification = useCallback(() => {
    setNotificationConfirmState({
      isOpen: true,
      appointmentId: props.appointment.id,
      webVisitorId: props.appointment.webVisitor?.id as string,
    });
  }, [props.appointment.id, props.appointment.webVisitor?.id, setNotificationConfirmState]);

  const handleCongestionClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(webVisitorNotificationCongestionModalState, {
          isOpen: true,
          appointmentId: props.appointment.id,
          webVisitorId: props.appointment.webVisitor?.id as string,
        });
      },
    [props.appointment.id, props.appointment.webVisitor?.id],
  );

  // 会計モーダルで確定後
  // - 未会計 -> 会計済(配送待ち) （telemedicine: true）
  // - 指導待ち -> 未会計 -> 完了 （telemedicine: false）
  const openChargeModal = useCallback(() => {
    setChargeModalState({
      isOpen: true,
      appointmentId: props.appointment.id,
    });
  }, [setChargeModalState, props.appointment.id]);

  // キャンセル
  const handleClickCancel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelPane, { isOpen: true });
      },
    [],
  );

  useEffect(() => {
    resetCancelPane();
  }, [props.appointment.id, resetCancelPane]);

  return (
    <>
      <Flex flexDirection="column">
        <Flex alignItems="center">
          {isSentCongestionNotification ? (
            <InactiveButton status="送信済" wide="half" size="m">
              混雑時通知を送信
            </InactiveButton>
          ) : (
            <ActionButton use="primaryInverse" wide="half" size="m" onClick={handleCongestionClick}>
              混雑時通知を送信
            </ActionButton>
          )}
          <ActionButton
            use="primaryInverse"
            wide="half"
            size="m"
            marginLeft={theme.space.m}
            onClick={handleSendNotification}
          >
            準備完了通知を送信
          </ActionButton>
        </Flex>
        <ActionButton
          marginTop={theme.space.m}
          use="primary"
          wide="fill"
          size="l"
          onClick={openChargeModal}
        >
          会計
        </ActionButton>
        <ActionButton use="bgGrey" onClick={handleClickCancel} marginTop={theme.space.m} size="s">
          キャンセル
        </ActionButton>
      </Flex>
      <CancelPane align="center" canceling={canceling} onCancel={handleCancel} />
    </>
  );
};
