import React from 'react';

import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import {
  DraftAppointmentStatus,
  PfDispensingRequest,
  PfDispensingRequestMedicalInstitutionMedicalInstitution,
} from '~/graphql';

import { DraftAppointmentSameDayDeliveryInfo } from './SameDayDeliveryInfo';

type Props = {
  status: DraftAppointmentStatus;
  isSameDayDelivery: boolean;
  medicalInstitutionName:
    | PfDispensingRequestMedicalInstitutionMedicalInstitution['name']
    | undefined;
  prescriptionSendMethod: PfDispensingRequest['sendMethod'] | undefined;
};

export const DraftAppointmentActionInfo = React.memo((props: Props) => {
  const { status, isSameDayDelivery, medicalInstitutionName, prescriptionSendMethod } = props;

  if (status === 'available') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          {medicalInstitutionName ? `${medicalInstitutionName}から` : ''}
          処方箋が共有されるまでお待ちください
          <ActionInfo.Description>
            処方箋は、データまたはFAX等にて共有されます
          </ActionInfo.Description>
        </ActionInfo.Title>
        {isSameDayDelivery && <DraftAppointmentSameDayDeliveryInfo />}
      </ActionInfo>
    );
  }

  if (status === 'wait_for_booking') {
    if (prescriptionSendMethod === 'UPLOAD') {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            処方箋のデータが共有されました。患者からの服薬指導日時の指定をお待ちください
          </ActionInfo.Title>
          {isSameDayDelivery && <DraftAppointmentSameDayDeliveryInfo />}
        </ActionInfo>
      );
    } else {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            患者からの服薬指導日時の指定をお待ちください
          </ActionInfo.Title>
          {isSameDayDelivery && <DraftAppointmentSameDayDeliveryInfo />}
        </ActionInfo>
      );
    }
  }

  return null;
});

DraftAppointmentActionInfo.displayName = 'DraftAppointmentActionInfo';
