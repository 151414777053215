import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { EntryList, TextField } from '~/components/blocks';
import { numberOrHyphenRegexp } from '~/constants/regexp';
import { invitationModalState } from '~/state/reception/atoms';
import { toHalfNumberWithHyphen } from '~/utils/convert';

import { Fields } from './types';
import { useDefaultValues } from './use-default_values';
import { validationSchema } from './validation';

type Props = {
  disabled?: boolean;
  onSubmit: (phoneNumber: string, memo?: string) => void;
};

const InputTextField = styled(TextField)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.default,
  }),
);

export const InputForm = React.forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { onSubmit } = props;
  const defaultValues = useDefaultValues();
  const setState = useSetRecoilState(invitationModalState);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleSubmit = useCallback(
    (values: Fields) => {
      onSubmit(values.phoneNumber, values.memo);
    },
    [onSubmit],
  );

  const handlePhoneNumberChange = useCallback(
    (formik: FormikProps<Fields>, e: React.FocusEvent<HTMLInputElement>) => {
      if (!numberOrHyphenRegexp.test(e.currentTarget.value)) return;
      const phoneNumber = toHalfNumberWithHyphen(e.currentTarget.value || '');
      formik.setFieldValue('phoneNumber', phoneNumber);
      setState((_state) => ({ ..._state, phoneNumber }));
    },
    [setState],
  );

  const handleMemoChange = useCallback(
    (formik: FormikProps<Fields>, e: React.FocusEvent<HTMLInputElement>) => {
      const memo = e.currentTarget.value;
      formik.setFieldValue('memo', memo);
      setState((_state) => ({ ..._state, memo }));
    },
    [setState],
  );

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  return (
    <Formik
      innerRef={ref}
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <EntryList>
            <EntryList.Head>送信先携帯電話番号</EntryList.Head>
            <EntryList.Body>
              <InputTextField
                ref={inputRef}
                disabled={props.disabled}
                error={formik.errors.phoneNumber}
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={(e) => handlePhoneNumberChange(formik, e)}
                placeholder="08012345678"
              ></InputTextField>
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>メモ（患者には表示されません）</EntryList.Head>
            <EntryList.Body>
              <InputTextField
                disabled={props.disabled}
                error={formik.errors.memo}
                name="memo"
                value={formik.values.memo}
                onChange={formik.handleChange}
                onBlur={(e) => handleMemoChange(formik, e)}
                placeholder="患者名など"
              ></InputTextField>
            </EntryList.Body>
          </EntryList>
        </>
      )}
    </Formik>
  );
});

InputForm.displayName = 'InputForm';
