import { useEffect, useState } from 'react';

import { useGetPrescriptionFromNodeLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchAppointment = (appointmentId: string | null) => {
  const [error, setError] = useState<Error | null>(null);

  const [getAppointment, { called, loading, data }] = useGetPrescriptionFromNodeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (_error) => setError(_error),
  });

  const appointment = getNode(data, 'Appointment');

  useEffect(() => {
    if (appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, getAppointment]);

  return { loading: !called || loading, error, appointment };
};
