import { Fields } from '~/components/pages/Reception/GuestPatientForm/types';
import { GuestPatientTabMap, NewReceptionTabMap, WebVisitorTabMap } from '~/constants/tab';
import { CandidateFragment, OrganizationAppointmentStatus } from '~/graphql';

export type B2CloudDownloadModalState = {
  isOpen: boolean;
  disabled: boolean;
  error: string | null;
  appointmentIds: string[];
};

export type BookmarkPatientListModalState = {
  isOpen: boolean;
  disabled: boolean;
  error: string | null;
  page: number;
  totalPage: number;
  perPage: number;
  yearMonth: Date | null;
  patientCount: number;
};

export type CheckinFinishConfirmModalModalState = {
  isOpen: boolean;
  checkinEntryId: string | null;
};

export type CheckinNotifyConfirmModalState = {
  isOpen: boolean;
  checkinEntryId: string | null;
};

export type OutpatientQuestionnaireNotifyConfirmModalState = {
  isOpen: boolean;
  outpatientQuestionnaireEntryId: string | null;
};

export type OutpatientQuestionnaireEntryFinishConfirmModalModalState = {
  isOpen: boolean;
  outpatientQuestionnaireEntryId: string | null;
};

export type ReceptionPageInfo = {
  page: number;
  totalPage: number;
  perPage: number;
  orderBy: { field: 'created_at' | 'start'; direction: 'asc' | 'desc' };
  date: Date | null;
  statuses: OrganizationAppointmentStatus[];
  patientName: string | null;
};

export type InvitationModal = {
  isOpen: boolean;
  invitationCode: string;
  invitationCodeId: string;
  phoneNumber: string;
  memo: string;
};

export type InvitationHistoryPageInfoState = {
  isSendFailed: boolean;
  isNotApplied: boolean;
  page: number;
  totalPage: number;
  perPage: number;
  error: string | null;
};

export const NewReceptionPanelMap = {
  input: 'input',
  inputFamily: 'inputFamily',
  confirm: 'confirm',
  select: 'select',
  checkin: 'checkin',
} as const;

export type QrCodePatient = {
  familyName: string;
  givenName: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
  birthDate: string;
  sex: string;
};

export type NewReceptionDrawerState = {
  currentPanel: (typeof NewReceptionPanelMap)[keyof typeof NewReceptionPanelMap];
  inputValue: Fields | null;
  qrCodePatient: QrCodePatient | null;
  isOpen: boolean;
  page: number;
  perPage: number;
  totalPage: number;
  searchWord: string;
  selectedCandidate: CandidateFragment | null;
  existingPhoneNumber: boolean;
};

export type TabType = (typeof GuestPatientTabMap)[keyof typeof GuestPatientTabMap];

export type GuestPatientTab = {
  type: TabType;
  editableProfile: boolean;
};

export type NewReceptionTabType = (typeof NewReceptionTabMap)[keyof typeof NewReceptionTabMap];

export type NewReceptionTab = {
  type: NewReceptionTabType;
};

export type GuestPatientSearchPageInfo = {
  page: number;
  totalPage: number;
  perPage: number;
};

export type GuestPatientHistoryPageInfo = {
  guestPatientId: string | null;
  page: number;
  totalPage: number;
  perPage: number;
};

export type GuestPatientFileState = {
  guestPatientId: string | null;
};

export type PrescriptionQrCodeReaderDialog = {
  isOpen: boolean;
};

export type WebVisitorProfileDrawerState = {
  isOpen: boolean;
  webVisitorId: string | null;
  appointmentId: string | null;
};

export type WebVisitorTabType = (typeof WebVisitorTabMap)[keyof typeof WebVisitorTabMap];
export type WebVisitorTab = {
  type: WebVisitorTabType;
};

export type WebVisitorFileState = {
  webVisitorId: string | null;
};

export type ChargeModalState = {
  isOpen: boolean;
  appointmentId: string | null;
};

export type NotificationConfirmModalState = {
  isOpen: boolean;
  appointmentId: string | null;
  webVisitorId: string | null;
};

export type NotificationCongestionModalState = {
  isOpen: boolean;
  appointmentId: string | null;
  webVisitorId: string | null;
};

export type FinishConfirmModalState = {
  isOpen: boolean;
  encounterId: string | null;
  webVisitorId: string | null;
  error: string | null;
};

export type CheckinEntryProfileDrawerState = {
  isOpen: boolean;
  checkinEntryId: string | null;
};

export type OutpatientQuestionnaireEntryProfileDrawerState = {
  isOpen: boolean;
  outpatientQuestionnaireEntryId: string | null;
};

export type DraftAppointmentPatientProfileDrawerState = {
  isOpen: boolean;
  draftAppointmentId: string | null;
  patientId: string | null;
  error: string | null;
};

export type CancelDraftAppointmentPaneState = {
  isOpen: boolean;
};

export type RequestAppointmentModalState = {
  isOpen: boolean;
};

export type FinishDraftAppointmentModalState = {
  isOpen: boolean;
};

export type RequestUberDeliveryModalState = {
  isOpen: boolean;
  appointmentId: string | null;
  step: 'checkList' | 'selectQuantity' | 'inputPieces' | 'confirm';
  quoteId: string | null;
  error: string | null;
  graphqlError: string | null;
  autoMode: 'delivered' | 'canceled' | 'unfulfilled' | null;
  checked: boolean;
};
