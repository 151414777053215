import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Box, Chip, Flex, Icon, IconName, Text } from '~/components/blocks';

type DetailedStatusType = {
  label: string;
  iconName: IconName;
  color: ComponentProps<typeof Icon>['color'];
};

type Props = {
  label: string;
  labelColor: ComponentProps<typeof Chip>['textColor'];
  chipColor: ComponentProps<typeof Chip>['color'];
  detailedStatus?: DetailedStatusType;
};

const DetailedStatus = styled(Box)(() =>
  css({
    position: 'relative',
    marginTop: '1px',
  }),
);

export const ReceptionStatus = (props: Props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });
  const detailedStatus = props.detailedStatus;

  if (!detailedStatus) {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Chip size="fixed" color={props.chipColor} textColor={props.labelColor}>
          {props.label}
        </Chip>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" alignContent="center" alignItems="center">
      <Box>
        <Chip size="fixed" color={props.chipColor} textColor={props.labelColor}>
          {props.label}
        </Chip>
      </Box>
      <DetailedStatus>
        <Flex alignItems="center">
          <Icon
            icon={detailedStatus.iconName}
            size={isTablet ? 's' : 'm'}
            color={detailedStatus.color}
          />
          <Text color={detailedStatus.color} size="xs">
            {detailedStatus.label}
          </Text>
        </Flex>
      </DetailedStatus>
    </Flex>
  );
};
