import React from 'react';
import { useRecoilValue } from 'recoil';

import { ReceptionDrawerFileArea } from '~/components/layouts/ReceptionDrawer/FileArea';
import { ReceptionFileType } from '~/constants/reception_file_type';
import { telemedicinePatientFileAreaState } from '~/state/reception_drawer/atoms';

import { InsuranceCard } from './InsuranceCard';
import { Prescription } from './Prescription';
import { Questionnaire } from './Questionnaire';

type Props = {
  onClose: () => void;
};

export const FileArea = (props: Props) => {
  const { type } = useRecoilValue(telemedicinePatientFileAreaState);

  return (
    <ReceptionDrawerFileArea onClose={props.onClose}>
      {type === ReceptionFileType.prescription && <Prescription />}
      {type === ReceptionFileType.insuranceCard && <InsuranceCard />}
      {type === ReceptionFileType.questionnaire && <Questionnaire />}
    </ReceptionDrawerFileArea>
  );
};
