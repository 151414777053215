import { useTheme } from '@emotion/react';
import { FormikProps } from 'formik';
import React from 'react';
import { useSetRecoilState } from 'recoil';

import { Alert, Box, Button, Icon, Modal, Text } from '~/components/blocks';
import { isProduction } from '~/constants/utils';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';

import { PiecesContent } from '../PiecesContent';
import { Fields } from '../type';
import { AutoRadioInput } from './AutoRadioInput';
import { ContentItem } from './ContentItem';

type Props = {
  formik: FormikProps<Fields>;
  loading: boolean;
};

export const ConfirmContent = React.memo((props: Props) => {
  const { formik, loading } = props;
  const theme = useTheme();
  const setState = useSetRecoilState(requestUberDeliveryModalState);

  return (
    <Box>
      <Modal.Body height="360px">
        <Box marginBottom={theme.space.l}>
          <Text size="s">梱包情報を確認の上、「Uber Eatsに集荷を依頼」ボタンを押してください</Text>
        </Box>
        <Alert status="info" marginBottom={theme.space.l}>
          <Text>集荷を依頼すると、配達員とのマッチングが開始されます</Text>
        </Alert>
        {formik.values.pieces.map((value, idx) => (
          <PiecesContent key={idx} index={idx}>
            <ContentItem index={idx} value={value} />
          </PiecesContent>
        ))}
        {!isProduction && <AutoRadioInput />}
      </Modal.Body>
      <Modal.Footer both>
        <Button
          size="xs"
          use="white"
          onClick={() => {
            setState((state) => ({ ...state, step: 'inputPieces' }));
          }}
        >
          <Icon icon="back-line" size="l" />
          <Text size="m">戻る</Text>
        </Button>
        <Button size="m" use="secondary" disabled={loading} onClick={formik.submitForm}>
          Uber Eatsに集荷を依頼
        </Button>
      </Modal.Footer>
    </Box>
  );
});

ConfirmContent.displayName = 'ConfirmContent';
