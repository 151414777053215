import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { MarginProps } from 'styled-system';

import { Box, Flex, Icon } from '~/components/blocks';

import { ActionButton } from './ActionButton';

type Props = {
  children: React.ReactNode;
  status?: string;
  wide: 'fill' | 'half';
  size: 's' | 'm' | 'l';
  use?: 'primary' | 'primaryInverse';
} & MarginProps;

const Status = styled('div')(({ theme }) =>
  css({
    display: 'inline-block',
    position: 'absolute',
    right: '0px',
    top: '0px',
    backgroundColor: theme.colors.background.bg,
    color: theme.colors.colorPallete.grey,
    fontSize: theme.fontSizes.xs,
    width: 'fit-content',
    lineHeight: theme.space.xs,
    padding: theme.space.xs,
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: theme.space.s,
  }),
);

const Root = styled(Box)<{ wide: Props['wide'] }>(({ wide }) => {
  return css({
    position: 'relative',
    width: wide === 'fill' ? '100%' : '49%',
  });
});

export const InactiveButton = (props: Props) => {
  const { children, status, wide, size, use = 'primaryInverse', ...marginProps } = props;

  return (
    <Root wide={wide} {...marginProps}>
      <ActionButton use={use} size={size} disabled={true} wide="fill">
        {children}
      </ActionButton>
      {status && (
        <Status>
          <Flex alignItems="center">
            <Icon icon="check" size="xs" marginRight="xs" color="black" />
            {status}
          </Flex>
        </Status>
      )}
    </Root>
  );
};
