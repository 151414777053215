import { useEffect, useMemo } from 'react';

import { useGetDraftAppointmentOnInsuranceCardLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

import { useFetchInsuranceCards } from './use-fetch-insurance_cards';

type Props = {
  patientId: string | null | undefined;
  draftAppointmentId: string | null | undefined;
};

export const useFetchInsuranceCardStatus = ({ patientId, draftAppointmentId }: Props) => {
  const {
    insuranceCards,
    loading: insuranceCardLoading,
    timeout,
  } = useFetchInsuranceCards(patientId || null);
  const [getDraftAppointment, { data, loading: draftAppointmentLoading, called }] =
    useGetDraftAppointmentOnInsuranceCardLazyQuery();

  const draftAppointment = useMemo(() => {
    return getNode(data, 'DraftAppointment');
  }, [data]);

  useEffect(() => {
    if (draftAppointmentId) {
      getDraftAppointment({
        variables: {
          id: draftAppointmentId,
        },
      });
    }
  }, [draftAppointmentId, getDraftAppointment]);

  const status = useMemo(() => {
    if (!draftAppointment?.patient?.active) {
      return {
        label: '閲覧期限切れ',
        active: false,
      };
    }

    if (insuranceCards.length === 0) {
      return {
        label: 'データなし',
        active: false,
      };
    }

    return {
      label: 'データあり',
      active: true,
    };
  }, [draftAppointment, insuranceCards]);

  return {
    status,
    loading: insuranceCardLoading || draftAppointmentLoading || !called,
    timeout,
  };
};
