import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';
import { css } from '@styled-system/css';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box, Button, EntryList, Flex, Icon, Text } from '~/components/blocks';
import { PfDispensingRequestSendMethod } from '~/graphql';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { draftAppointmentPatientFileAreaState } from '~/state/reception_drawer/atoms';
import { isPdf } from '~/utils/file';
import { openPreviewPage } from '~/utils/pf_dispensing_request';

import { PdfViewer } from './PdfViewer';
import { PrescriptionImgViewer } from './PrescriptionImgViewer';
import { PrescriptionLoader } from './PrescriptionLoader';
import { useFetchDispensingRequest } from './use-fetch-dispensing-request';

const PreviewButton = styled(Button)(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radii.circle,
    opacity: 0,
    transform: 'translate(-50%,-50%)',
    transition: `opacity linier ${theme.transitions.fast}`,
  }),
);

const Content = styled(Box)(({ onClick, theme }) =>
  css({
    position: 'relative',
    ...(onClick && {
      background: theme.colors.background.bg,
      cursor: 'pointer',
      '&:hover': {
        background: theme.colors.background.grey,
        opacity: 1,
        [`& > ${PreviewButton}`]: {
          opacity: 1,
        },
      },
    }),
  }),
);

export const Prescription = () => {
  const { draftAppointmentId } = useRecoilValue(draftAppointmentPatientFileAreaState);
  const {
    medicalInstitution,
    dispensingRequest,
    willReceivePrescription,
    hasReceivedPrescription,
    loading,
    timeout,
    error,
  } = useFetchDispensingRequest(draftAppointmentId);

  const theme = useTheme();

  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [hasPdfError, setHasPdfError] = useState(false);

  const { electronicPrescription } = useOrganizationElectronicPrescription();

  const hasPdf = dispensingRequest?.prescriptionImages.some(
    (image) => image && isPdf(image.attachment.contentType),
  );
  const isUploaded = dispensingRequest?.sendMethod === PfDispensingRequestSendMethod.Upload;
  const prescriptionImages = isUploaded ? dispensingRequest?.prescriptionImages || [] : [];

  const handleClick = useCallback(() => {
    if (draftAppointmentId) {
      openPreviewPage(draftAppointmentId);
    }
  }, [draftAppointmentId]);
  const handlePdfError = useCallback((error: Error) => {
    setHasPdfError(true);
    Sentry.captureMessage(`react-pdf Error: message=${error.message}`);
  }, []);
  const handlePdfLoad = useCallback(() => setIsPdfLoading(false), []);

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l}>
        <Icon size="xl" icon="drug" />
        <Text fontWeight="bold" marginLeft={theme.space.xs}>
          {electronicPrescription ? '処方箋または処方内容（控え）' : '処方箋'}
        </Text>
      </Flex>
      {timeout ? (
        <Box padding={theme.space.m}>
          <Alert status="error">
            処方箋の取得に時間がかかっています。
            <br />
            しばらく待ってから、再度アクセスしてください
          </Alert>
        </Box>
      ) : error ? (
        <Box padding={theme.space.m}>
          <Alert status="error">取得できませんでした</Alert>
        </Box>
      ) : loading ? (
        <PrescriptionLoader />
      ) : (
        <Box>
          {prescriptionImages.length > 0 ? (
            <Content onClick={hasPdf && (hasPdfError || isPdfLoading) ? undefined : handleClick}>
              {prescriptionImages.map((image) => {
                if (image) {
                  return isPdf(image.attachment.contentType) ? (
                    <PdfViewer
                      key={image.attachment.id}
                      src={image.attachment.url}
                      onError={handlePdfError}
                      onLoad={handlePdfLoad}
                    />
                  ) : (
                    <PrescriptionImgViewer
                      key={image.attachment.id}
                      src={image.attachment.url}
                      alt={image.attachment.title}
                    />
                  );
                } else {
                  return null;
                }
              })}
              <PreviewButton icon use="base">
                <Icon color="white" icon="blank" size="l" />
              </PreviewButton>
            </Content>
          ) : willReceivePrescription ? (
            <Box padding={theme.space.m}>
              <Alert status="info">
                処方箋が届かない等の確認事項がある場合は医療機関へお問い合わせください
              </Alert>
            </Box>
          ) : hasReceivedPrescription && !dispensingRequest ? (
            <Box padding={theme.space.m}>
              <Alert status="warning">医療機関によって処方箋が取り消されました</Alert>
            </Box>
          ) : hasReceivedPrescription ? (
            <Box padding={theme.space.m}>
              <Alert status="info">
                医療機関より送られてきた処方箋または処方内容（控え）をご確認ください
              </Alert>
            </Box>
          ) : null}
          {medicalInstitution && (
            <Box padding={theme.space.m} paddingTop={0} marginTop={theme.space.m}>
              <EntryList size="s">
                <EntryList.Head>医療機関名</EntryList.Head>
                <EntryList.Body>{medicalInstitution.name}</EntryList.Body>
              </EntryList>
              <EntryList size="s">
                <EntryList.Head>住所</EntryList.Head>
                <EntryList.Body>{medicalInstitution.address || '未登録'}</EntryList.Body>
              </EntryList>
              <EntryList size="s">
                <EntryList.Head>連絡先</EntryList.Head>
                <EntryList.Body>{medicalInstitution.tel || '未登録'}</EntryList.Body>
              </EntryList>
              <EntryList size="s">
                <EntryList.Head>担当</EntryList.Head>
                <EntryList.Body>{medicalInstitution.staffName}</EntryList.Body>
              </EntryList>
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};
