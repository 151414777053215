import React from 'react';

import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import { OutpatientQuestionnaireEntry, OutpatientQuestionnaireEntryStatus } from '~/graphql';

type Props = {
  status: OutpatientQuestionnaireEntryStatus;
  isEnabledNotification: OutpatientQuestionnaireEntry['enableNotification'];
};

export const OutpatientQuestionnaireEntryActionInfo = React.memo((props: Props) => {
  const { status, isEnabledNotification } = props;

  if (status === 'entered') {
    if (isEnabledNotification) {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">
            問診を確認しお薬の準備が完了したら、通知を送って患者を呼び出してください
          </ActionInfo.Title>
        </ActionInfo>
      );
    } else {
      return (
        <ActionInfo status="success">
          <ActionInfo.Title status="success">問診を確認し、対応を完了してください</ActionInfo.Title>
        </ActionInfo>
      );
    }
  }

  if (status === 'notified') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">患者の来局をお待ちください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  return null;
});

OutpatientQuestionnaireEntryActionInfo.displayName = 'OutpatientQuestionnaireEntryActionInfo';
