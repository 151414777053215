import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Button, Flex, Icon, Modal, Select, Text } from '~/components/blocks';
import { Interview } from '~/components/partials';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { publicPath } from '~/utils/path';

import { CustomQuestionTitle } from './CustomQuestionTitle';
import { Fields, SelectQuantities } from './type';

type Props = {
  formik: FormikProps<Fields>;
};

const CustomContents = styled(Interview.Contents)(() =>
  css({
    paddingLeft: '0px',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  }),
);

const Image = styled('img')(({ theme }) =>
  css({
    display: 'block',
    minWidth: '360px',
    margin: `calc(${theme.space.m} + ${theme.space.s})`,
    flex: 1,
  }),
);

const CustomSelect = styled(Select)(() =>
  css({
    flex: 1,
  }),
);

export const SelectQuantityContent = React.memo((props: Props) => {
  const { formik } = props;
  const theme = useTheme();

  const handleNext = useRecoilCallback(
    ({ set }) =>
      async () => {
        await formik.setValues({
          ...formik.values,
          pieces: Array.from({ length: formik.values.quantity }).map<number>(() => 1),
        });
        set(requestUberDeliveryModalState, (_state) => ({
          ..._state,
          step: 'inputPieces' as const,
        }));
      },
    [formik],
  );

  const handleBack = useRecoilCallback(
    ({ set }) =>
      () => {
        set(requestUberDeliveryModalState, (_state) => ({
          ..._state,
          step: 'checkList' as const,
        }));
      },
    [],
  );

  return (
    <Box>
      <Modal.Body height="360px">
        <Box>
          <Text marginBottom={theme.space.l} size="s">
            質問に答えて集荷依頼を完了してください。
          </Text>
          <Interview>
            <CustomQuestionTitle>
              <Text fontWeight="bold">配達員に渡す「荷物」はいくつありますか？</Text>
            </CustomQuestionTitle>
            <CustomContents>
              <Image
                alt="当日配達荷物イメージ"
                src={publicPath('/img/same_day_delivery_quantity.png')}
              />
              <CustomSelect
                name="quantity"
                error={formik.errors.quantity}
                value={formik.values.quantity}
                onChange={formik.handleChange}
                fill
              >
                {SelectQuantities.map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </CustomSelect>
            </CustomContents>
          </Interview>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Flex width="100%" justifyContent="space-between">
          <Button size="xs" use="white" onClick={handleBack}>
            <Icon icon="back-line" size="l" />
            <Text size="m">戻る</Text>
          </Button>
          <Button size="m" use="base" onClick={handleNext}>
            次へ
          </Button>
        </Flex>
      </Modal.Footer>
    </Box>
  );
});

SelectQuantityContent.displayName = 'SelectQuantityContent';
