import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Button, Flex, Icon, Loader, Text } from '~/components/blocks';
import { OutpatientQuestionnaireSheet } from '~/components/partials/OutpatientQuestionnaireSheet';
import { OutpatientQuestionnaireSheetClipButton } from '~/components/partials/OutpatientQuestionnaireSheetClipButton';
import { outpatientQuestionnaireEntryFileAreaState } from '~/state/reception_drawer/atoms';

import { useFetchOutpatientQuestionnaireEntryQuestionnaires } from './use-fetch_outpatient_questionnaire_entry_questionnaires';

const LoaderRoot = styled('div')(() =>
  css({
    height: '200px',
    position: 'relative',
  }),
);

export const Questionnaire = () => {
  const { outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireEntryFileAreaState,
  );
  const theme = useTheme();
  const { outpatientQuestionnaireEntry, loading } =
    useFetchOutpatientQuestionnaireEntryQuestionnaires(outpatientQuestionnaireEntryId);

  const questionnaires = outpatientQuestionnaireEntry?.questionnaires || [];
  const enteredAt = outpatientQuestionnaireEntry?.enteredAt || '';

  const handlePrintClick = useCallback(() => {
    if (!outpatientQuestionnaireEntryId) return;

    window.open(
      `/outpatient_questionnaire_sheet_preview/${outpatientQuestionnaireEntryId}`,
      '_blank',
    );
  }, [outpatientQuestionnaireEntryId]);

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Icon size="m" icon="document" />
          <Text fontWeight="bold" marginLeft={theme.space.xs}>
            問診
          </Text>
        </Flex>
        <Flex paddingY={theme.space.m}>
          <Button disabled={loading} size="m" onClick={handlePrintClick} marginRight="m">
            <Icon icon="print" size="m" />
            印刷
          </Button>
          <OutpatientQuestionnaireSheetClipButton
            disabled={loading}
            outpatientQuestionnaireEntry={outpatientQuestionnaireEntry}
          />
        </Flex>
      </Flex>
      {loading ? (
        <LoaderRoot>
          <Loader open inside appearance="white" logoSize="half" />
        </LoaderRoot>
      ) : (
        <Box position="relative" marginBottom={theme.space.xxl}>
          <OutpatientQuestionnaireSheet answers={questionnaires} enteredAt={enteredAt} />
        </Box>
      )}
    </Flex>
  );
};
