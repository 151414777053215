import React, { ComponentProps } from 'react';

import { Chip } from '~/components/blocks';
import { ReceptionStatus } from '~/components/partials/ReceptionStatus';
import { CheckinEntryStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  status: CheckinEntryStatus;
};

export const CheckinEntryStatusLabel = (props: Props) => {
  const { status } = props;

  if (status === CheckinEntryStatus.Entered) {
    return (
      <ReceptionStatus
        label="指導待ち"
        chipColor="blue"
        labelColor="white"
        detailedStatus={{ label: '未通知', color: 'pink', iconName: 'notification' }}
      />
    );
  }

  if (status === CheckinEntryStatus.Notified) {
    return (
      <ReceptionStatus
        label="指導待ち"
        chipColor="blue"
        labelColor="white"
        detailedStatus={{ label: '通知済', color: 'black', iconName: 'notification' }}
      />
    );
  }

  return <ReceptionStatus label="完了" chipColor="grey03" labelColor="black" />;
};
