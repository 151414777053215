import { useEffect } from 'react';

import { UberDeliveryStatus, useGetUberDeliveryLazyQuery } from '~/graphql';

type Props = {
  uberDeliveryId: string;
  status: UberDeliveryStatus;
};

export const useFetchUberCourier = ({ uberDeliveryId, status }: Props) => {
  const [getCourier, { loading, data }] = useGetUberDeliveryLazyQuery();
  const courier = data?.node?.__typename === 'UberDelivery' ? data.node.courier : null;

  useEffect(() => {
    if (status === 'dropoff' || status == 'pickup') {
      getCourier({ variables: { uberDeliveryId } });
    }
  }, [uberDeliveryId, status, getCourier]);

  return { courier, loading };
};
