import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Chip, Flex, Icon, Text } from '~/components/blocks';

type Props = {
  isAppPatient: boolean;
  active?: boolean;
};

const CustomChip = styled(Chip)<{ active: boolean }>(({ theme, active }) =>
  css({
    border: active ? theme.borders.default : 'none',
    size: 'fixed',
    backgroundColor: active ? theme.colors.colorPallete.white : theme.colors.colorPallete.grey04,
    padding: `0 ${theme.space.m}`,
  }),
);

export const ClinicsAppTag = ({ isAppPatient, active }: Props) => {
  const theme = useTheme();

  if (isAppPatient) {
    return (
      <CustomChip active={active || false}>
        <Flex alignItems="center">
          <Icon icon="app" size="l" marginRight={theme.space.xs} />
          <Text
            size="xs"
            color={active ? 'navy' : 'grey01'}
            lineHeight="1"
            padding={`${theme.space.s} 0`}
          >{`CLINICS会員${active ? '' : '（退会済）'}`}</Text>
        </Flex>
      </CustomChip>
    );
  } else {
    return (
      <CustomChip active={false}>
        <Text size="xs" color="grey01" lineHeight="1" padding={`${theme.space.s} 0`}>
          CLINICS非会員
        </Text>
      </CustomChip>
    );
  }
};
