import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Grid, Tabs } from '~/components/blocks';
import { WebVisitorTabMap } from '~/constants/tab';
import { PlanFeatureCode, WebVisitorAppointmentDetailFragment } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { webVisitorTabState } from '~/state/reception/atoms';
import { WebVisitorTabType } from '~/state/reception/types';

import { FilePane } from './FilePane';
import { HistoryPane } from './HistoryPane';
import { MessagePane } from './MessagePane';
import { ReceptionPane } from './ReceptionPane';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

export const WebVisitorKarte = React.memo((props: Props) => {
  const { appointment } = props;
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const [tab, setTab] = useRecoilState(webVisitorTabState);

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as WebVisitorTabType;

      setTab((_state) => ({ ..._state, type: newTab }));
    },
    [setTab],
  );

  return hasEnabledFollowup ? (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab.type === WebVisitorTabMap.reception}
          data-tab={WebVisitorTabMap.reception}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === WebVisitorTabMap.history}
          data-tab={WebVisitorTabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === WebVisitorTabMap.message}
          data-tab={WebVisitorTabMap.message}
          onClick={handleChange}
          size="xs"
        >
          メッセージ
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === WebVisitorTabMap.file}
          data-tab={WebVisitorTabMap.file}
          onClick={handleChange}
          size="xs"
        >
          ファイル
        </Tabs.Tab>
      </Tabs>
      {tab.type === WebVisitorTabMap.reception && <ReceptionPane appointment={appointment} />}
      {tab.type === WebVisitorTabMap.history && <HistoryPane />}
      {tab.type === WebVisitorTabMap.message && (
        <MessagePane webVisitorId={appointment.webVisitor?.id as string} />
      )}
      {tab.type === WebVisitorTabMap.file && (
        <FilePane webVisitorId={appointment.webVisitor?.id as string} />
      )}
    </Grid>
  ) : (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab.type === WebVisitorTabMap.reception}
          data-tab={WebVisitorTabMap.reception}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === WebVisitorTabMap.history}
          data-tab={WebVisitorTabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
      </Tabs>
      {tab.type === WebVisitorTabMap.reception && <ReceptionPane appointment={appointment} />}
      {tab.type === WebVisitorTabMap.history && <HistoryPane />}
    </Grid>
  );
});

WebVisitorKarte.displayName = 'WebVisitorKarte';
