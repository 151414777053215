import React from 'react';

import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import { ReceptionUberDeliveryFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { useFetchUberCourier } from '../use-fetch_uber_courier';

type Props = {
  uberDelivery: ReceptionUberDeliveryFragment;
};

export const UberCourierInfo = React.memo((props: Props) => {
  const { uberDelivery } = props;

  const { courier } = useFetchUberCourier({
    uberDeliveryId: uberDelivery?.id,
    status: uberDelivery.status,
  });

  if (courier && courier.pickupScheduledAt) {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          {courier.name}
          さんが{Label.HHMM(courier.pickupScheduledAt)}
          ごろ薬局に到着予定です。到着したら、注文番号「{uberDelivery.orderNumber}
          」をご確認の上、お薬を渡してください
        </ActionInfo.Title>
      </ActionInfo>
    );
  }
});

UberCourierInfo.displayName = 'UberCourierInfo';
