import { useTheme } from '@emotion/react';
import React from 'react';

import { Flex, Name, Text } from '~/components/blocks';
import { PatientProfilePatientFragment } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { Label } from '~/utils/label';

import { BookmarkTag } from '../BookmarkTag';
import { ClinicsAppTag } from '../ClinicsAppTag';
import { MedixsTag } from '../MedixsTag';

type Props = {
  patient: PatientProfilePatientFragment;
};

export const Profile: React.FC<Props> = (props) => {
  const { patient } = props;
  const theme = useTheme();
  const { enabledMedixs } = useMedixsSetting();

  const isBookmarked = !!patient.bookmark;

  return (
    <>
      <Flex flexDirection="row" alignItems="flex-end" paddingRight={theme.space.m}>
        <Text wordBreak="break-all">
          <Name
            familyName={patient.familyName || ''}
            givenName={patient.givenName || ''}
            phoneticFamilyName={patient.phoneticFamilyName || ''}
            phoneticGivenName={patient.phoneticGivenName || ''}
            size="l"
          />
        </Text>
        <Text lineHeight={theme.lineHeights.s} marginLeft={theme.space.m}>
          {Label.age(patient.birthDate)}（{Label.sex(patient.sex)}）
        </Text>
      </Flex>
      <Flex marginY={theme.space.l}>
        <ClinicsAppTag isAppPatient={true} active={patient.active} />
        {patient.active && (
          <Flex marginLeft={theme.space.s}>
            <BookmarkTag isBookmarked={isBookmarked} />
          </Flex>
        )}
        {enabledMedixs && (
          <Flex marginLeft={theme.space.s}>
            <MedixsTag isLinked={!!patient.medixsPatient} />
          </Flex>
        )}
      </Flex>
    </>
  );
};
