import React from 'react';
import { useRecoilCallback } from 'recoil';
import { MarginProps } from 'styled-system';

import { ActionButton } from '~/components/partials/ReceptionDrawer/ActionButton';
import { requestAppointmentModalState } from '~/state/reception/atoms';

export const RequestBookingButton = (props: MarginProps) => {
  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(requestAppointmentModalState, {
          isOpen: true,
        });
      },
    [],
  );

  return (
    <ActionButton use="primaryInverse" wide="fill" size="l" onClick={handleClick} {...props}>
      FAX等で処方箋を受領した
    </ActionButton>
  );
};
