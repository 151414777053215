import { useEffect } from 'react';

import { useGetOutpatientQuestionnaireEntryOnQuestionnaireLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchOutpatientQuestionnaireEntryQuestionnaires = (
  outpatientQuestionnaireEntryId: string | null,
) => {
  const [getOutpatientQuestionnaireEntry, { called, loading, data }] =
    useGetOutpatientQuestionnaireEntryOnQuestionnaireLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

  const outpatientQuestionnaireEntry = getNode(data, 'OutpatientQuestionnaireEntry');

  useEffect(() => {
    if (outpatientQuestionnaireEntryId) {
      getOutpatientQuestionnaireEntry({ variables: { id: outpatientQuestionnaireEntryId } });
    }
  }, [outpatientQuestionnaireEntryId, getOutpatientQuestionnaireEntry]);

  return {
    loading: !called || loading,
    outpatientQuestionnaireEntry: outpatientQuestionnaireEntry,
  };
};
