import { useEffect, useMemo } from 'react';

import { useGetDraftAppointmentOnFileMenuLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  draftAppointmentId: string | null | undefined;
};

export const useFetchPrescriptionStatus = ({ draftAppointmentId }: Props) => {
  const [getDraftAppointment, { data, loading, called }] =
    useGetDraftAppointmentOnFileMenuLazyQuery();
  const draftAppointment = useMemo(() => {
    return getNode(data, 'DraftAppointment');
  }, [data]);

  useEffect(() => {
    if (draftAppointmentId) {
      getDraftAppointment({
        variables: {
          id: draftAppointmentId,
        },
      });
    }
  }, [draftAppointmentId, getDraftAppointment]);

  const status = useMemo(() => {
    if (draftAppointment?.status === 'available') {
      return {
        label: '医療機関待ち',
        active: true,
      };
    }

    if (draftAppointment?.pfDispensingRequest?.sendMethod === 'UPLOAD') {
      return {
        label: 'データあり',
        active: true,
      };
    }

    return {
      label: 'データなし',
      active: true,
    };
  }, [draftAppointment]);

  return {
    status,
    loading: loading || !called,
  };
};
