import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

import { Button } from '~/components/blocks';

type Props = {
  size: 's' | 'm' | 'l';
};

export const ActionButton = styled(Button, { shouldForwardProp })<Props>(({ theme }) =>
  compose(
    variant({
      prop: 'size',
      variants: {
        s: css({
          fontSize: theme.fontSizes.s,
          height: '35px',
        }),
        m: css({
          fontSize: theme.fontSizes.s,
          height: '45px',
        }),
        l: css({
          fontSize: theme.fontSizes.m,
          height: '45px',
        }),
      },
    }),
  ),
);
