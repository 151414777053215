import React from 'react';

import { ExternalLink } from '~/components/blocks/ExternalLink';
import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';

export const DraftAppointmentSameDayDeliveryInfo = React.memo(() => {
  return (
    <ActionInfo.Title status="success">
      集荷依頼までに、当日配達（Uber Eats）資材を確認し、準備をお願いします
      <ActionInfo.Description>
        <ExternalLink url="https://intercom.help/pharms/ja/articles/9049950-%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94-uber-eats-%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E5%AF%BE%E5%BF%9C%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6">
          資材のダウンロードおよび対応方法
        </ExternalLink>
      </ActionInfo.Description>
    </ActionInfo.Title>
  );
});

DraftAppointmentSameDayDeliveryInfo.displayName = 'DraftAppointmentSameDayDeliveryInfo';
