import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Flex, Icon, Text } from '~/components/blocks';
import { NotificationListItem } from '~/components/partials/NotificationListItem';
import { OrganizationEntryNotificationFragment } from '~/graphql';
import { notificationPanelState } from '~/state/layouts/SharedAppShell/atoms';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  newReceptionDrawerState,
  outpatientQuestionnaireEntryProfileDrawerState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

type Props = {
  entryNotification: OrganizationEntryNotificationFragment;
};

export const EntryNotification = (props: Props) => {
  const { entryNotification } = props;
  const router = useRouter();
  const theme = useTheme();

  const handleOpenReception = useRecoilCallback(
    ({ set, reset }) =>
      async (e: React.MouseEvent<HTMLLIElement>) => {
        // 受付ドロアーで既読処理をするため、親要素で既読処理が実行されないようにする
        e.stopPropagation();

        if (router.pathname !== '/reception') {
          await router.push('/reception');
        }

        // 受付通知パネルを閉じる
        set(notificationPanelState, (_state) => ({ ..._state, isOpen: false }));

        if (entryNotification.checkinEntry) {
          set(checkinEntryProfileDrawerState, {
            isOpen: true,
            checkinEntryId: entryNotification.checkinEntry.id,
          });
          reset(outpatientQuestionnaireEntryProfileDrawerState);
          reset(profileDrawerState);
          reset(webVisitorProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }

        if (entryNotification.outpatientQuestionnaireEntry) {
          set(outpatientQuestionnaireEntryProfileDrawerState, {
            isOpen: true,
            outpatientQuestionnaireEntryId: entryNotification.outpatientQuestionnaireEntry.id,
          });
          reset(checkinEntryProfileDrawerState);
          reset(profileDrawerState);
          reset(webVisitorProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }
      },
    [entryNotification, router],
  );
  const isTaskUncompleted = !entryNotification.isRead;

  return (
    <NotificationListItem onClick={handleOpenReception} isMarked={isTaskUncompleted}>
      <Flex flexDirection="column" marginRight={theme.space.m}>
        <Text fontWeight="bold" size="s">
          {entryNotification.subject}
        </Text>
        <Text size="xs" color="grey01" marginTop={theme.space.xs}>
          {`${Label.YYYYMMDDja(entryNotification.updatedAt)} ${Label.HHMM(entryNotification.updatedAt)}`}
        </Text>
      </Flex>
      <Icon icon="forward-line" size="m" marginLeft="auto" />
    </NotificationListItem>
  );
};
