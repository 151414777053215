import { DeliveryMethodLabel } from './delivery_method';

export const TEMPLATE_MEMOS = ['原本到着まち', '疑義照会', '要患者連絡', '薬剤在庫なし'];
export const SUMMARIZED_MEMO = 'メモ記載あり';
export const MEDICATION_FOLLOWUP_TEMPLATE_MEMO_REGEX = new RegExp(
  `^服薬フォローアップ｜[0-9][0-9]\\/[0-9][0-9]$`,
);

const DELIVERY_METHODS = Object.values(DeliveryMethodLabel);
export const DELIVERY_REGEX = new RegExp(`[${DELIVERY_METHODS.join('|')}]｜[0-9].\\/[0-9].`);
