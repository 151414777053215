import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';

import { Box, Collapse, EntryList, Flex, Text } from '~/components/blocks';
import { AppointmentStatusLabel, DispensingRequestCollapse } from '~/components/partials';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { AppointmentDeliveryMethod, HistoryAppointmentFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  appointment: HistoryAppointmentFragment;
};

const formatAppoinment = (appointment: HistoryAppointmentFragment) => {
  const { charge } = appointment;
  let amount = 'ー';
  let practitionerName = 'ー';

  if (charge) {
    amount = Label.amount(+charge.amount);
    practitionerName = `${charge.practitioner.familyName}${charge.practitioner.givenName}`;
  }

  return {
    datetime: `${Label.YYYYMMDDja(appointment.createdAt)} ${Label.HHMM(appointment.createdAt)}`,
    telemedicine: appointment.telemedicine,
    amount,
    practitionerName,
    status: appointment.status,
  };
};

export const History = (props: Props) => {
  const theme = useTheme();
  const appointment = useMemo(() => formatAppoinment(props.appointment), [props.appointment]);

  return (
    <Collapse label={appointment.datetime}>
      <Box padding={`0 ${theme.space.m} ${theme.space.l}`}>
        <Flex alignItems="center" justifyContent="space-between" paddingY={theme.space.l}>
          <MedicationInstructionMethodLabel
            method={props.appointment.telemedicine ? 'telemedicine' : 'faceToFace'}
          />
          <AppointmentStatusLabel
            status={props.appointment.status}
            uberDelivery={props.appointment.uberDelivery}
            isSameDayDelivery={
              props.appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
            }
          />
        </Flex>
        <DispensingRequestCollapse appointmentId={props.appointment.id} />
        <Flex alignItems="flex-start" justifyContent="space-between" mt={theme.space.l}>
          <EntryList>
            <EntryList.Head>担当薬剤師</EntryList.Head>
            <EntryList.Body>
              <strong>{appointment.practitionerName}</strong>
            </EntryList.Body>
          </EntryList>
        </Flex>
        <Box padding={theme.space.l} mt={theme.space.m} bg={theme.colors.background.bg}>
          <EntryList>
            <EntryList.Head>合計金額</EntryList.Head>
            <EntryList.Body>
              <Text size="l" fontWeight="bold">
                {appointment.amount}
              </Text>
            </EntryList.Body>
          </EntryList>
        </Box>
      </Box>
    </Collapse>
  );
};
