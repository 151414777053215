import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import { Flex, Icon, Tag, TagGroup, Text } from '~/components/blocks';
import { SUMMARIZED_MEMO } from '~/constants/memo';
import { getReceptionMemos } from '~/utils/memo';

type Props = {
  memo: string;
};

const CustomTag = styled(Tag)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
  }),
);

export const ReceptionMemos = (props: Props) => {
  const { memo } = props;
  const memos = useMemo(() => getReceptionMemos(memo), [memo]);

  return (
    <TagGroup>
      {memos.map((memo, idx) =>
        memo === SUMMARIZED_MEMO ? (
          <CustomTag key={idx} whiteSpace="nowrap">
            <Flex alignItems="center">
              <Icon icon="edit" color="black" size="m" />
              <Text size="s">{memo}</Text>
            </Flex>
          </CustomTag>
        ) : (
          <CustomTag key={idx} whiteSpace="nowrap">
            <Text size="s">{memo}</Text>
          </CustomTag>
        ),
      )}
    </TagGroup>
  );
};
