import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, EntryList, Flex, Icon, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

type Props = {
  start?: string | Date;
  end?: string | Date;
};

const IconBox = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    borderRadius: theme.radii.circle,
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.space.s,
  }),
);

export const MedicationInstructionDateTime = ({ start, end }: Props) => {
  const theme = useTheme();

  return (
    <Flex justifyContent="flex-start">
      <Flex alignItems="center">
        <IconBox>
          <Icon size="l" icon="time" color="grey" />
        </IconBox>
        <EntryList size="s">
          <EntryList.Head>服薬指導日時</EntryList.Head>
          <EntryList.Body>
            {start && end ? (
              <>
                <Text fontWeight="bold" size="m">
                  {Label.YYYYMMDDja(start)}
                </Text>
                <Text fontWeight="bold" size="m" ml={theme.space.s}>
                  {Label.HHMM(start)}-{Label.HHMM(end)}
                </Text>
              </>
            ) : (
              <Text fontWeight="bold" size="m">
                未確定
              </Text>
            )}
          </EntryList.Body>
        </EntryList>
      </Flex>
    </Flex>
  );
};
