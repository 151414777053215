import React, { useMemo } from 'react';

import { ClipButton } from '~/components/partials';
import { OutpatientQuestionnaireEntry } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  disabled: boolean;
  outpatientQuestionnaireEntry?: OutpatientQuestionnaireEntry;
};

const buildText = (outpatientQuestionnaireEntry: OutpatientQuestionnaireEntry) => {
  const enteredDate = `【回答日】${Label.YYYYMMDDja(outpatientQuestionnaireEntry.enteredAt)} ${Label.HHMM(outpatientQuestionnaireEntry.enteredAt)}`;
  const answersText = outpatientQuestionnaireEntry.questionnaires.map(({ title, answers }) => {
    return `Q：${title}
${answers.join('｜')}`;
  });
  return `${enteredDate}
${answersText.join('\n')}`;
};

export const OutpatientQuestionnaireSheetClipButton = React.memo((props: Props) => {
  const { disabled, outpatientQuestionnaireEntry } = props;
  const clipboardText = useMemo(() => {
    if (outpatientQuestionnaireEntry) {
      return buildText(outpatientQuestionnaireEntry);
    } else {
      return '';
    }
  }, [outpatientQuestionnaireEntry]);

  return (
    <ClipButton size="m" disabled={disabled} iconSize="m" text={clipboardText} btnText="コピー" />
  );
});

OutpatientQuestionnaireSheetClipButton.displayName = 'OutpatientQuestionnaireSheetClipButton';
