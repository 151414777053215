import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { BasePatientProfileFragment } from '~/graphql';

import { ProfileDetail } from './ProfileDetail';

type Props = {
  patient?: BasePatientProfileFragment | null;
};

const Root = styled('div')(({ theme }) =>
  css({
    padding: `${theme.space.l} ${theme.space.l}`,
  }),
);

export const Profile = React.memo((props: Props) => {
  const { patient } = props;

  return (
    <Root>
      <ProfileDetail patient={patient} />
    </Root>
  );
});

Profile.displayName = 'Profile';
