import { useEffect, useMemo } from 'react';

import { useGetAppointmentOnAppointmentFileMenuLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  appointmentId: string | null | undefined;
};

export const useFetchPrescriptionStatus = ({ appointmentId }: Props) => {
  const [getAppointment, { data, loading, called }] =
    useGetAppointmentOnAppointmentFileMenuLazyQuery();

  const appointment = useMemo(() => {
    return getNode(data, 'Appointment');
  }, [data]);

  useEffect(() => {
    if (appointmentId) {
      getAppointment({
        variables: {
          id: appointmentId,
        },
      });
    }
  }, [appointmentId, getAppointment]);

  const status = useMemo(() => {
    if (
      appointment?.pfDispensingRequestUploadType === 'app' ||
      appointment?.pfDispensingRequestUploadType === 'medical_institution'
    ) {
      return {
        label: 'データあり',
        active: true,
      };
    }

    return {
      label: 'データなし',
      active: true,
    };
  }, [appointment]);

  return {
    status,
    loading: loading || !called,
  };
};
