import React from 'react';
import { BackgroundProps, MarginProps, PaddingProps } from 'styled-system';

import { Portal } from '../Portal';
import { DEFAULT_VARIANT, Drawer as DrawerCommponent } from './Drawer';
import { Footer } from './Footer';

type Variant = 'permanent' | 'persistent' | 'temporary' | 'inside';

type Anchor = 'left' | 'right';

type Props = PaddingProps &
  MarginProps &
  BackgroundProps & {
    open: boolean;
    onClose?: () => void;
    variant?: Variant;
    anchor?: Anchor;
    width?: string;
    fullHeight?: boolean;
    onTransitionEnd?: () => void;
    children?: React.ReactNode;
  };

const Component: React.FC<Props> = React.memo((props) => {
  const { variant = DEFAULT_VARIANT } = props;

  return variant === 'temporary' ? (
    <Portal>
      <DrawerCommponent {...props} />
    </Portal>
  ) : (
    <DrawerCommponent {...props} />
  );
});

Component.displayName = 'Drawer';

export const Drawer = Object.assign(Component, { Footer });
