import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Box, EntryList, Flex, Icon, Text } from '~/components/blocks';
import { CheckinEntryStatusLabel, MemoField } from '~/components/partials';
import { MedicationInstructionDateTime } from '~/components/partials/MedicationInstructionDateTime';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { ReceiveOption } from '~/components/partials/ReceiveOption';
import { CheckinEntryDetailFragment } from '~/graphql';
import { theme } from '~/styles/theme';
import { Label } from '~/utils/label';

import { CheckinEntryActionInfo } from './ActionInfo';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  checkinEntry: CheckinEntryDetailFragment;
};

const PatientBox = styled(Box)(({ theme }) =>
  css({
    borderTop: `1px solid ${theme.colors.border.default}`,
    paddingTop: theme.space.l,
  }),
);

export const ProfilePane = React.memo((props: Props) => {
  const { checkinEntry } = props;
  const enteredAt = checkinEntry.enteredAt;
  const enteredEndAt = new Date(+new Date(enteredAt) + 30 * 60 * 1000);

  const { updating, latestMemo, update } = useUpdateMemo(checkinEntry.id);

  const handleChangeMemo = useCallback(
    (memo: string) => update(checkinEntry.id, memo),
    [update, checkinEntry.id],
  );

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      <Flex flexDirection="column">
        <CheckinEntryActionInfo status={checkinEntry.status} />
        <Flex justifyItems="start" marginY={theme.space.m} alignItems="center">
          <MedicationInstructionMethodLabel method="faceToFace" />
          <Box marginLeft="auto">
            <CheckinEntryStatusLabel status={checkinEntry.status} />
          </Box>
        </Flex>
        <MedicationInstructionDateTime start={enteredAt} end={enteredEndAt} />
      </Flex>
      <ReceiveOption deliveryMethod="hand" />
      <Flex alignItems="center" marginY={theme.space.m}>
        <Icon icon="checkin" size="m" />
        <Text size="s" marginLeft={theme.space.xs}>
          店舗チェックイン
        </Text>
      </Flex>
      <PatientBox>
        <Text fontWeight="bold" marginBottom={theme.space.m}>
          患者情報
        </Text>
        <EntryList>
          <EntryList.Head>氏名</EntryList.Head>
          <EntryList.Body>
            {`${checkinEntry.familyName} ${checkinEntry.givenName}`}（
            {`${checkinEntry?.phoneticFamilyName} ${checkinEntry?.phoneticGivenName}`}）
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>電話番号（携帯）</EntryList.Head>
          <EntryList.Body>{checkinEntry.phoneNumber}</EntryList.Body>
        </EntryList>
        <Text fontWeight="bold" marginTop={theme.space.xl} marginBottom={theme.space.m}>
          その他
        </Text>
        <EntryList>
          <EntryList.Head>申し込み日時</EntryList.Head>
          <EntryList.Body>
            <Flex>
              <Box>
                <Text size="m">{Label.YYYYMMDDja(enteredAt)}</Text>
                <Text size="m">{Label.HHMM(enteredAt)}</Text>
              </Box>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <EntryList marginTop={theme.space.l}>
          <EntryList.Head>メモ</EntryList.Head>
          <EntryList.Body>
            <MemoField
              disabled={updating}
              value={latestMemo.mutationCalled ? latestMemo.memo : checkinEntry.description || ''}
              onChange={handleChangeMemo}
            />
          </EntryList.Body>
        </EntryList>
      </PatientBox>
    </Box>
  );
});

ProfilePane.displayName = 'ProfilePane';
