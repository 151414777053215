import React from 'react';

import { ActionInfo } from '~/components/partials/ReceptionDrawer/ActionInfo';
import { AppointmentStatus } from '~/graphql';

type Props = {
  status: AppointmentStatus;
};

export const WebVisitorActionInfo = React.memo(({ status }: Props) => {
  if (status === 'pending') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">処方箋を確認し、受付してください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'booked') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">
          お薬の準備ができたら準備完了通知を送って患者にお伝えください
          <ActionInfo.Description>
            薬局が混み合っており時間に間に合わない場合は、混雑時通知を送ってください
          </ActionInfo.Description>
        </ActionInfo.Title>
        <ActionInfo.Title status="success">服薬指導後、会計操作を行なってください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  if (status === 'waiting_for_charge') {
    return (
      <ActionInfo status="success">
        <ActionInfo.Title status="success">服薬指導後、会計操作を行なってください</ActionInfo.Title>
      </ActionInfo>
    );
  }

  return null;
});

WebVisitorActionInfo.displayName = 'WebVisitorActionInfo';
