import { atom } from 'recoil';

import { FilePageInfo, HistoryPageInfo, Tab } from './types';

export const filePageInfoState = atom<FilePageInfo>({
  key: 'partials.telemedicinePatientProfile.filePageInfo',
  default: {
    patientId: null,
    page: 1,
    totalPage: 1,
    perPage: 10,
    filter: { isSent: true, isReceived: true },
  },
});

export const historyPageInfoState = atom<HistoryPageInfo>({
  key: 'partilas.telemedicinePatientProfile.historyPageInfo',
  default: {
    page: 1,
    totalPage: 1,
    perPage: 15,
  },
});

export const tabState = atom<Tab>({
  key: 'partials.telemedicinePatientProfile.tab',
  default: {
    type: 'reception',
  },
});
