import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex } from '~/components/blocks';

import { ReceptionDrawerWidth } from './constants';

type Props = {
  children: React.ReactNode;
};

export const ReceptionDrawerFileMenu: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      overflow="hidden"
      height="100%"
      backgroundColor={theme.colors.background.bg}
    >
      <Flex flexDirection="column" width={ReceptionDrawerWidth.fileMenu} height="100%">
        {props.children}
      </Flex>
    </Box>
  );
};
