import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, Grid, Tabs } from '~/components/blocks';
import { useConfirmPatientStatus } from '~/components/partials/PatientKarte/use-confirm-patient_status';
import { TabMap } from '~/constants/tab';
import { PlanFeatureCode } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { tabState } from '~/state/partials/patient_karte/atoms';
import { TabType } from '~/state/partials/patient_karte/types';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';

import { FilePane } from './FilePane';
import { HistoryPane } from './HistoryPane';
import { MessagePane } from './MessagePane';
import { ReceptionPane } from './ReceptionPane';

type Props = {
  online?: boolean;
  patientId: string;
  appointmentId?: string;
  draftAppointmentId?: string;
};

export const PatientKarte = React.memo((props: Props) => {
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);

  const { appointmentId } = useRecoilValue(profileDrawerState);
  const { isActivePatient } = useConfirmPatientStatus(props.patientId);
  const [tab, setTab] = useRecoilState(tabState);
  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as TabType;
      setTab({ type: newTab });
    },
    [setTab],
  );

  useEffect(() => {
    if (props.appointmentId !== appointmentId) {
      setTab({ type: 'reception' });
    }
  }, [appointmentId, props.appointmentId, setTab]);

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab.type === TabMap.reception}
          data-tab={TabMap.reception}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === TabMap.history}
          data-tab={TabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        {hasEnabledFollowup && (
          <Tabs.Tab
            attached
            active={tab.type === TabMap.message}
            data-tab={TabMap.message}
            onClick={handleChange}
            size="xs"
          >
            メッセージ
          </Tabs.Tab>
        )}
        <Tabs.Tab
          attached
          active={tab.type === TabMap.file}
          data-tab={TabMap.file}
          onClick={handleChange}
          size="xs"
        >
          ファイル
        </Tabs.Tab>
      </Tabs>
      <Box overflow="auto">
        {tab.type === TabMap.reception && (
          <ReceptionPane
            appointmentId={props.appointmentId}
            draftAppointmentId={props.draftAppointmentId}
          />
        )}

        {tab.type === TabMap.history && <HistoryPane patientId={props.patientId} />}
        {tab.type === TabMap.message && (
          <MessagePane
            online={props.online}
            patientId={props.patientId}
            isActivePatient={isActivePatient}
          />
        )}
        {tab.type === TabMap.file && (
          <FilePane
            online={props.online}
            patientId={props.patientId}
            isActivePatient={isActivePatient}
          />
        )}
      </Box>
    </Grid>
  );
});

PatientKarte.displayName = 'PatientKarte';
