import React from 'react';

import { Drawer, Flex } from '~/components/blocks';

import { ReceptionDrawerWidth } from './constants';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  onTransitionEnd?: () => void;
  isExpended: boolean;
  use?: 'temporary' | 'inside';
};

export const ReceptionDrawer: React.FC<Props> = (props) => {
  const use = props.use || 'temporary';

  return (
    <Drawer
      open={props.isOpen}
      onClose={props.onClose}
      onTransitionEnd={props.onTransitionEnd}
      width={props.isExpended ? ReceptionDrawerWidth.expanded : ReceptionDrawerWidth.base}
      variant={use}
    >
      <Flex flexDirection="row" height="100%">
        {props.children}
      </Flex>
    </Drawer>
  );
};
