import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Box, Grid, Tooltip } from '~/components/blocks';
import { ReceptionDrawer } from '~/components/layouts/ReceptionDrawer';
import { ReceptionDrawerFileMenu } from '~/components/layouts/ReceptionDrawer/FileMenu';
import { PatientKarte, PatientProfile } from '~/components/partials';
import { DEFAULT_WINDOW } from '~/components/partials/MedicineNotesContainer';
import { FileMenuButton } from '~/components/partials/ReceptionDrawer/FileMenuButton';
import { FileMenuSkeletonButton } from '~/components/partials/ReceptionDrawer/FileMenuSkeletonButton';
import { ReceptionMainBox } from '~/components/partials/ReceptionDrawer/ReceptionMainBox';
import { ReceptionFileType } from '~/constants/reception_file_type';
import { appointmentPatientFileAreaState } from '~/state/reception_drawer/atoms';
import { ReceptionDrawerFileType } from '~/state/reception_drawer/types';
import { openWithNoOpener } from '~/utils/window';

import { Footer } from '../Footer';
import { useMarkNotification } from '../use-mark-notification';
import { FileArea } from './FileArea';
import { useFetchAndIdentifyMedixsReception } from './use-fetch-and-identify-medixs-reception';
import { useFetchInsuranceCardStatus } from './use-fetch-insurance_card_status';
import { useFetchMedicineNoteStatus } from './use-fetch-medicine_note_status';
import { useFetchPrescriptionStatus } from './use-fetch-prescription_status';

type Props = {
  open: boolean;
  appointmentId?: string | null;
  patientId?: string | null;
  onClose: () => void;
  onTransitionEnd: () => void;
};

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const ProfileDrawer = (props: Props) => {
  const { onClose, onTransitionEnd, appointmentId, patientId } = props;
  const theme = useTheme();
  const [isFileAreaOpened, setOpenFileArea] = useState(false);
  const setFileAreaState = useSetRecoilState(appointmentPatientFileAreaState);

  const handleOpenFileArea = useCallback(
    (type: ReceptionDrawerFileType) => {
      if (appointmentId) {
        setOpenFileArea(true);
        setFileAreaState((state) => ({
          ...state,
          type,
          appointmentId,
          patientId: patientId || null,
        }));
      }
    },
    [appointmentId, patientId, setFileAreaState],
  );
  const handleCloseFileArea = useCallback(() => {
    setOpenFileArea(false);
  }, []);

  const handleOpenMedicineNote = useCallback(() => {
    if (patientId) {
      openWithNoOpener(`/medicinenote/patients/${patientId}`, {
        target: patientId,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });
    }
  }, [patientId]);

  useMarkNotification(appointmentId);
  const { status: prescriptionStatus, loading: prescriptionLoading } = useFetchPrescriptionStatus({
    appointmentId: appointmentId,
  });
  const {
    status: insuranceCardStatus,
    loading: insuranceCardLoading,
    timeout: insuranceCardTimeout,
  } = useFetchInsuranceCardStatus({
    appointmentId: appointmentId,
    patientId: patientId,
  });
  useFetchAndIdentifyMedixsReception(appointmentId);
  const { status: medicineNoteStatus, loading: medicineNoteLoading } = useFetchMedicineNoteStatus({
    appointmentId: appointmentId,
  });

  useEffect(() => {
    setOpenFileArea(false);
  }, [appointmentId, props.open]);

  return (
    <ReceptionDrawer
      isOpen={props.open}
      onClose={onClose}
      onTransitionEnd={onTransitionEnd}
      isExpended={isFileAreaOpened}
    >
      <ReceptionDrawerFileMenu>
        {prescriptionLoading ? (
          <FileMenuSkeletonButton marginTop={theme.space.xl} />
        ) : (
          <FileMenuButton
            fileName="処方箋"
            active={prescriptionStatus.active}
            statusLabel={prescriptionStatus.label}
            onClick={() => handleOpenFileArea(ReceptionFileType.prescription)}
            marginTop={theme.space.xl}
          />
        )}
        {insuranceCardLoading ? (
          <FileMenuSkeletonButton />
        ) : insuranceCardTimeout ? (
          <Tooltip
            content={
              <>
                データ取得に時間がかかっています
                <br />
                しばらく経ってから再度お試しください
              </>
            }
            placement="right"
          >
            <div>
              <FileMenuButton fileName="保険証" active={false} statusLabel="取得不可" use="error" />
            </div>
          </Tooltip>
        ) : (
          <FileMenuButton
            fileName="保険証"
            active={insuranceCardStatus.active}
            statusLabel={insuranceCardStatus.label}
            onClick={() => handleOpenFileArea(ReceptionFileType.insuranceCard)}
          />
        )}
        <FileMenuButton
          fileName="問診票"
          active={true}
          statusLabel="データあり"
          onClick={() => handleOpenFileArea(ReceptionFileType.questionnaire)}
        />
        {medicineNoteLoading ? (
          <FileMenuSkeletonButton />
        ) : (
          <FileMenuButton
            fileName="お薬手帳"
            active={medicineNoteStatus.active}
            statusLabel={medicineNoteStatus.label}
            statusIcon={medicineNoteStatus.active ? 'blank' : undefined}
            onClick={handleOpenMedicineNote}
          />
        )}
      </ReceptionDrawerFileMenu>
      {isFileAreaOpened && <FileArea onClose={handleCloseFileArea} />}
      {appointmentId && patientId ? (
        <ReceptionMainBox>
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <PatientProfile appointmentId={appointmentId} patientId={patientId} />
            </ProfileBox>
            <Box overflow="auto">
              <PatientKarte appointmentId={appointmentId} patientId={patientId} />
            </Box>
            <Footer appointmentId={appointmentId} patientId={patientId} />
          </Grid>
        </ReceptionMainBox>
      ) : null}
    </ReceptionDrawer>
  );
};
