import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Flex, Icon, Text } from '~/components/blocks';
import { QuestionnaireSheet } from '~/components/partials';
import { QuestionnaireSheetClipButton } from '~/components/partials/QuestionnaireSheetClipButton';
import {
  QuestionnaireSheetAnswerFragment,
  QuestionnaireSheetFragment,
  useGetWebVisitorQuestionnaireSheetLazyQuery,
  WebVisitorQuestionnaireSheetFragment,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { webVisitorFileAreaState } from '~/state/reception_drawer/atoms';

export const Questionnaire = () => {
  const { appointmentId } = useRecoilValue(webVisitorFileAreaState);
  const theme = useTheme();
  const [getQuestionnaires, { data, loading }] = useGetWebVisitorQuestionnaireSheetLazyQuery();

  const answers = getNode(data, 'Appointment') as WebVisitorQuestionnaireSheetFragment;
  const sheet: QuestionnaireSheetFragment | undefined =
    answers?.questionnaires.length > 0
      ? {
          answers: answers?.questionnaires.map((question) => {
            const answer: QuestionnaireSheetAnswerFragment = {
              title: question.title,
              value: question.value,
            };
            return answer;
          }),
          updatedAt: answers?.createdAt,
        }
      : undefined;

  const disabled = loading || !sheet?.answers;

  useEffect(() => {
    if (appointmentId) {
      getQuestionnaires({ variables: { appointmentId: appointmentId } });
    }
  }, [getQuestionnaires, appointmentId]);

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Icon size="m" icon="document" />
          <Text fontWeight="bold" marginLeft={theme.space.xs}>
            問診
          </Text>
        </Flex>
        <QuestionnaireSheetClipButton disabled={disabled} sheet={sheet} />
      </Flex>
      <Box position="relative" marginBottom={theme.space.xxl}>
        <QuestionnaireSheet loading={loading} sheet={sheet} />
      </Box>
    </Flex>
  );
};
