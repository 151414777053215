import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Flex, Icon, Text } from '~/components/blocks';
import { QuestionnaireSheet } from '~/components/partials';
import { QuestionnaireSheetClipButton } from '~/components/partials/QuestionnaireSheetClipButton';
import { useGetQuestionnaireSheetLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { telemedicinePatientFileAreaState } from '~/state/reception_drawer/atoms';

export const Questionnaire = () => {
  const { patientId } = useRecoilValue(telemedicinePatientFileAreaState);
  const theme = useTheme();
  const [getQuestionnaires, { data, loading }] = useGetQuestionnaireSheetLazyQuery();

  const sheet = getNode(data, 'Patient')?.questionnaireSheet || undefined;
  const disabled = loading || !sheet?.answers;

  useEffect(() => {
    if (patientId) {
      getQuestionnaires({ variables: { patientId: patientId } });
    }
  }, [getQuestionnaires, patientId]);

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Icon size="m" icon="document" />
          <Text fontWeight="bold" marginLeft={theme.space.xs}>
            問診
          </Text>
        </Flex>
        <QuestionnaireSheetClipButton disabled={disabled} sheet={sheet} />
      </Flex>
      <Box position="relative" marginBottom={theme.space.xxl}>
        <QuestionnaireSheet loading={loading} sheet={sheet} />
      </Box>
    </Flex>
  );
};
