import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { ComponentProps } from 'react';

import { Alert, AlertStatusType, Flex, Icon, IconName, Text } from '~/components/blocks';

type Props = {
  status: AlertStatusType;
  children: React.ReactNode;
};

const getIconName = (status: AlertStatusType): IconName => {
  switch (status) {
    case 'info':
      return 'info';
    case 'success':
      return 'check';
    case 'warning':
      return 'alert';
    case 'error':
    default:
      return 'error';
  }
};

const getIconColor = (status: AlertStatusType): ComponentProps<typeof Icon>['color'] => {
  switch (status) {
    case 'info':
      return 'blue';
    case 'success':
      return 'green';
    case 'warning':
      return 'yellow';
    case 'error':
    default:
      return 'pink';
  }
};

const CustomList = styled('li')(() =>
  css({
    listStyleType: 'none',
    position: 'relative',
    paddingLeft: '16px',
    '&::before': {
      content: '"•"',
      fontSize: '0.8em',
      position: 'absolute',
      left: '0.5em',
      top: '0.1em',
    },
  }),
);

const CustomText = styled(Text)(() =>
  css({
    overflowWrap: 'break-word',
  }),
);

const Description: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ul>
      <CustomList>
        <CustomText size="s" fontWeight="normal">
          {children}
        </CustomText>
      </CustomList>
    </ul>
  );
};

const Title: React.FC<Props> = ({ children, status }) => {
  const theme = useTheme();
  const iconName = getIconName(status);
  const iconColor = getIconColor(status);

  return (
    <Flex paddingY={theme.space.s}>
      <Icon size="l" icon={iconName} color={iconColor} marginRight={theme.space.m} />
      <CustomText size="s" fontWeight="bold">
        {children}
      </CustomText>
    </Flex>
  );
};

const Root: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Alert status={props.status} withIcon={false} marginY={theme.space.m}>
      <Flex flexDirection="column">{props.children}</Flex>
    </Alert>
  );
};

export const ActionInfo = Object.assign(Root, { Title, Description });
