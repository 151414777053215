import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Button } from '~/components/blocks';
import { invitationModalState } from '~/state/reception/atoms';

export const InvitationButton = () => {
  const theme = useTheme();

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(invitationModalState, (_state) => ({ ..._state, isOpen: true }));
      },
    [],
  );
  return (
    <Button size="s" ml={theme.space.m} onClick={handleClick}>
      オンライン服薬指導申込コード
    </Button>
  );
};
