import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Button, EntryList, Flex, Grid, Icon } from '~/components/blocks';
import { AppointmentDeliveryMethod } from '~/graphql';

import { DeliveryMethodField } from '../DeliveryMethodField';

type Props = {
  onClick?: () => void;
  children?: React.ReactNode;
  deliveryMethod: AppointmentDeliveryMethod;
};

const Root = styled('div')(({ theme }) =>
  css({
    marginTop: theme.space.m,
    marginBottom: theme.space.m,
  }),
);

const IconBox = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    borderRadius: theme.radii.circle,
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.space.s,
  }),
);

export const ReceiveOption = (props: Props) => {
  const { children, deliveryMethod, onClick } = props;

  return (
    <Root>
      <Grid gridTemplateColumns="min-content 1fr" alignItems="start">
        <IconBox>
          <Icon size="l" icon="medicine" color="grey" />
        </IconBox>
        <Grid gridTemplateRows="1fr min-content">
          <Flex alignItems="center">
            <EntryList size="s">
              <EntryList.Head>受け渡し方法</EntryList.Head>
              <EntryList.Body>
                <DeliveryMethodField deliveryMethod={deliveryMethod} size="s" />
              </EntryList.Body>
            </EntryList>
            {onClick && (
              <Button use="default" size="s" onClick={onClick} marginLeft="auto">
                変更
              </Button>
            )}
          </Flex>
          {children}
        </Grid>
      </Grid>
    </Root>
  );
};
