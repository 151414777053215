import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { Alert, Box, Button, EntryList, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import {
  useAddInvitationCodeMutation,
  useGetInvitationOrganizationNameQuery,
  useSendInvitationSmsMutation,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { invitationModalState } from '~/state/reception/atoms';

import { InputForm } from './InputForm';

const InvitationPane = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    paddingY: theme.space.xxl,
    paddingX: '250px',
    marginTop: theme.space.l,
  }),
);

export const SendInvitationPanel = () => {
  const theme = useTheme();
  const { data } = useGetInvitationOrganizationNameQuery();
  const [{ isOpen, invitationCode, invitationCodeId }, setState] =
    useRecoilState(invitationModalState);
  const resetState = useResetRecoilState(invitationModalState);
  const formRef = useRef<RefAttributeType<typeof InputForm>>(null);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [addInvitationCodeError, setAddInvitationCodeError] = useState<string | null>(null);
  const organizationId = (data && getMe(data)?.organization.id) || null;
  const organizationName = (data && getMe(data)?.organization.name) || null;

  const [addInvitationCode] = useAddInvitationCodeMutation({
    onCompleted: (_data) => {
      const _invitationCode = _data.addInvitationCode?.invitationCode;
      if (_invitationCode) {
        setState((_state) => ({
          ..._state,
          invitationCodeId: _invitationCode.id,
          invitationCode: _invitationCode.code,
        }));
      }
    },
    onError: (_error) => {
      setAddInvitationCodeError(_error.message);
    },
  });

  const [sendInvitationSms] = useSendInvitationSmsMutation();
  const handleSend = useCallback(
    async (phoneNumber: string, memo?: string) => {
      if (!organizationName || !invitationCode || !invitationCodeId) {
        return;
      }
      const content = `${organizationName}に処方箋が届きオンライン服薬指導の申し込みが可能になりました。
オンライン診療・服薬指導アプリ「CLINICS」をインストールし、申し込みコード「${invitationCode}」を入力してお申し込みください。
{{ link }}
※インストール後にアプリ内で薬局を検索していただくか、このリンクを再びタップすると直接薬局画面に移動します`;

      setSending(true);
      try {
        await sendInvitationSms({
          variables: {
            input: {
              content,
              phoneNumber: phoneNumber.replace(/^0/, '+81'),
              invitationCodeId: invitationCodeId,
              memo: memo,
            },
          },
        });
        resetState();
      } catch (e) {
        setError(e);
      }
      setSending(false);
    },
    [invitationCode, invitationCodeId, organizationName, resetState, sendInvitationSms],
  );

  useEffect(() => {
    if (isOpen && organizationId && !invitationCodeId) {
      addInvitationCode({ variables: { input: { organizationId } } });
    }
  }, [addInvitationCode, organizationId, isOpen, invitationCodeId, setState, invitationCode]);

  return (
    <>
      {error && (
        <Alert status="error" mb={theme.space.l}>
          {error}
        </Alert>
      )}
      {addInvitationCodeError && (
        <Alert status="error" mb={theme.space.l}>
          {addInvitationCodeError}
        </Alert>
      )}
      <Text block>
        申込コードをSMSで送信することで、患者がオンライン服薬指導を予約できます。
        <br />
        使い方などの詳細は
        <ExternalLink url="https://intercom.help/pharms/ja/articles/10391183-%E7%94%B3%E8%BE%BC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6">
          こちら
        </ExternalLink>
      </Text>
      <InvitationPane>
        <EntryList>
          <EntryList.Head>申込コード</EntryList.Head>
          <EntryList.Body>
            {addInvitationCodeError ? (
              <Text size="m">申込コードを発行できませんでした</Text>
            ) : (
              <Text size="xl">{invitationCode || '作成中...'}</Text>
            )}
          </EntryList.Body>
        </EntryList>
        <InputForm
          ref={formRef}
          disabled={sending || !!addInvitationCodeError}
          onSubmit={handleSend}
        />
        <Box textAlign="right" mt={theme.space.xxl}>
          <Button
            use="secondary"
            loading={sending}
            disabled={!!addInvitationCodeError}
            onClick={formRef.current?.submitForm}
          >
            送信
          </Button>
        </Box>
      </InvitationPane>
    </>
  );
};
